import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

import EndpointSecurityCardsData from '../../../data/ops-view/SecurityStackEndpointSecurity/EndpointSecurityCards.json';

const EndpointSecurityCards = (props) => {

  const [onLoading, setOnLoading] = useState(false)

  const data = props.data.charts;

  const [parentWidth, setParentWidth] = useState(0);


  useEffect(() => {
    const updateParentWidth = () => {
      const parentDiv = document.getElementById('parentDiv');
      if (parentDiv) {
        const width = parentDiv.offsetWidth;
        setParentWidth(width * 0.95); // Assigning 90% width
      }
    };

    updateParentWidth();

    window.addEventListener('resize', updateParentWidth);

    return () => {
      window.removeEventListener('resize', updateParentWidth);
    };
  }, []);


  return (
    <div style={{
      width: '100%', marginLeft: 0, display: 'flex', flexDirection: 'column', height: 350,
      marginTop: '1%', padding: 20, borderRadius: 15
    }}>


      {onLoading &&

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

          <ShimmerCircularImage size={500} />
          <ShimmerCircularImage size={500} />
        </div>
      }


      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>

        <div style={{ width: '100%' }}>

          <div style={{
            display: 'flex', flexDirection: 'row', 
            justifyContent: 'space-between', alignItems: 'center',
            alignItems: 'center', flexWrap: 'wrap'
          }}>


            <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#fff', borderRadius: 5, padding: 25, width: '18%', 
            justifyContent:'space-between', height:130 }}>

              <span style={{ color: '#000', fontSize: 25, fontWeight: 'bold', textAlign: 'center' }}>{data.endpointSecurityOverview[0].value}</span>
              <span style={{ color: '#000', fontSize: 18, fontWeight: 'normal', textAlign: 'center' }}>{data.endpointSecurityOverview[0].name}</span>

            </div>


            <div style={{ display: 'flex', flexDirection: 'column',
             backgroundColor: '#fff', borderRadius: 5, padding: 25, width: '18%',
             justifyContent:'space-between', height:130 }}>

              <span style={{ color: '#000', fontSize: 25, fontWeight: 'bold', textAlign: 'center' }}>{data.endpointSecurityOverview[1].value}</span>
              <span style={{ color: '#000', fontSize: 18, fontWeight: 'normal', textAlign: 'center' }}>{data.endpointSecurityOverview[1].name}</span>

            </div>

            <div style={{ display: 'flex', flexDirection: 'column', 
            backgroundColor: '#fff', borderRadius: 5, padding: 25, width: '18%',
            justifyContent:'space-between', height:130 }}>

              <span style={{ color: '#000', fontSize: 25, fontWeight: 'bold', textAlign: 'center' }}>{data.endpointSecurityOverview[2].value}</span>
              <span style={{ color: '#000', fontSize: 18, fontWeight: 'normal', textAlign: 'center' }}>{data.endpointSecurityOverview[2].name}</span>

            </div>


            <div style={{ display: 'flex', flexDirection: 'column', 
            backgroundColor: '#fff', borderRadius: 5, padding: 25, width: '18%',
            justifyContent:'space-between', height:130 }}>

              <span style={{ color: '#000', fontSize: 25, fontWeight: 'bold', textAlign: 'center' }}>{data.endpointSecurityOverview[3].value}</span>
              <span style={{ color: '#000', fontSize: 18, fontWeight: 'normal', textAlign: 'center' }}>{data.endpointSecurityOverview[3].name}</span>

            </div>


            <div style={{ display: 'flex', flexDirection: 'column', 
            backgroundColor: '#fff', borderRadius: 5, padding: 25, width: '18%',
            justifyContent:'space-between', height:130 }}>

              <span style={{ color: '#000', fontSize: 25, fontWeight: 'bold', textAlign: 'center' }}>{data.endpointSecurityOverview[4].value}</span>
              <span style={{ color: '#000', fontSize: 18, fontWeight: 'normal', textAlign: 'center' }}>{data.endpointSecurityOverview[4].name}</span>

            </div>

            <div style={{ display: 'flex', flexDirection: 'column', 
            backgroundColor: '#fff', borderRadius: 5, padding: 25, 
            width: '18%', marginTop: 20, justifyContent:'space-between', height:130 }}>

              <span style={{ color: '#000', fontSize: 25, fontWeight: 'bold', textAlign: 'center' }}>{data.endpointSecurityOverview[5].value}</span>
              <span style={{ color: '#000', fontSize: 18, fontWeight: 'normal', textAlign: 'center' }}>{data.endpointSecurityOverview[5].name}</span>

            </div>


            <div style={{ display: 'flex', flexDirection: 'column',
             backgroundColor: '#fff', borderRadius: 5, padding: 25, 
             width: '18%', marginTop: 20 ,justifyContent:'space-between', height:130}}>

              <span style={{ color: '#000', fontSize: 25, fontWeight: 'bold', textAlign: 'center' }}>{data.endpointSecurityOverview[6].value}</span>
              <span style={{ color: '#000', fontSize: 18, fontWeight: 'normal', textAlign: 'center' }}>{data.endpointSecurityOverview[6].name}</span>

            </div>


            <div style={{ display: 'flex', flexDirection: 'column',
             backgroundColor: '#fff', borderRadius: 5, padding: 25, 
             width: '18%', marginTop: 20, justifyContent:'space-between', height:130 }}>

              <span style={{ color: '#000', fontSize: 25, fontWeight: 'bold', textAlign: 'center' }}>{data.endpointSecurityOverview[7].value}</span>
              <span style={{ color: '#000', fontSize: 18, fontWeight: 'normal', textAlign: 'center' }}>{data.endpointSecurityOverview[7].name}</span>

            </div>


            <div style={{ display: 'flex', flexDirection: 'column', 
            backgroundColor: '#fff', borderRadius: 5, padding: 25, 
            width: '18%', marginTop: 20, justifyContent:'space-between', height:130 }}>

              <span style={{ color: '#000', fontSize: 25, fontWeight: 'bold', textAlign: 'center' }}>{data.endpointSecurityOverview[8].value}</span>
              <span style={{ color: '#000', fontSize: 18, fontWeight: 'normal', textAlign: 'center' }}>{data.endpointSecurityOverview[8].name}</span>

            </div>

            <div style={{ display: 'flex', flexDirection: 'column',
             backgroundColor: '#fff', borderRadius: 5, padding: 25, 
             width: '18%', marginTop: 20, justifyContent:'space-between', height:130 }}>

              <span style={{ color: '#000', fontSize: 25, fontWeight: 'bold', textAlign: 'center' }}>{data.endpointSecurityOverview[9].value}</span>
              <span style={{ color: '#000', fontSize: 18, fontWeight: 'normal', textAlign: 'center' }}>{data.endpointSecurityOverview[9].name}</span>

            </div>




          </div>
        </div>
      </div>


    </div>
  );
};

export default EndpointSecurityCards;