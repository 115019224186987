import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

const ActiveSessions = (props) => {

  const [onLoading, setOnLoading] = useState(false)

  const data = props.data.charts;

  const [parentWidth, setParentWidth] = useState(0);


  useEffect(() => {
    const updateParentWidth = () => {
      const parentDiv = document.getElementById('parentDiv');
      if (parentDiv) {
        const width = parentDiv.offsetWidth;
        setParentWidth(width * 0.95); 
      }
    };

    updateParentWidth();

    window.addEventListener('resize', updateParentWidth);

    return () => {
      window.removeEventListener('resize', updateParentWidth);
    };
  }, []);


  return (
    <div style={{
      width: '67%', marginLeft: 0, display: 'flex', flexDirection: 'column', height: 450,
      marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
    }}>


      {onLoading &&

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

          <ShimmerCircularImage size={500} />
          <ShimmerCircularImage size={500} />
        </div>
      }


      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>


        <div style={{ width: '100%' }}>

          <h6 style={{ color: '#fff', textAlign: 'left' }}>Active Sessions</h6>
          <hr />


          <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
            alignItems: 'center',
          }}>

            
<table style={{width:'100%'}}>
      <thead>
        <tr>
          <th style={{ padding: 10,color:'#fff' }}>System Name</th>
          <th style={{ padding: 10,color:'#fff' }}>DNS Name / IP Address</th>
          <th style={{ padding: 10,color:'#fff' }}>Username</th>
          <th style={{ padding: 10,color:'#fff' }}>Start Time</th>
          <th style={{ padding: 10,color:'#fff' }}>Login Account</th>
          <th style={{ padding: 10,color:'#fff' }}>Account</th>
          <th style={{ padding: 10,color:'#fff' }}>Session</th>
        </tr>
      </thead>
      <tbody>
        {data.activeSessions.map((activity, index) => (
          <tr key={index}>
            <td style={{ padding: 10 }}>{activity.systemName}</td>
            <td style={{ padding: 10 }}>{activity.dnsName}</td>
            <td style={{ padding: 10 }}>{activity.username}</td>
            <td style={{ padding: 10 }}>{activity.startTime}</td>
            <td style={{ padding: 10 }}>{activity.loginAcc}</td>
            <td style={{ padding: 10 }}>{activity.account}</td>
            <td style={{ padding: 10 }}>{activity.session}</td>
          </tr>
        ))}
      </tbody>
    </table>




          </div>
        </div>
      </div>


    </div>
  );
};

export default ActiveSessions;