import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';

import ReceivedCasesData from '../../data/tactical-view/ReceivedCases.json'; 


const ReceivedCases= (props) => {

    const [onLoading, setOnLoading] = useState(false)  

    const [screenWidth, setScreenWidth] = useState(1000);

  useEffect(() => {
      setScreenWidth (window.innerWidth - ((window.innerWidth * 25)/100))
  }, []);

    const data = props.data.charts.receivedCases;

    return (
      <div style={{
          width: '49%', marginLeft: 0, display: 'flex', flexDirection: 'column', height: 420,
          marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
      }}>         


          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 10 }}>


          <div style={{ height: 60, }}>


                  <h6 style={{ color: '#fff', textAlign: 'left' }}>Received Cases</h6>
                  <hr />

                  </div>


                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems:'center' }}>      


                <div style={{width:'80%'}}>

                  <Chart
                    options={data.chartOptions}
                    series={[{
                        name: 'Received Cases',
                        data: data.chartData.seriesData
                    }]}
                    type="area"
                    width={screenWidth * 0.35}
                    height={300}
                 />
                 </div>

                 <div style={{display:'flex', flexDirection:'column', width:'20%', justifyContent:'space-between'}}>

                    <div style={{display:'flex', flexDirection:'column', backgroundColor:'#fff', padding:5,
                     alignItems:'center', borderRadius:5, width:'100%', marginBottom:10}}>

                        <span style={{color:'#000', fontSize:20, fontWeight:'bold'}}>
                            {data.chartData.seriesData[0] + data.chartData.seriesData[1] + data.chartData.seriesData[2]} 
                        </span>
                        <span style={{color:'#5953c5', fontSize:15, fontWeight:'normal'}}>1st Quarter</span>

                    </div>


                    <div style={{display:'flex', flexDirection:'column', backgroundColor:'#fff', padding:5,
                     alignItems:'center', borderRadius:5, width:'100%',marginBottom:10}}>

                        <span style={{color:'#000', fontSize:20, fontWeight:'bold'}}>
                            {data.chartData.seriesData[3] + data.chartData.seriesData[4] + data.chartData.seriesData[5]} 
                        </span>
                        <span style={{color:'#5953c5', fontSize:15, fontWeight:'normal'}}>2nd Quarter</span>

                    </div>


                    <div style={{display:'flex', flexDirection:'column', backgroundColor:'#fff', padding:5,
                     alignItems:'center', borderRadius:5, width:'100%',marginBottom:10}}>

                        <span style={{color:'#000', fontSize:20, fontWeight:'bold'}}>
                            {data.chartData.seriesData[6] + data.chartData.seriesData[7] + data.chartData.seriesData[8]} 
                        </span>
                        <span style={{color:'#5953c5', fontSize:15, fontWeight:'normal'}}>3rd Quarter</span>

                    </div>


                    <div style={{display:'flex', flexDirection:'column', backgroundColor:'#fff', padding:5,
                     alignItems:'center', borderRadius:5, width:'100%', marginBottom:10}}>

                        <span style={{color:'#000', fontSize:20, fontWeight:'bold'}}>
                            {data.chartData.seriesData[9] + data.chartData.seriesData[10] + data.chartData.seriesData[11]} 
                        </span>
                        <span style={{color:'#5953c5', fontSize:15, fontWeight:'normal'}}>4th Quarter</span>

                    </div>


                 </div>


                  </div>



              </div>


          </div>


      
  );
};

export default ReceivedCases;
