import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';
import '../../style.css';


const BrandReviewSummary = (props) => {

  const [onLoading, setOnLoading] = useState(false)

  const data = props.data;

  const total = data.charts.brandReviewSummary.positive +  
                data.charts.brandReviewSummary.neutral + 
                data.charts.brandReviewSummary.negative + 
                data.charts.brandReviewSummary.noRating;


  useEffect(() => {
    const animateBars = () => {
      const bars = document.querySelectorAll('.bar span');

      const percentagePositive = (data.charts.brandReviewSummary.positive / total) * 100;
      const percentageNeutral = (data.charts.brandReviewSummary.neutral / total) * 100;
      const percentageNegative = (data.charts.brandReviewSummary.negative / total) * 100;
      const percentageNoRating = (data.charts.brandReviewSummary.noRating / total) * 100;

     


      if (bars) {

        bars.forEach(bar => bar.style.display = 'none');

        animate('#bar-five', `${percentagePositive}%`);
        animate('#bar-four', `${percentageNeutral}%`);
        animate('#bar-three', `${percentageNegative}%`);
        animate('#bar-two', `${percentageNoRating}%`);
        

        setTimeout(() => {
          bars.forEach(bar => bar.style.display = 'inline');
        }, 1000);

      }

    };

    animateBars();
  }, []);

  // Animation function
  function animate(selector, width) {
    const element = document.querySelector(selector);
    if (element) {
      let currentWidth = 0;
      const step = 1;
      const duration = 1000 / (width / step);
      const timer = setInterval(() => {
        currentWidth += step;
        element.style.width = currentWidth + '%';
        if (currentWidth >= parseInt(width)) {
          clearInterval(timer);
        }
      }, duration);
    }
  }


  return (
    <div style={{
      width: '49%', marginLeft: 0, display: 'flex',
      flexDirection: 'column', height: 400,
      marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
    }}>


      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 10 }}>


      <div style={{ height: 60 }}>

          <h4 style={{ color: '#fff', textAlign: 'left' }}>Brand Review Summary</h4>
          <hr />

      </div>


          <div style={{ display: 'flex', flexDirection: 'row', 
          justifyContent: 'space-between', alignItems: 'center', height:320 }}>


            <div className="reviews-container">
              <div className="inner">
                <div className="rating">
                  <span className="rating-num">4.0</span>
                  <div className="rating-stars">
                    <span><i className="active icon-star"></i></span>
                    <span><i className="active icon-star"></i></span>
                    <span><i className="active icon-star"></i></span>
                    <span><i className="active icon-star"></i></span>
                    <span><i className="icon-star"></i></span>
                  </div>
                  <div className="rating-users">
                    <i className="icon-user"></i> {total} Reviews
                  </div>
                </div>

                <div className="histo">
                  <div className="five histo-rate">
                    <span className="histo-star">
                      <i className="active icon-star"></i> Positive
                    </span>
                    <span className="bar-block">
                      <span id="bar-five" className="bar">
                        <span>{data.charts.brandReviewSummary.positive}</span>&nbsp;
                      </span>
                    </span>
                  </div>

                  <div className="four histo-rate">
                    <span className="histo-star">
                      <i className="active icon-star"></i> Neutral
                    </span>
                    <span className="bar-block">
                      <span id="bar-four" className="bar">
                        <span>{data.charts.brandReviewSummary.neutral}</span>&nbsp;
                      </span>
                    </span>
                  </div>

                  <div className="three histo-rate">
                    <span className="histo-star">
                      <i className="active icon-star"></i> Negative
                    </span>
                    <span className="bar-block">
                      <span id="bar-three" className="bar">
                        <span>{data.charts.brandReviewSummary.negative}</span>&nbsp;
                      </span>
                    </span>
                  </div>

                  <div className="two histo-rate">
                    <span className="histo-star">
                      <i className="active icon-star"></i> No Rating
                    </span>
                    <span className="bar-block">
                      <span id="bar-two" className="bar">
                        <span>{data.charts.brandReviewSummary.noRating}</span>&nbsp;
                      </span>
                    </span>
                  </div>


                </div>
              </div>
            </div>




          </div>



        </div>


      </div>


  );
};

export default BrandReviewSummary;
