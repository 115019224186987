import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';


const WhichDataStoresNeedAttention = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const data = props.data;


    const total = 1134;
    const portion = 113;
    const percentage = (portion / total) * 100;
    const restPercentage = 100 - percentage;






    return (
        <div style={{
            width: '32%', marginLeft: 0, display: 'flex', flexDirection: 'column', height: 500,
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', marginTop: 10 }}>

                <div style={{ height: 100 }}>

                    <h4 style={{ color: '#fff', textAlign: 'left' }}>Which data stores need attention?</h4>
                    <hr />

                </div>


                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',  }}>


                    


                    <div style={{ writingMode: 'vertical-rl', textAlign: 'left' }}>
                        <span className="whiteSpan" style={{ display: 'inline-block', transform: 'rotate(180deg)' }}>LIKELIHOOD</span>
                    </div>


                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', 
                        justifyContent: 'space-between', marginTop: 20, width: '100%', marginLeft:10 }}>

                        <div style={{
                            display: 'flex', flexDirection: 'column', width: '30%', margin: '0.5%',
                            border: '1px solid #fff', justifyContent: 'center', borderRadius: 5, padding: 5, alignItems: 'center'
                        }}>
                            <span style={{ color: '#fb4437', fontSize: 25 }}>{data.charts.whichDataStoresNeedAttention[0].high}</span>
                            <span className="whiteSpan" style={{ marginTop: 10, fontSize:14 }}>High</span>
                        </div>

                        <div style={{
                            display: 'flex', flexDirection: 'column', width: '30%', margin: '0.5%',
                            border: '1px solid #fff', justifyContent: 'center', borderRadius: 5, padding: 5, alignItems: 'center'
                        }}>
                            <span style={{ color: '#fb4437', fontSize: 25 }}>{data.charts.whichDataStoresNeedAttention[0].med}</span>
                            <span className="whiteSpan" style={{ marginTop: 10, fontSize:14 }}>Med</span>
                        </div>


                        <div style={{
                            display: 'flex', flexDirection: 'column', width: '30%', margin: '0.5%',
                            border: '1px solid #fff', justifyContent: 'center', borderRadius: 5, padding: 5, alignItems: 'center',
                        }}>
                            <span style={{ color: '#fb4437', fontSize: 25 }}>{data.charts.whichDataStoresNeedAttention[0].low}</span>
                            <span className="whiteSpan" style={{ marginTop: 10, fontSize:14 }}>Low</span>
                        </div>


                        <div style={{
                            display: 'flex', flexDirection: 'column', width: '30%', margin: '0.5%',
                            border: '1px solid #fff', justifyContent: 'center', borderRadius: 5, padding: 5, alignItems: 'center',
                        }}>
                            <span style={{ color: '#fb4437', fontSize: 25 }}>{data.charts.whichDataStoresNeedAttention[1].high}</span>
                            <span className="whiteSpan" style={{ marginTop: 10, fontSize:14 }}>High</span>
                        </div>

                        <div style={{
                            display: 'flex', flexDirection: 'column', width: '30%', margin: '0.5%',
                            border: '1px solid #fff', justifyContent: 'center', borderRadius: 5, padding: 5, alignItems: 'center',
                        }}>
                            <span style={{ color: '#fb4437', fontSize: 25 }}>{data.charts.whichDataStoresNeedAttention[1].med}</span>
                            <span className="whiteSpan" style={{ marginTop: 10, fontSize:14 }}>Med</span>
                        </div>

                        <div style={{
                            display: 'flex', flexDirection: 'column', width: '30%', margin: '0.5%',
                            border: '1px solid #fff', justifyContent: 'center', borderRadius: 5, padding: 5, alignItems: 'center',
                        }}>
                            <span style={{ color: '#fb4437', fontSize: 25 }}>{data.charts.whichDataStoresNeedAttention[1].low}</span>
                            <span className="whiteSpan" style={{ marginTop: 10, fontSize:14 }}>Low</span>
                        </div>

                        <div style={{
                            display: 'flex', flexDirection: 'column', width: '30%', margin: '0.5%',
                            border: '1px solid #fff', justifyContent: 'center', borderRadius: 5, padding: 5, alignItems: 'center',
                        }}>
                            <span style={{ color: '#fb4437', fontSize: 25 }}>{data.charts.whichDataStoresNeedAttention[2].high}</span>
                            <span className="whiteSpan" style={{ marginTop: 10, fontSize:14 }}>High</span>
                        </div>

                        <div style={{
                            display: 'flex', flexDirection: 'column', width: '30%', margin: '0.5%',
                            border: '1px solid #fff', justifyContent: 'center', borderRadius: 5, padding: 5, alignItems: 'center',
                        }}>
                            <span style={{ color: '#fb4437', fontSize: 25 }}>{data.charts.whichDataStoresNeedAttention[2].med}</span>
                            <span className="whiteSpan" style={{ marginTop: 10, fontSize:14 }}>Med</span>
                        </div>

                        <div style={{
                            display: 'flex', flexDirection: 'column', width: '30%', margin: '0.5%',
                            border: '1px solid #fff', justifyContent: 'center', borderRadius: 5, padding: 5, alignItems: 'center',
                        }}>
                            <span style={{ color: '#fb4437', fontSize: 25 }}>{data.charts.whichDataStoresNeedAttention[0].low}</span>
                            <span className="whiteSpan" style={{ marginTop: 10,fontSize:14 }}>Low</span>
                        </div>

                        <div style={{ textAlign: 'center', marginTop: 20, width: '100%' }}>
                            <span className="whiteSpan" style={{ display: 'block', }}>IMPACT</span>
                        </div>

                    </div>






                </div>



            </div>


        </div>



    );
};

export default WhichDataStoresNeedAttention;
