import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

import OWASPDistributionData from '../../../data/ops-view/SecurityStackAppSecurity/OWASPDistribution.json';

const OWASPDistribution = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const data = props.data.charts;

    const [screenWidth, setScreenWidth] = useState(1000);

    useEffect(() => {
        setScreenWidth (window.innerWidth - ((window.innerWidth * 25)/100))
    }, []);

    const labels = data.owaspDistribution.map(item => item.code);
  const assetsValues = data.owaspDistribution.map(item => item.assets);
  const openFindingsValues = data.owaspDistribution.map(item => item.openFindings);

  const colors = [
    '#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800',
    '#9C27B0', '#03A9F4', '#4CAF50', '#F44336', '#FFEB3B'
  ];

  // Chart options
  const options = {
    chart: {
      type: 'bar',
      toolbar: {
        show: true
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        barHeight: '80%',
        dataLabels: {
          position: 'top'
        }
      }
    },
    
    
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ['#fff']
      }
    },
    xaxis: {
      categories: labels
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          fontSize: '12px',
          colors: ['#fff']
        }
      }
    },
    colors: colors,
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'right',
      offsetY: -32
    }
  };

  // Chart series
  const series = [{
    name: 'Assets',
    data: assetsValues,
    color:'#3498db'
  }, {
    name: 'Open Findings',
    data: openFindingsValues,
    color:'#2ecc71'
  }];
    

  console.log('screenWidth:', screenWidth)
   

    return (
        <div style={{
            width: '100%', marginLeft: 0, display: 'flex', 
            flexDirection: 'column', height:570,
            marginTop: '2%',
             background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>



            {onLoading &&

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

                    <ShimmerCircularImage size={500} />
                    <ShimmerCircularImage size={500} />
                </div>
            }


            <div style={{ display: 'flex', 
            flexDirection: 'row', justifyContent: 'space-around', 
            marginTop: 10 }}>


                <div style={{ width: '100%' }}>

                    <h6 style={{ color: '#fff', textAlign: 'left' }}>
                        OWASP Distribution</h6>
                    <hr />

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} id="parentDiv">
                         
                    <ReactApexChart options={options}
                     series={series} type="bar" height={500} width={screenWidth}/>

                    </div>
                </div>
            </div>


        </div>
    );
};

export default OWASPDistribution;