import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';
import '../../style.css';


const WhatIsTheTrendOfRisks = (props) => {

  const [onLoading, setOnLoading] = useState(false)

  const data = props.data;

  const [screenWidth, setScreenWidth] = useState(1000);

  useEffect(() => {
    setScreenWidth(window.innerWidth - ((window.innerWidth * 25) / 100))
  }, []);

  const whatIsTrendOfRisksChartData = {
    options: {
      chart: {
        type: 'bar',
        stacked: true,
      },
      xaxis: {
        categories: data.charts.whatIsTheTrendOfRisks.categories,
      },
      yaxis: {
        min: 0,
        max: 2000,
        tickAmount: 5,
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          }
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      colors: ['#ff4d4e', '#fea840', '#bfbfbf'],
      legend: {
        position: 'top',
      },
      fill: {
        opacity: 1,
      },
    },
    series: data.charts.whatIsTheTrendOfRisks.series
  };



  return (
    <div style={{
      width: '49%', marginLeft: 0, display: 'flex',
      flexDirection: 'column', height: 570,
      marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
    }}>

      <div style={{
        display: 'flex', flexDirection: 'column',
        justifyContent: 'space-around', marginTop: 10
      }}>



        <div style={{ height: 100 }}>

            <h4 style={{ color: '#fff', textAlign: 'left' }}>
              What Is The Trend of Risks?</h4>
            <hr />
        </div>


            <div style={{
              display: 'flex',
              flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', alignItems:'center'
            }}>




              <Chart
                options={whatIsTrendOfRisksChartData.options}
                series={whatIsTrendOfRisksChartData.series}
                type="bar"
                width={screenWidth * 0.45}
                height="350"
              />


            </div>


          </div>
        </div>
     
    
  );
};

export default WhatIsTheTrendOfRisks;
