import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';

import RiskRegisterData from '../../data/tactical-view/RiskRegister.json';

const RiskRegister = (props) => {


  const data = props.data.charts.riskRegister;

  return (
    <div style={{
      width: '100%', marginLeft: 0, display: 'flex', flexDirection: 'column', 
      marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
    }}>     



      <div style={{ display: 'flex', flexDirection: 'column',
       justifyContent: 'space-between', marginTop: 10 }}>


<div style={{ height: 60, }}>

          <h6 style={{ color: '#fff', textAlign: 'left' }}>Risk Register</h6>
          <hr />

</div>

          <div style={{ display: 'flex', flexDirection: 'row',
               justifyContent: 'center', alignItems: 'center', }}>

            <div style={{ width: '80%' }}>

              <span className="whiteSpan" style={{fontSize:12, }}>Residual Breakdown</span>

              <div style={{ display: 'flex', flexDirection: 'row', 
                    justifyContent: 'space-between', backgroundColor: '#1d1f2a', padding: 10, marginTop:10 }}>

                <div style={{
                  display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: '#fff',
                  height: 100, width: 100, borderRadius: 50, padding: 20
                }}>

                  <span style={{ color: '#000', textAlign: 'center', fontWeight: 'bold' }}>{data.total}</span>
                  <span style={{ color: '#000', textAlign: 'center' }}>Total</span>

                </div>

                <div style={{
                  display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: '#fff',
                  height: 100, width: 100, borderRadius: 50, padding: 20
                }}>

                  <span style={{ color: '#000', textAlign: 'center', fontWeight: 'bold' }}>{data.critical}</span>
                  <span style={{ color: '#000', textAlign: 'center' }}>Critical</span>

                </div>

                <div style={{
                  display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: '#fff',
                  height: 100, width: 100, borderRadius: 50, padding: 20
                }}>

                  <span style={{ color: '#000', textAlign: 'center', fontWeight: 'bold' }}>{data.high}</span>
                  <span style={{ color: '#000', textAlign: 'center' }}>High</span>

                </div>

                <div style={{
                  display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: '#fff',
                  height: 100, width: 100, borderRadius: 50, padding: 20
                }}>

                  <span style={{ color: '#000', textAlign: 'center', fontWeight: 'bold' }}>{data.medium}</span>
                  <span style={{ color: '#000', textAlign: 'center' }}>Medium</span>

                </div>

                <div style={{
                  display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: '#fff',
                  height: 100, width: 100, borderRadius: 50, padding: 20
                }}>

                  <span style={{ color: '#000', textAlign: 'center', fontWeight: 'bold' }}>{data.low}</span>
                  <span style={{ color: '#000', textAlign: 'center' }}>Low</span>

                </div>


              </div>


              <table style={{ marginTop: 30 }}>
                <thead>
                  <tr>
                    <th style={{ padding: 10,color:'#fff' }}>ID</th>
                    <th style={{ padding: 10,color:'#fff' }}>Name</th>
                    <th style={{ padding: 10 ,color:'#fff'}}>Entity</th>
                    <th style={{ padding: 10,color:'#fff' }}>Priority</th>
                    <th style={{ padding: 10,color:'#fff' }}>Inherent</th>
                    <th style={{ padding: 10 ,color:'#fff'}}>Residual</th>
                    <th style={{ padding: 10,color:'#fff' }}>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {data.tableData.map((item, index) => (
                    <tr key={index}>
                      <td>{item.ID}</td>
                      <td>{item.Name}</td>
                      <td>{item.Entity}</td>
                      <td>{item.Priority}</td>
                      <td>{item.Inherent}</td>
                      <td>{item.Residual}</td>
                      <td>{item.Status}</td>
                    </tr>
                  ))}
                </tbody>
              </table>





            </div>


            {/* Inherent risk map  - START */}
            <div style={{ width: '50%' }}>

              



            </div>
             {/* Inherent risk map  - END */}



          </div>



        </div>


      </div>


   
  );
};

export default RiskRegister;
