import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';
import '../../style.css';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend);


const WhoCanAccessSensitiveData = (props) => {

  const [screenWidth, setScreenWidth] = useState(1000);

  const data = props.data;

  useEffect(() => {
    setScreenWidth(window.innerWidth - ((window.innerWidth * 25) / 100))
  }, []);


  const [onLoading, setOnLoading] = useState(false)
  const total = data.charts.whoCanAccessSensitiveData.usersWithAccessToSensitiveDataChart.total;
  const portion = data.charts.whoCanAccessSensitiveData.usersWithAccessToSensitiveDataChart.portion;
  const percentage = (portion / total) * 100;
  const restPercentage = 100 - percentage;


  const data1 = {
    labels: ['Completed', 'Remaining'],
    datasets: [
      {
        data: [portion, total - portion],
        backgroundColor: ['#5dbc53', '#d76358',],
        hoverBackgroundColor: ['#5dbc53', '#d76358',]
      }
    ],
    text: ""
  };

  const options1 = {
    plugins: {
      legend: {
        display: false,
        position: 'right',
        labels: {
          color: '#fff'
        }
      },

    },
    cutout: 70
  };

  const plugins1 = [{
    id: 'pluginId',
    beforeDraw: function (chart) {
      var width = chart.width,
        height = chart.height,
        ctx = chart.ctx;
      ctx.restore();
      var fontSize = 1;
      ctx.font = fontSize + "em sans-serif";
      ctx.textBaseline = "top";
      ctx.textColor = "white";
      var text = `${portion}/${total}`,
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 2;
      ctx.fillStyle = '#fff';
      ctx.fillText(text, textX, textY);
      ctx.save();
    }
  }]



  const total1 = data.charts.whoCanAccessSensitiveData.rolesGrantingAccessToSensitiveDataChart.total;
  const portion1 = data.charts.whoCanAccessSensitiveData.rolesGrantingAccessToSensitiveDataChart.portion;
  const percentage1 = (portion1 / total1) * 100;
  const restPercentage1 = 100 - percentage1;


  const data2 = {
    labels: ['Completed', 'In Progress', 'Delayed'],
    datasets: [
      {
        data: [portion1, total1 - portion1],
        backgroundColor: ['#5dbc53', '#d76358', 'red'],
        hoverBackgroundColor: ['#5dbc53', '#d76358', 'red']
      }
    ],
    text: ""
  };

  const options2 = {
    plugins: {
      legend: {
        display: false,
        position: 'right',
        labels: {
          color: '#fff'
        }
      },

    },
    cutout: 70
  };

  const plugins2 = [{
    id: 'pluginId',
    beforeDraw: function (chart) {
      var width = chart.width,
        height = chart.height,
        ctx = chart.ctx;
      ctx.restore();
      var fontSize = 1;
      ctx.font = fontSize + "em sans-serif";
      ctx.textBaseline = "top";
      ctx.textColor = "white";
      var text = `${portion1}/${total1}`,
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 2;
      ctx.fillStyle = '#fff';
      ctx.fillText(text, textX, textY);
      ctx.save();
    }
  }]


  return (
    <div style={{
      width: '49%', marginLeft: 0, display: 'flex',
      flexDirection: 'column', height: 570,
      marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
    }}>

      <div style={{
        display: 'flex', flexDirection: 'column',
        justifyContent: 'space-around', marginTop: 10,
      }}>


        <div style={{ height: 60, }}>

          <h4 style={{ color: '#fff', textAlign: 'left' }}>
            Who Can Access Sensitive Data?</h4>
          <hr />

        </div>


        <div style={{
          display: 'flex',
          flexDirection: 'row', height: '100%', flexWrap: 'wrap',
          justifyContent: 'space-between', alignItems: 'center', height: 420,
        }}>



          <div className="donut-chart" style={{
            position: 'relative', width: '40%',
            display: 'flex', flexDirection: 'column', alignItems: 'center'
          }}>

            <span className="whiteSpan" style={{
              textAlign: 'center',
              width: '100%', marginBottom: 30,
            }}>Users with access to Sensitive Data</span>

            <Doughnut
              type="doughnut"
              data={data1}
              options={options1}
              plugins={plugins1}
              width={screenWidth * 0.3}
              height={screenWidth * 0.3}

            />

          </div>


          <div className="donut-chart" style={{
            position: 'relative', width: '40%',
            display: 'flex', flexDirection: 'column', alignItems: 'center', 
          }}>

            <span className="whiteSpan" style={{ textAlign: 'center', width: '100%', marginBottom: 30, }}>Roles granting access to Sensitive Data</span>

            <Doughnut
              type="doughnut"
              data={data2}
              options={options2}
              plugins={plugins2}
              width={screenWidth * 0.3}
              height={screenWidth * 0.3}

            />

          </div>

        </div>



      </div>


    </div>


  );
};

export default WhoCanAccessSensitiveData;
