import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';
import '../../style.css'; 


const WhatIsTheMonetaryValueOfData = (props) => {

    const Data = props.data;

    const [onLoading, setOnLoading] = useState(false)    

    return (
        <div style={{
            width: '32%', marginLeft: 0, display: 'flex', flexDirection: 'column', height: 520,
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>          


            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', marginTop: 10 }}>

                     <div style={{ height: 100 }}>

                    <h4 style={{ color: '#fff', textAlign: 'left' }}>
                        What is the Monetary Value of Data?</h4>
                    <hr />

                    </div>


            <div style={{ display: 'flex', 
                    flexDirection: 'row', justifyContent: 'space-between',
                     alignItems:'center', flexWrap:'wrap', height:350 }}>


                    <div style={{display:'flex', flexDirection:'column',
                            justifyContent:'center', 
                            padding:10, borderRadius:10, width:'45%',height:150,
                             backgroundColor:'#fff'}}>

                             <span style={{fontSize:18, color:'#000',
                              fontWeight:'bold', textAlign:'center'}}>
                                ${Data.charts.whatIsTheMonetaryValueOfData.totalValueInMillions}M</span> 
                             <span style={{fontSize:15, color:'#000',
                              fontWeight:'normal', textAlign:'center'}}>
                               Total Value </span>   
                        
                    </div>   

                    <div style={{display:'flex', flexDirection:'column',
                            justifyContent:'center', 
                            padding:10, borderRadius:10, width:'45%',height:150,
                             backgroundColor:'#fff'}}>

                             <span style={{fontSize:18, color:'#000',
                              fontWeight:'bold', textAlign:'center'}}>${Data.charts.whatIsTheMonetaryValueOfData.valueAtRiskInMillion}M</span> 
                             <span style={{fontSize:15, color:'#000',
                              fontWeight:'normal', textAlign:'center'}}>
                                Value At Risk</span>   
                        
                    </div>   


                    <div style={{display:'flex', flexDirection:'column', marginTop:30,
                            justifyContent:'center', 
                            padding:10, borderRadius:10, width:'45%',height:150,
                             backgroundColor:'#fff'}}>

                             <span style={{fontSize:18, color:'#000',
                              fontWeight:'bold', textAlign:'center'}}>
                                {Data.charts.whatIsTheMonetaryValueOfData.totalDataStores}
                                </span> 
                             <span style={{fontSize:15, color:'#000',
                              fontWeight:'normal', textAlign:'center'}}>
                                Total DataStores</span>   
                        
                    </div> 



                    <div style={{display:'flex', flexDirection:'column',marginTop:30,
                            justifyContent:'center', 
                            padding:10, borderRadius:10, width:'45%', height:150,
                             backgroundColor:'#fff'}}>

                             <span style={{fontSize:18, color:'#000',
                              fontWeight:'bold', textAlign:'center'}}>
                                {Data.charts.whatIsTheMonetaryValueOfData.unscannedDatastores}
                                </span> 
                             <span style={{fontSize:15, color:'#000',
                              fontWeight:'normal', textAlign:'center'}}>
                                Unscanned DataStores</span>   
                        
                    </div>  
                  

                        
                    </div>
                </div>
            </div>
        
    );
};

export default WhatIsTheMonetaryValueOfData;
