import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';

import SLAViolationsThisMonthData from '../../data/tactical-view/SLAViolationsThisMonth.json';


const SLAViolationsThisMonth = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const data = props.data.charts.slaViolationsThisMonth;

    const [screenWidth, setScreenWidth] = useState(1000);

    useEffect(() => {
        setScreenWidth(window.innerWidth - ((window.innerWidth * 25) / 100))
    }, []);

    return (
        <div style={{
            width: '49%', marginLeft: 0, display: 'flex', flexDirection: 'column', height: 470,
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>


            <div style={{ display: 'flex', flexDirection: 'column',
                     justifyContent: 'space-between', marginTop: 10 }}>

                <div style={{ height: 60, }}>

                    <h6 style={{ color: '#fff', textAlign: 'left' }}>SLA Violations this Month</h6>
                    <hr />

                </div>


                <div style={{
                    display: 'flex', flexDirection: 'row',
                    justifyContent: 'space-between', alignItems: 'center'
                }}>

                    <Chart
                        options={data.chartOptions.options}
                        series={[{
                            name: 'SLA Violations',
                            data: data.chartData.seriesData
                        }]}
                        type="bar"
                        width={screenWidth * 0.5}
                        height={350}
                    />

                </div>

            </div>
        </div>


    );
};

export default SLAViolationsThisMonth;
