import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';

import OperationsStatusSLACardsData from '../../data/tactical-view/OperationsStatusSLACards.json';

import { FaEnvelope } from "react-icons/fa";
import { FaFlag } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";


const OperationsStatusSLACards = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const data = props.data.charts.operationsStatusSLACards;

    return (
        <div style={{
            width: '100%', marginLeft: 0, display: 'flex', flexDirection: 'column',
            marginTop: '2%', padding: 20, borderRadius: 15, background: 'linear-gradient(135deg, #161c2a, #1a2130)'
        }}>


            {onLoading &&

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

                    <ShimmerCircularImage size={500} />
                    <ShimmerCircularImage size={500} />
                </div>
            }



            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>


                <div style={{ width: '100%' }}>


                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>


                        <span className="whiteSpan" style={{width: '10%', }}>Today</span>

                        <div style={{
                            display: 'flex', flexDirection: 'row',
                            justifyContent: 'space-between', backgroundColor: '#fff', alignItems:'center',
                             borderRadius: 5, width: '23%', padding: 10, paddingLeft: 20, paddingRight: 20
                        }}>

                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', }}>
                                <span style={{ color: '#000', fontSize: 25,fontWeight:'bold'  }}>{data.today.responses}</span>
                                <span style={{ color: '#000', fontSize: 15, fontWeight:'bold'  }}>Response</span>
                            </div>

                            <FaEnvelope style={{ color: '#000' }} size={40} />


                        </div>

                        <div style={{
                            display: 'flex', flexDirection: 'row',alignItems:'center',
                            justifyContent: 'space-between', backgroundColor: '#fff', borderRadius: 5, width: '23%', padding: 10, paddingLeft: 20, paddingRight: 20
                        }}>

                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', }}>
                                <span style={{ color: '#000', fontSize: 25, fontWeight:'bold'  }}>{data.today.slaViolations}</span>
                                <span style={{ color: '#000', fontSize: 15, fontWeight:'bold'  }}>SLA Violations</span>
                            </div>

                            <FaFlag style={{ color: 'red' }} size={40} />


                        </div>


                        <div style={{
                            display: 'flex', flexDirection: 'row',alignItems:'center',
                            justifyContent: 'space-between', backgroundColor: '#fff', borderRadius: 5, width: '23%', padding: 10, paddingLeft: 20, paddingRight: 20
                        }}>

                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', }}>
                                <span style={{ color: '#000', fontSize: 25, fontWeight:'bold'  }}>{data.today.successRate}</span>
                                <span style={{ color: '#000', fontSize: 15,fontWeight:'bold'  }}>Success Rate</span>
                            </div>

                            <FaCheckCircle style={{ color: '#5dbc53' }} size={40} />


                        </div>


                    </div>


                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', marginTop: 20 }}>


                        <span className="whiteSpan" style={{width: '10%', }}>This Month</span>

                        <div style={{
                            display: 'flex', flexDirection: 'row',alignItems:'center',
                            justifyContent: 'space-between', backgroundColor: '#fff', borderRadius: 5, width: '23%', padding: 10, paddingLeft: 20, paddingRight: 20
                        }}>

                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', }}>
                                <span style={{ color: '#000', fontSize: 25, fontWeight:'bold'  }}>{data.thisMonth.responses}</span>
                                <span style={{ color: '#000', fontSize: 15, fontWeight:'bold'  }}>Responses</span>
                            </div>

                            <FaEnvelope style={{ color: '#000' }} size={40} />


                        </div>

                        <div style={{
                            display: 'flex', flexDirection: 'row',alignItems:'center',
                            justifyContent: 'space-between', backgroundColor: '#fff', borderRadius: 5, width: '23%', padding: 10, paddingLeft: 20, paddingRight: 20
                        }}>

                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', }}>
                                <span style={{ color: '#000', fontSize: 25,fontWeight:'bold'  }}>{data.thisMonth.slaViolations}</span>
                                <span style={{ color: '#000', fontSize: 15, fontWeight:'bold'  }}>SLA Violations</span>
                            </div>

                            <FaFlag style={{ color: 'red' }} size={40} />


                        </div>


                        <div style={{
                            display: 'flex', flexDirection: 'row', alignItems:'center',
                            justifyContent: 'space-between', backgroundColor: '#fff', borderRadius: 5, width: '23%', padding: 10, paddingLeft: 20, paddingRight: 20
                        }}>

                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', }}>
                                <span style={{ color: '#000', fontSize: 25, fontWeight:'bold'  }}>{data.thisMonth.successRate}</span>
                                <span style={{ color: '#000', fontSize: 15, fontWeight:'bold' }}>Success Rate</span>
                            </div>

                            <FaCheckCircle style={{ color: '#5dbc53' }} size={40} />


                        </div>

                    </div>

                </div>


            </div>


        </div>
    );
};

export default OperationsStatusSLACards;
