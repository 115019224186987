import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";



const MonthlyGraph = () => {

    const [onLoading, setOnLoading] = useState(false)

    const [screenWidth, setScreenWidth] = useState(1000);

    useEffect(() => {
        setScreenWidth (window.innerWidth - ((window.innerWidth * 25)/100))
    }, []);


    const [parentWidth, setParentWidth] = useState(0);

    useEffect(() => {
      const updateParentWidth = () => {
        const parentDiv = document.getElementById('parentDiv');
        if (parentDiv) {
          const width = parentDiv.offsetWidth;
          setParentWidth(width * 0.95); // Assigning 90% width
        }
      };
  
      updateParentWidth();
  
      window.addEventListener('resize', updateParentWidth);
  
      return () => {
        window.removeEventListener('resize', updateParentWidth);
      };
    }, []);

    const options = {
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
        yaxis: {
          min: 0,
          max: 100,
          tickAmount: 5
        },
        series: [{
          name: 'Organization Score',
          data: [70, 65, 72, 80, 85, 78, 75, 82, 90, 88, 92, 85]
        }, {
          name: 'Industry Average',
          data: [60, 62, 65, 70, 68, 72, 75, 78, 80, 82, 75, 70]
        }],
        legend: {
          offsetY: 10,
        }
      };
    
      const series = [{
        name: 'Organization Score',
        data: [70, 65, 72, 80, 85, 78, 75, 82, 90, 88, 92, 85]
      }, {
        name: 'Industry Average',
        data: [60, 62, 65, 70, 68, 72, 75, 78, 80, 82, 75, 70]
      }];
   

    return (
        <div style={{
            width: '57%', marginLeft: 0,
             display: 'flex', flexDirection: 'column', height: 450,
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>



            {onLoading &&

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

                    <ShimmerCircularImage size={500} />
                    <ShimmerCircularImage size={500} />
                </div>
            }


            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>


                <div style={{ width: '100%' }}>

                    <h6 style={{ color: '#fff', textAlign: 'left' }}>
                        Monthly Graph</h6>
                    <hr />


                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} id="parentDiv">
                         

                    <ReactApexChart options={options} 
                    series={series} type="bar" height={350} 
                    width={screenWidth * 0.5}/>
 

                    </div>
                </div>
            </div>


        </div>
    );
};

export default MonthlyGraph;