import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';

import OperationsStatusSLACardsSecondSectionData from '../../data/tactical-view/OperationsStatusSLACardsSecondSection.json'; 


const OperationsStatusSLACardsSecondSection= (props) => {

    const [onLoading, setOnLoading] = useState(false)  

    const data = props.data.charts.operationsStatusSLACardsSecond;

    return (
      <div style={{
          width: '100%', marginLeft: 0, display: 'flex', flexDirection: 'column',
          marginTop: '2%' , padding: 0, borderRadius: 15
      }}>



          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>



               
                  <div style={{ display: 'flex', flexDirection: 'row', 
                        justifyContent: 'space-between', alignItems:'center', flexWrap:'wrap', width:'100%' }}>                     


                        <div style={{
                            display: 'flex', flexDirection: 'row',
                            justifyContent: 'space-between', backgroundColor: '#fff', borderRadius: 5, width: '24%', padding: 10, 
                                paddingLeft: 20, paddingRight: 20, marginBottom:20, height:100
                            }}>

                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between',width:'100%' }}>
                                <span style={{ color: '#000', fontSize: 25,fontWeight:'bold', textAlign:'center'  }}>{data.serviceLevelManagementSLA}</span>
                                <span style={{ color: '#000', fontSize: 15, fontWeight:'bold',textAlign:'center'  }}>Service Level Management SLA</span>
                            </div>

                        </div>



                        <div style={{
                            display: 'flex', flexDirection: 'row',
                            justifyContent: 'space-between', backgroundColor: '#fff', borderRadius: 5, width: '24%', padding: 10, 
                                paddingLeft: 20, paddingRight: 20, marginBottom:20, height:100
                            }}>

                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width:'100%'}}>
                                <span style={{ color: '#000', fontSize: 25,fontWeight:'bold' ,textAlign:'center',  }}>{data.yearToDateSLA}</span>
                                <span style={{ color: '#000', fontSize: 15, fontWeight:'bold',textAlign:'center',  }}>Year to Date SLA</span>
                            </div>



                        </div>




                        <div style={{
                            display: 'flex', flexDirection: 'row',
                            justifyContent: 'space-between', backgroundColor: '#fff', borderRadius: 5, width: '24%', padding: 10, 
                                paddingLeft: 20, paddingRight: 20, marginBottom:20, height:100
                            }}>

                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width:'100%' }}>
                                <span style={{ color: '#000', fontSize: 25,fontWeight:'bold',textAlign:'center'  }}>{data.totalReceivedCases}</span>
                                <span style={{ color: '#000', fontSize: 15, fontWeight:'bold',textAlign:'center'  }}>Total Received Cases</span>
                            </div>



                        </div>




                        <div style={{
                            display: 'flex', flexDirection: 'row',
                            justifyContent: 'space-between', backgroundColor: '#fff', borderRadius: 5, width: '24%', padding: 10, 
                                paddingLeft: 20, paddingRight: 20, marginBottom:20, height:100
                            }}>

                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between',width:'100%' }}>
                                <span style={{ color: '#000', fontSize: 25,fontWeight:'bold',textAlign:'center'  }}>{data.customerSatisfactionCSAT}</span>
                                <span style={{ color: '#000', fontSize: 15, fontWeight:'bold',textAlign:'center'  }}>Customer Satisfaction - CSAT</span>
                            </div>



                        </div>




                        <div style={{
                            display: 'none', flexDirection: 'row',
                            justifyContent: 'space-between', backgroundColor: '#fff', borderRadius: 5, width: '30%', padding: 10, 
                                paddingLeft: 20, paddingRight: 20, marginBottom:20
                            }}>

                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between',width:'100%' }}>
                                <span style={{ color: '#000', fontSize: 25,fontWeight:'bold' ,textAlign:'center' }}>{data.customerEffortScoreCES}</span>
                                <span style={{ color: '#000', fontSize: 15, fontWeight:'bold',textAlign:'center'  }}>Customer Effort Score - CES</span>
                            </div>



                        </div>




                        <div style={{
                            display: 'none', flexDirection: 'row',
                            justifyContent: 'space-between', backgroundColor: '#fff', borderRadius: 5, width: '30%', padding: 10, 
                                paddingLeft: 20, paddingRight: 20, marginBottom:20
                            }}>

                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between',width:'100%' }}>
                                <span style={{ color: '#000', fontSize: 25,fontWeight:'bold',textAlign:'center'  }}>{data.netPromoterScoreNPS}</span>
                                <span style={{ color: '#000', fontSize: 15, fontWeight:'bold',textAlign:'center' }}>Net Promoter Score - NPS</span>
                            </div>



                        </div>
                  </div>
              </div>
      </div>
  );
};

export default OperationsStatusSLACardsSecondSection;
