import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

//import data from '../../../datanew/Australia/data/opsView.json';

const TopClientOperatingSystem = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const [screenWidth, setScreenWidth] = useState(1000);

    const data = props.data;

  useEffect(() => {
      setScreenWidth (window.innerWidth - ((window.innerWidth * 25)/100))
  }, []);

   

    return (
        <div style={{
            width: '49%', marginLeft: 0, display: 'flex', 
            flexDirection: 'column', height: 450,
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>



            {onLoading &&

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

                    <ShimmerCircularImage size={500} />
                    <ShimmerCircularImage size={500} />
                </div>
            }


            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>


                <div style={{ width: '100%' }}>

                    <h6 style={{ color: '#fff', textAlign: 'left' }}>
                    Top Client Operating System</h6>
                    <hr />


                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} id="parentDiv">
                         
                    <table style={{width:'100%'}}>
      <thead>
        <tr>
          <th style={{padding:10,color:'#fff'}}>Products</th>
          <th style={{padding:10,color:'#fff'}}>Releases</th>
          <th style={{padding:10,color:'#fff'}}>Assets</th>
        </tr>
      </thead>
      <tbody>
        {data.charts.topClientOperatingSystems.map((item, index) => (
          <tr key={index}>
            <td>{item.name}</td>
            <td>{item.releases}</td>
            <td>{item.assets}</td>
          </tr>
        ))}
      </tbody>
    </table>


                    </div>
                </div>
            </div>


        </div>
    );
};

export default TopClientOperatingSystem;