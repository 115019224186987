import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

import GCPTop5FailedControlsData from '../../../data/ops-view/SecurityStackCloudSecurity/GCPTop5FailedControls.json';


const GCPTop5FailedControls = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const data = props.data.charts;

    const [parentWidth, setParentWidth] = useState(0);

    useEffect(() => {
      const updateParentWidth = () => {
        const parentDiv = document.getElementById('parentDiv');
        if (parentDiv) {
          const width = parentDiv.offsetWidth;
          setParentWidth(width * 0.95); // Assigning 90% width
        }
      };
  
      updateParentWidth();
  
      window.addEventListener('resize', updateParentWidth);
  
      return () => {
        window.removeEventListener('resize', updateParentWidth);
      };
    }, []);
   

    return (
        <div style={{
            width: '32%', marginLeft: 0, display: 'flex', flexDirection: 'column', 
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>


            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>


                <div style={{ width: '100%' }}>

                    <h6 style={{ color: '#fff', textAlign: 'left' }}>GCP Top 5 Failed Controls</h6>
                    <hr />


                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                      <table  style={{width:'100%'}}>
                          <thead>
                              <th style={{fontSize:12, padding:5, width:'80%',color:'#fff'}}>Control</th>
                              <th style={{fontSize:12, padding:5, width:'20%',color:'#fff'}}>Resources Failed</th>
                          </thead>

                          <tbody>
                              <tr>
                                 <td>
                                    <span className="" style={{color:'#fff',fontSize:12}}>{data.gcpTop5FailedControls[0].controlName}</span>
                                    <hr style={{margin:'0.5rem 0'}}/>
                                    <span className="failedControlTableText" 
                                    style={{display:'block',
                                    color:'#fff',fontSize:10}}>Criticality :
                                        <div style={{backgroundColor:'red', color:'#fff', marginLeft:10, width:40, fontSize:10, padding:3, display:'inline',
                                        textAlign:'center', borderRadius:3}}>{data.gcpTop5FailedControls[0].criticality}</div></span>
                                 </td>

                                 <td>
                                 <span className="failedControlTableText" style={{color:'#fff',fontSize:12}}>{data.gcpTop5FailedControls[0].resourcesFailed}</span>
                                 </td>
                              </tr>

                              <tr>
                                <td>
                                    <span className="" style={{color:'#fff',fontSize:12}}>{data.gcpTop5FailedControls[1].controlName}</span>
                                    <hr style={{margin:'0.5rem 0'}}/>

                                    <span className="failedControlTableText"
                                     style={{display:'block',
                                     color:'#fff',fontSize:10}}>Criticality :
                                        <div style={{backgroundColor:'red', color:'#fff', marginLeft:10, width:40, fontSize:10, padding:3, display:'inline',
                                        textAlign:'center', borderRadius:3}}>{data.gcpTop5FailedControls[1].criticality}</div></span>
                                 </td>

                                 <td>
                                    <span className="failedControlTableText" style={{color:'#fff',fontSize:12}}>{data.gcpTop5FailedControls[1].resourcesFailed}</span>
                                 </td>
                              </tr>


                              <tr>
                                 <td>
                                    <span className="" style={{color:'#fff',fontSize:12}}>{data.gcpTop5FailedControls[2].controlName}</span>
                                    <hr style={{margin:'0.5rem 0'}}/>

                                  
                                    <span className="failedControlTableText" 
                                    style={{display:'block',
                                    color:'#fff',fontSize:10}}>Criticality :
                                        <div style={{backgroundColor:'red', color:'#fff', marginLeft:10, width:40, fontSize:10, padding:3, display:'inline',
                                        textAlign:'center', borderRadius:3}}>{data.gcpTop5FailedControls[2].criticality}</div></span>
                                 </td>

                                 <td>
                                  <span className="failedControlTableText" style={{color:'#fff',fontSize:12}}>{data.gcpTop5FailedControls[2].resourcesFailed}</span>
                                 </td>
                              </tr>


                              <tr>
                                 <td>
                                    <span className="" style={{color:'#fff',fontSize:12}}>{data.gcpTop5FailedControls[3].controlName}</span>
                                    <hr style={{margin:'0.5rem 0'}}/>

                                    <span className="failedControlTableText" 
                                    style={{display:'block',
                                    color:'#fff',fontSize:10}}>Criticality :
                                        <div style={{backgroundColor:'red', color:'#fff', marginLeft:10, width:40, fontSize:10, padding:3, display:'inline',
                                        textAlign:'center', borderRadius:3}}>{data.gcpTop5FailedControls[3].criticality}</div></span>
                                 </td>

                                 <td>
                                 <span className="failedControlTableText" style={{color:'#fff',fontSize:12}}>{data.gcpTop5FailedControls[3].resourcesFailed}</span>
                                 </td>
                              </tr>


                              <tr>
                                 <td>
                                    <span className="" style={{color:'#fff',fontSize:12}}>{data.gcpTop5FailedControls[4].controlName}</span>
                                    <hr style={{margin:'0.5rem 0'}}/>

                                    <span className="failedControlTableText" 
                                    style={{display:'block',
                                    color:'#fff',fontSize:10}}>Criticality : 
                                        <div style={{backgroundColor:'red', color:'#fff', marginLeft:10, width:40, fontSize:10, padding:3, display:'inline',
                                        textAlign:'center', borderRadius:3}}>{data.gcpTop5FailedControls[4].criticality}</div></span>
                                 </td>

                                 <td>
                                 <span className="failedControlTableText" style={{color:'#fff',fontSize:12}}> {data.gcpTop5FailedControls[4].resourcesFailed} </span>
                                 </td>
                              </tr>
                          </tbody>
                      </table>

                         
                    </div>
                </div>
            </div>


        </div>
    );
};

export default GCPTop5FailedControls;