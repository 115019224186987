import React, { useState, useEffect, useRef } from "react";

import {
  CCol,
  CRow,
  CContainer,
} from '@coreui/react'


import './style.css';


import TotalMonetaryImpact from './components/strategic/TotalMonetaryImpact';
import RiskByAssetCategory from './components/strategic/RiskByAssetCategory'
import WhichDataStoresNeedAttention from "./components/strategic/WhichDataStoresNeedAttention";

import BrandReviewSummary from "./components/strategic/BrandReviewSummary";
import TopReviewSources from "./components/strategic/TopReviewSources";
import ListingScore from "./components/strategic/ListingScore";
import ListingAccuracy from "./components/strategic/ListingAccuracy";
import Competition from "./components/strategic/Competition";
import RecentMentions from "./components/strategic/RecentMentions";

import WhatIsTheMonetaryValueOfData from "./components/strategic/WhatIsTheMonetaryValueOfData";
import OverallRiskScore from "./components/strategic/OverallRiskScore";
import ComplianceStatus from "./components/strategic/ComplianceStatus";
import RiskLevel from "./components/strategic/RiskLevel";
import ProjectStatusChart from "./components/strategic/ProjectStatusChart";
import OrganizationalDataRiskCards from "./components/strategic/OrganizationalDataRiskCards";
import WhatSensitiveDataTypesAreStored from "./components/strategic/WhatSensitiveDataTypesAreStored";
import WhatIsTheTrendOfRisks from "./components/strategic/WhatIsTheTrendOfRisks";
import WhoCanAccessSensitiveData from "./components/strategic/WhoCanAccessSensitiveData";
import WhereIsTheDataStored from "./components/strategic/WhereIsTheDataStored";
import WhichRiskNeedToBeResolvedFirst from "./components/strategic/WhichRiskNeedToBeResolvedFirst";
import TangibleImpactCards from "./components/strategic/TangibleImpactCards";

import australiaData from './datanew/Australia/data/strategicView.json';
import sydneyData from './datanew/Australia/Sydney/data/strategicView.json';
import sydneyCBDData from './datanew/Australia/Sydney/CBD/data/strategicView.json';

import europeData from './datanew/Europe/data/strategicView.json';
import berlinData from './datanew/Europe/Berlin/data/strategicView.json';
import centralBerlinData from './datanew/Europe/Berlin/Central Berlin/data/strategicView.json';

import indiaData from './datanew/India/data/strategicView.json';
import bangaloreData from './datanew/India/Bangalore/data/strategicView.json';
import indiranagarData from './datanew/India/Bangalore/Indiranagar/data/strategicView.json';

import singaporeData from './datanew/Singapore/data/strategicView.json';
import singaporeCityData from './datanew/Singapore/Singapore City/data/strategicView.json';
import marinaBayData from './datanew/Singapore/Singapore City/Marina Bay/data/strategicView.json';

import usaData from './datanew/USA/data/strategicView.json';
import losAngelesData from './datanew/USA/Los Angeles/data/strategicView.json';
import longBeachData from './datanew/USA/Los Angeles/Long Beach/data/strategicView.json';


const StrategicView = () => {
  

  const [selectedRegion, setSelectedRegion] = useState('Australia');
  const [selectedCity, setSelectedCity] = useState('select');
  const [selectedOffice, setSelectedOffice] = useState('select');  

  const handleRegionChange = (event) => {
    setSelectedRegion(event.target.value);
    setSelectedCity('select');
    setSelectedOffice('select');
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
    setSelectedOffice('select');
  };

  const handleOfficeChange = (event) => {
    setSelectedOffice(event.target.value);
  };


  const random = () => Math.round(Math.random() * 100)

  const regions = [
    {
      "name":"Australia",
      "cities":[
        {
          "name":"Sydney",
          "offices":[
            "CBD",            
          ]          
        },      
      ]
    },
    {
      "name":"Europe",
      "cities":[
        
        {
          "name":"Berlin",
          "offices":[
            "Central Berlin",                      
          ]          
        },        
      ]
    },
    {
      "name":"India",
      "cities":[
        
        {
          "name":"Bangalore",
          "offices":[            
            "Indiranagar",                   
          ]          
        },        
      ]
    },
    {
      "name":"Singapore",
      "cities":[
        {
          "name":"Singapore City",
          "offices":[            
            "Marina Bay",                                
          ]          
        },        
      ]
    },
    {
      "name":"USA",
      "cities":[        
        {
          "name":"Los Angeles",
          "offices":[            
            "Long Beach",
          ]          
        },      
        
      ]
    }
  ]
  
  

  let data = australiaData;

  if(selectedRegion == 'Australia' && selectedCity == 'select' && selectedOffice == 'select'){
    data = australiaData;
  }

  if(selectedRegion == 'Australia' && selectedCity == 'Sydney' && selectedOffice == 'select'){
    data = sydneyData;
  }

  if(selectedRegion == 'Australia' && selectedCity == 'Sydney' && selectedOffice == 'CBD'){
    data = sydneyCBDData;
  }


  if(selectedRegion == 'Europe' && selectedCity == 'select' && selectedOffice == 'select'){
    data = europeData;
  }

  if(selectedRegion == 'Europe' && selectedCity == 'Berlin' && selectedOffice == 'select'){
    data = berlinData;
  }

  if(selectedRegion == 'Europe' && selectedCity == 'Berlin' && selectedOffice == 'Central Berlin'){
    data = centralBerlinData;
  }


  if(selectedRegion == 'India' && selectedCity == 'select' && selectedOffice == 'select'){
    data = indiaData;
  }

  if(selectedRegion == 'India' && selectedCity == 'Bangalore' && selectedOffice == 'select'){
    data = bangaloreData;
  }

  if(selectedRegion == 'India' && selectedCity == 'Bangalore' && selectedOffice == 'Indiranagar'){
    data = indiranagarData;
  }

  if(selectedRegion == 'Singapore' && selectedCity == 'select' && selectedOffice == 'select'){
    data = singaporeData;
  }

  if(selectedRegion == 'Singapore' && selectedCity == 'Singapore City' && selectedOffice == 'select'){
    data = singaporeCityData;
  }

  if(selectedRegion == 'Singapore' && selectedCity == 'Singapore City' && selectedOffice == 'Marina Bay'){
    data = marinaBayData;
  }

  if(selectedRegion == 'USA' && selectedCity == 'select' && selectedOffice == 'select'){
    data = usaData;
  }

  if(selectedRegion == 'USA' && selectedCity == 'Los Angeles' && selectedOffice == 'select'){
    data = losAngelesData;
  }

  if(selectedRegion == 'USA' && selectedCity == 'Los Angeles' && selectedOffice == 'Long Beach'){
    data = longBeachData;
  }

  return (
    <>

      <CContainer style={{ marginBottom: 50 }}>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}>

          <h4>Strategic View</h4>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

          <select
        value={selectedRegion}
        onChange={handleRegionChange}
        style={{ marginLeft: 10, marginRight: 10, fontSize: 13, padding: 5 }}
      >
        <option value="select" disabled>Select Region</option>
        {regions.map(region => (
          <option key={region.name} value={region.name}>{region.name}</option>
        ))}
      </select>

      <select
        value={selectedCity}
        onChange={handleCityChange}
        style={{ marginLeft: 10, marginRight: 10, fontSize: 13, padding: 5 }}
      >
        <option value="select" disabled>Select City</option>
        {selectedRegion !== 'select' && regions.find(region => region.name === selectedRegion).cities.map(city => (
          <option key={city.name} value={city.name}>{city.name}</option>
        ))}
      </select>

      <select
        value={selectedOffice}
        onChange={handleOfficeChange}
        style={{ marginLeft: 10, marginRight: 10, fontSize: 13, padding: 5 }}
      >
        <option value="select" disabled>Select Office</option>
        {selectedCity !== 'select' && selectedRegion !== 'select' &&
          regions.find(region => region.name === selectedRegion)
                 .cities.find(city => city.name === selectedCity).offices.map((office, index) => (
          <option key={index} value={office}>{office}</option>
        ))}
      </select>
          </div>

        </div>

        {/* Row 1 - START */}
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', flexWrap: 'wrap', marginTop: 0 }}>


          <OverallRiskScore
            data={data}/>

          <ComplianceStatus
            data={data}/>
          
          <RiskLevel 
            data={data}/>

          <ProjectStatusChart
            data={data}/>

        </div>
        {/* END - Row 1 */}


        <div style={{ width: '100%', backgroundColor: '#89181b', paddingTop: 20, 
          paddingBottom: 20, paddingLeft: 20, paddingRight: 20, marginTop: 20, marginBottom: 20 }}>

          <span style={{ color: 'white', fontSize: 16, fontWeight: 'bold' }}>
            ORGANIZATIONAL DATA RISK DASHBOARD
          </span>

        </div>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', flexWrap: 'wrap', marginTop: 20 }}>


        <OrganizationalDataRiskCards
          data={data}/> 

        <WhatSensitiveDataTypesAreStored
          data={data}/>

        <WhatIsTheMonetaryValueOfData
          data={data}/>

        <WhichDataStoresNeedAttention 
          data={data}/>  

        <WhatIsTheTrendOfRisks
          data={data}/> 

        <WhoCanAccessSensitiveData
          data={data}/>  

        <WhereIsTheDataStored
          data={data}/> 

        <WhichRiskNeedToBeResolvedFirst
          data={data}/>
      </div>        




        <div style={{
          width: '100%', backgroundColor: '#89181b', paddingTop: 20, 
          paddingBottom: 20, paddingLeft: 20, paddingRight: 20, marginTop: 20,
          marginBottom: 5, display: 'flex', 
          flexDirection: 'row', justifyContent: 'center'
        }}>

          <span style={{ color: 'white', fontSize: 16, fontWeight: 'bold',
           alignSelf: 'center' }}>
            BUSINESS IMPACT DASHBOARD
          </span>

        </div>

        <div style={{
          width: '100%', backgroundColor: '#89181b', paddingTop: 20, paddingBottom: 20, paddingLeft: 20, paddingRight: 20, marginTop: 0,
          marginBottom: 0,display:'flex', flexDirection:'row', justifyContent:'center'
        }}>

          <span style={{ color: 'white', fontSize: 16, alignSelf: 'center', 
          fontWeight: 'bold' }}>
            Tangible Impact
          </span>

        </div>

        <TangibleImpactCards  data={data}/>

        {/* Tangible impact - Charts Row - START */}
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', flexWrap: 'wrap', marginTop: 20 }}>


          <TotalMonetaryImpact 
          data={data}/>

          <RiskByAssetCategory 
            data={data}/>


        </div>
        {/* END - Row 1 */}


        <div style={{
          width: '100%', backgroundColor: '#89181b', paddingTop: 20, 
          paddingBottom: 20, paddingLeft: 20, paddingRight: 20, marginTop: 20,
          marginBottom: 20, 
          display:'flex', flexDirection:'row', justifyContent:'center'
        }}>

          <span style={{ color: 'white', alignSelf: 'center', 
          fontSize: 16, fontWeight: 'bold' }}>
            Intangible Impact
          </span>

        </div>

        <span style={{ color: 'white', fontSize: 16, fontWeight: 'normal', }}>
          Reputation Management Dashboard to Manage Brand's Status
        </span>


        {/* Intangible Impact - Charts Row - START */}
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', flexWrap: 'wrap', marginTop: 0 }}>



          <BrandReviewSummary 
            data={data}/>

          <TopReviewSources 
            data={data}/>

{/*
          <ListingScore 
            data={data}/>

          <ListingAccuracy 
            data={data}/>

          <Competition 
            data={data}/>

      */}

          <RecentMentions 
            data={data}/>

        </div>


      </CContainer>
    </>
  )
}

export default StrategicView