import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';

import RemediationSLAOverviewData from '../../data/tactical-view/RemediationSLAOverview.json';


const RemediationSLAOverview = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const data = props.data.charts.remediationSLAOverview;

    return (
        <div style={{
            width: '55%', marginLeft: 0, display: 'flex', flexDirection: 'column', height: 500,
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>

           


            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 10 }}>


            <div style={{ height: 60, }}>


                    <h6 style={{ color: '#fff', textAlign: 'left' }}>Remediation SLA Overview</h6>
                    <hr />

                    </div>


                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>

                        <table style={{ width: '100%' }}>

                            <thead>

                                <th style={{ padding: 10, width:'1%',color:'#fff' }}>Distribution</th>
                                <th style={{ padding: 10, height: 60,  }}>
                                    <span style={{ backgroundColor: '#710119', 
                                    color: '#fff', padding: 10, borderRadius: 5, width:'100%', display:'block', textAlign:'center'
                                      }}>
                                        Critical
                                    </span>
                                </th>
                                <th style={{ padding: 10, height: 60, }}>
                                    <span style={{ backgroundColor: '#dd0015', color: '#fff', padding: 10, borderRadius: 5,
                                     width:'100%', display:'block', textAlign:'center' }} >
                                        High
                                    </span>
                                </th>
                                <th style={{ padding: 10, height: 60, }}>
                                    <span style={{ backgroundColor: '#fe8100', color: '#000', padding: 10, borderRadius: 5, 
                                    width:'100%', display:'block', textAlign:'center' }}>
                                        Medium
                                    </span>
                                </th>
                                <th style={{ padding: 10, height: 60, }}>
                                    <span style={{ backgroundColor: '#ffc900', color: '#000', padding: 10, borderRadius: 5,
                                    width:'100%', display:'block', textAlign:'center' }}>
                                        Low
                                    </span>
                                </th>

                            </thead>


                            <tbody>

                            {data.map(item => (


<tr>

<td style={{ padding: 5, }}>{item.distribution}</td>
<td style={{ padding: 0, height: 60 }}>

    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', border: '1px solid white', backgroundColor:'#fff', borderRadius:5 }}>

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

            <span style={{ color: '#00b6b4', padding: 5, fontWeight:'bold', fontSize:10, textAlign:'center' }}>
                Met SLA
            </span>

            <span style={{ color: '#00b6b4', padding: 5, fontWeight:'bold',fontSize:12, textAlign:'center'}}>
                {item.critical.metSLA}
            </span>

        </div>

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

            <span style={{ color: '#fe0000', padding: 5, fontWeight:'bold', fontSize:10, textAlign:'center' }}>
                Missed SLA
            </span>

            <span style={{ color: '#fe0000', padding: 5,fontWeight:'bold',fontSize:12, textAlign:'center' }}>
            {item.critical.missedSLA}
            </span>

        </div>
    </div>



</td>
<td style={{ padding: 10, height: 60 }}>
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', border: '1px solid white',backgroundColor:'#fff', borderRadius:5 }}>

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

            <span style={{ color: '#00b6b4', padding: 5,  fontWeight:'bold', fontSize:10, textAlign:'center'}}>
                Met SLA
            </span>

            <span style={{ color: '#00b6b4', padding: 5, fontWeight:'bold',fontSize:12, textAlign:'center'}}>
            {item.high.metSLA}
            </span>

        </div>

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

            <span style={{ color: '#fe0000', padding: 5, fontWeight:'bold', fontSize:10, textAlign:'center'}}>
                Missed SLA
            </span>

            <span style={{ color: '#fe0000', padding: 5, fontWeight:'bold',fontSize:12, textAlign:'center'}}>
            {item.high.missedSLA}
            </span>

        </div>
    </div>


</td>
<td style={{ padding: 10, height: 60 }}>

    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', border: '1px solid white',backgroundColor:'#fff', borderRadius:5 }}>

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

            <span style={{ color: '#00b6b4', padding: 5, fontWeight:'bold', fontSize:10, textAlign:'center'}}>
                Met SLA
            </span>

            <span style={{ color: '#00b6b4', padding: 5,fontWeight:'bold',fontSize:12, textAlign:'center' }}>
            {item.medium.metSLA}
            </span>

        </div>

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

            <span style={{ color: '#fe0000', padding: 5,fontWeight:'bold', fontSize:10, textAlign:'center' }}>
                Missed SLA
            </span>

            <span style={{ color: '#fe0000', padding: 5, fontWeight:'bold',fontSize:12, textAlign:'center'}}>
            {item.medium.missedSLA}
            </span>

        </div>
    </div>


</td>
<td style={{ padding: 10, height: 60 }}>

    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', border: '1px solid white', backgroundColor:'#fff', borderRadius:5 }}>

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

            <span style={{ color: '#00b6b4', padding: 5, fontWeight:'bold', fontSize:10, textAlign:'center'}}>
                Met SLA
            </span>

            <span style={{ color: '#00b6b4', padding: 5, fontWeight:'bold',fontSize:12, textAlign:'center'}}>
            {item.low.metSLA}
            </span>

        </div>

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

            <span style={{ color: '#fe0000', padding: 5, fontWeight:'bold', fontSize:10, textAlign:'center'}}>
                Missed SLA
            </span>

            <span style={{ color: '#fe0000', padding: 5,fontWeight:'bold',fontSize:12, textAlign:'center' }}>
            {item.low.missedSLA}
            </span>

        </div>
    </div>


</td>
</tr>

                  
                ))}






                            </tbody>



                        </table>




                    </div>
                </div>
            </div>


       
    );
};

export default RemediationSLAOverview;
