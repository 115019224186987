import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';

import ComplianceSummaryIndustryComplianceData from '../../data/tactical-view/ComplianceSummaryIndustryCompliance.json'; 

const ComplianceSummaryIndustryCompliance = () => {

    const [onLoading, setOnLoading] = useState(false)

    const data = ComplianceSummaryIndustryComplianceData;

    return (
        <div style={{
            width: '100%', marginLeft: 0, display: 'flex', flexDirection: 'column',
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>


            {onLoading &&

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

                    <ShimmerCircularImage size={500} />
                    <ShimmerCircularImage size={500} />
                </div>
            }



            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>


                <div style={{ width: '100%' }}>

                    <h6 style={{ color: '#fff', textAlign: 'left' }}>Compliance Summary - Industry Compliance</h6>
                    <hr />


                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems:'center' }}>
                        

                    <table className="complianceChecks">
      <thead>
        <tr>
          <th></th>
          {data.headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.data.map((row, index) => (
          <tr key={index}>
            <td>{row.category}</td>
            {row.values.map((value, idx) => (
              <td key={idx}>
                {idx === 1 ? (
                  <div className="green-bar">
                    <div className="inner-bar" style={{ width: '100%' }}>
                      <div className="inner-bar" style={{ width: `${value}%`, backgroundColor: 'darkgreen' }}>
                        <span className="bar-text">{value}%</span>
                      </div>
                    </div>
                  </div>
                ) : idx === 2 ? (
                  <div className="amber-bar">
                    <div className="inner-bar" style={{ width: '100%' }}>
                      <div className="inner-bar" style={{ width: `${value}%`, backgroundColor: 'darkred' }}>
                        <span className="bar-text">{value}%</span>
                      </div>
                    </div>
                  </div>
                ) : idx === 3 ? (
                  <div className="red-bar">
                    <div className="inner-bar" style={{ width: '100%' }}>
                      <div className="inner-bar" style={{ width: `${value}%`, backgroundColor: 'darkred' }}>
                        <span className="bar-text">{value}%</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  value
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>



                    </div>



                </div>


            </div>


        </div>
    );
};

export default ComplianceSummaryIndustryCompliance;
