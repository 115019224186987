import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import Login from './views/pages/preAuth/Login'

import AdminLogin from './views/pages/preAuth/AdminLogin'

import PasswordRecovery from './views/pages/preAuth/PasswordRecovery'
import PasswordReset from './views/pages/preAuth/PasswordReset'
import PasswordRecoverySendOtp from './views/pages/preAuth/PasswordRecoverySendOtp'
import DefaultLayout from './layout/DefaultLayout'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//global.baseURL = "http://localhost:3000";
global.baseURL = "https://samvp.intruceptlabs.com";


global.region = 'India';
global.city = 'Bengaluru';
global.office = 'Indiranagar;'

axios.defaults.baseURL = "https://sast-backend.intruceptlabs.com";
global.backendUrl = "https://sast-backend.intruceptlabs.com";

class App extends Component {    

  render() {
    
    return (
        <BrowserRouter>
        
          <Routes>
            <Route path="/" name="Login" element={<Login/>} />
            <Route exact path="/login" name="Login" element={<Login />} />
            <Route exact path="/password-recovery" name="Password Recovery" element={<PasswordRecovery/>} />
            <Route exact path="/password-recovery-send-otp" name="Password Recovery Send OTP" element={<PasswordRecoverySendOtp/>} />
            <Route exact path="/password-reset" name="Password Reset" element={<PasswordReset/>} />

            <Route exact path="/admin-login" name="Admin Login" element={<AdminLogin />} />

            <Route  path="*" name="dashboard" element={<DefaultLayout/>} />              
          </Routes>
          <ToastContainer />
        
        </BrowserRouter>    
    )
  }
}

export default App
