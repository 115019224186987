import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";



const SpiderWeb = () => {

    const [onLoading, setOnLoading] = useState(false)

    const [parentWidth, setParentWidth] = useState(0);

    const [screenWidth, setScreenWidth] = useState(1000);

    useEffect(() => {
        setScreenWidth (window.innerWidth - ((window.innerWidth * 25)/100))
    }, []);

    useEffect(() => {
      const updateParentWidth = () => {
        const parentDiv = document.getElementById('parentDiv');
        if (parentDiv) {
          const width = parentDiv.offsetWidth;
          setParentWidth(width * 0.95); // Assigning 90% width
        }
      };
  
      updateParentWidth();
  
      window.addEventListener('resize', updateParentWidth);
  
      return () => {
        window.removeEventListener('resize', updateParentWidth);
      };
    }, []);

    const options = {
        chart: {
          height: 350,
          type: 'radar',
        },
        dataLabels: {
          enabled: true
        },
        plotOptions: {
          radar: {
            size: 140,
            polygons: {
              strokeColors: '#e9e9e9',
              fill: {
                colors: ['#f8f8f8', '#fff']
              }
            }
          }
        },
        title: {
          text: ''
        },
        colors: ['#FF4560','#2185d0'],
        markers: {
          size: 4,
          colors: ['#fff'],
          strokeColor: '#FF4560',
          strokeWidth: 2,
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val
            }
          }
        },
        xaxis: {
          categories: [
            'CIS C01', 
            'CIS C02',
            'CIS C03',
            'CIS C04',
            'CIS C05',
            'CIS C06',
            'CIS C07',
            'CIS C08',
            'CIS C09',
            'CIS C010',
            'CIS C011',
            'CIS C012',
            'CIS C013',
            'CIS C014',
            'CIS C015',
            'CIS C016',
            'CIS C017',
            'CIS C018',
            'CIS C019',
            'CIS C020',          
        ]
        },
        yaxis: {
          tickAmount: 6,
          labels: {
            formatter: function(val, i) {
              if (i % 1 === 0) {
                return val
              } else {
                return ''
              }
            }
          }
        }
      };
    
      const series = [{
        name: 'Your Assessment',
        data: [0, 20, 40, 60, 80, 100, 0, 20, 40, 60, 80, 100, 0, 20, 40, 60, 80, 100, 90, 50],
      },
      {
        name: 'Industry Average',
        data: [10, 10, 50, 70, 90, 110, 10, 30, 50, 70, 90, 110, 10, 30,60,30, 50, 70, 90, 110],
      }];
   

    return (
        <div style={{
            width: '39%', marginLeft: 0, display: 'flex', 
            flexDirection: 'column', height: 450,
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>

            {onLoading &&

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

                    <ShimmerCircularImage size={500} />
                    <ShimmerCircularImage size={500} />
                </div>
            }


            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>


                <div style={{ width: '100%' }}>

                    <h6 style={{ color: '#fff', textAlign: 'left' }}>Spider Web</h6>
                    <hr />


                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} id="parentDiv">
                         

                    <ReactApexChart options={options} 
                    series={series} type="radar" height={350} width={screenWidth * 0.35}/>
                    

                    </div>
                </div>
            </div>


        </div>
    );
};

export default SpiderWeb;