import React, { useState, useEffect, useRef } from "react";

import axios from 'axios';

import {
    CCol,
    CRow,
    CContainer,
} from '@coreui/react'

import { Link } from "react-router-dom";

import './style.css';

import { HiOutlineDesktopComputer } from "react-icons/hi";
import { TbApi } from "react-icons/tb";
import { AiOutlineIssuesClose } from "react-icons/ai";
import { FiAlertCircle } from "react-icons/fi";
import { MdOutlinePersonalInjury } from "react-icons/md";
import { AiOutlineCloudServer } from "react-icons/ai";

import { BsCollection } from "react-icons/bs";
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

import { Shimmer, Breathing } from 'react-shimmer'
import { FaUsers } from "react-icons/fa";
import { MdApps } from "react-icons/md";
import { FaObjectGroup } from "react-icons/fa";
import { AiOutlineSecurityScan } from "react-icons/ai";
import { MdErrorOutline } from "react-icons/md";
import { SiHelpdesk } from "react-icons/si";
import { IoIosBusiness } from "react-icons/io";
import GaugeChart from 'react-gauge-chart'

import riskLevelChartData from './data/strategic-view/riskLevelChart.json';
import projectStatusTrendChart from './data/strategic-view/projectStatusTrendChart.json';

import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import Chart from 'react-apexcharts';

import MonthlyGraph from './components/csat/MonthlyGraph';
import SpiderWeb from './components/csat/SpiderWeb';
import ControlImplementationAverages from './components/csat/ControlImplementationAverages';
import GroupImplementationAverages from './components/csat/GroupImplementationAverages';
import MaturityLevelAverageScores from './components/csat/MaturityLevelAverageScores';
import MaturityIndexTables from './components/csat/MaturityIndexTables';

import data from './datanew/cisControls.json';


const CIS = () => {

    const random = () => Math.round(Math.random() * 100)

    const [loadingStats, setLoadingStats] = useState(false)

    const ref = useRef(null);

    const handleClick = (name) => {
        window.location.href = '/csat-details?index='+name;
    };

    return (
        <>

            <CContainer style={{ marginBottom: 50 }}>

               
                <div style={{
                    display: 'flex', flexDirection: 'row',
                    justifyContent: 'space-between', width: '100%',
                    flexWrap: 'wrap', marginTop: 20,
                }}>

                    <div style={{
                        display: 'flex', flexDirection: 'column',
                        justifyContent: 'space-between',
                        backgroundColor: '#fff', height: 120, padding: 20, borderRadius: 10, width: '23%'
                    }}>

                        <span  style={{ fontSize: 16, color: '#000', textAlign: 'center', fontWeight: 'bold' }}>Organizational Average</span>
                        <span  style={{ fontSize: 30, color: '#000', textAlign: 'center' }}>1</span>

                    </div>

                    <div style={{
                        display: 'flex', flexDirection: 'column',
                        justifyContent: 'space-between',
                        backgroundColor: '#fff', height: 120, padding: 20, borderRadius: 10, width: '23%'
                    }}>

                        <span  style={{ fontSize: 16, color: '#000', textAlign: 'center', fontWeight: 'bold' }}>Industry Average</span>
                        <span  style={{ fontSize: 30, color: '#000', textAlign: 'center' }}>14</span>

                    </div>


                    <div style={{
                        display: 'flex', flexDirection: 'column',
                        justifyContent: 'space-between',
                        backgroundColor: '#fff', height: 120, padding: 20, borderRadius: 10, width: '23%'
                    }}>

                        <span  style={{ fontSize: 16, color: '#000', textAlign: 'center', fontWeight: 'bold' }}>Completion %</span>
                        <span  style={{ fontSize: 30, color: '#000', textAlign: 'center' }}>0</span>

                    </div>


                    <div style={{
                        display: 'flex', flexDirection: 'column',
                        justifyContent: 'space-between',
                        backgroundColor: '#fff', height: 120, padding: 20, borderRadius: 10, width: '23%'
                    }}>

                        <span  style={{ fontSize: 16, color: '#000', textAlign: 'center', fontWeight: 'bold' }}>Validation %</span>
                        <span  style={{ fontSize: 30, color: '#000', textAlign: 'center' }}>0</span>

                    </div>

                </div>

                <div style={{
                    display: 'flex', flexDirection: 'row',
                    justifyContent: 'flex-start', width: '100%',
                    flexWrap: 'wrap', marginTop: 20,
                }}>


                {data.cisControls.map(item => (
                   <button 
                     onClick={() => handleClick(item.index)}
                    style={{
                        backgroundColor: '#2185d0', borderWidth: 0, padding: 10
                         , color: '#fff', borderRadius: 8, marginRight: 10, marginBottom: 10, fontSize: 14
                    }}>
                        {item.name}
                     </button>
                ))}                   
                   



                </div>


                <div style={{
                    display: 'flex', flexDirection: 'row',
                    justifyContent: 'space-between', width: '100%',
                    flexWrap: 'wrap', marginTop: 20,
                }}>

                    <MonthlyGraph/>
                    <SpiderWeb/>
                    <ControlImplementationAverages/>
                    <GroupImplementationAverages/>
                    <MaturityLevelAverageScores/>
                    <MaturityIndexTables/>


                </div>


            </CContainer>
        </>
    )
}

export default CIS