import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

import ThreatsClassificationData from '../../../data/ops-view/SecurityStackEmailSecurity/ThreatsClassification.json';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const ThreatsClassification = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const data = props.data.charts;

    const data1 = {
      labels: [data.threatsClassification[0].name, data.threatsClassification[1].name],
      datasets: [
        {
          data: [data.threatsClassification[0].value, data.threatsClassification[1].value],
          backgroundColor: ['#5dbc53', '#d76358'],
          hoverBackgroundColor: ['#5dbc53', '#d76358']
        }
      ],
      text: ""
    };
    
    const options1 = {
      plugins: {
        legend: {
          display: false
        }
      },
      cutout: 70
    };
    
    const plugins1 = [{
      id : 'pluginId',
      beforeDraw: function(chart) {
       var width = chart.width,
           height = chart.height,
           ctx = chart.ctx;
           ctx.restore();
           var fontSize = (height / 160).toFixed(2);
           ctx.font = fontSize + "em sans-serif";
           ctx.textBaseline = "top";
           ctx.textColor = "white";
           var text = `${((data.threatsClassification[1].value / 1000).toFixed(0))}K`,
           textX = Math.round((width - ctx.measureText(text).width) / 2),
           textY = height / 2;
           ctx.fillStyle = '#fff';
           ctx.fillText(text, textX, textY);
           ctx.save();
      } 
     }]

    const [parentWidth, setParentWidth] = useState(0);

    const [screenWidth, setScreenWidth] = useState(1000);

  useEffect(() => {
      setScreenWidth (window.innerWidth - ((window.innerWidth * 25)/100))
  }, []);

    useEffect(() => {
      const updateParentWidth = () => {
        const parentDiv = document.getElementById('parentDiv');
        if (parentDiv) {
          const width = parentDiv.offsetWidth;
          setParentWidth(width * 0.95); // Assigning 90% width
        }
      };
  
      updateParentWidth();
  
      window.addEventListener('resize', updateParentWidth);
  
      return () => {
        window.removeEventListener('resize', updateParentWidth);
      };
    }, []);
   

    return (
        <div style={{
            width: '32%', marginLeft: 0, display: 'flex', flexDirection: 'column', height:500, 
            
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>

          

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 10 }}>


            <div style={{ height: 60, }}>
                    <h6 style={{ color: '#fff', textAlign: 'left' }}>Threats Classification</h6>
                    <hr />

            </div>


                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height:380}}>


                     <div style={{width:'60%'}}> 

                    <Doughnut 
          type="doughnut" 
          data={data1} 
          options={options1} 
          plugins={plugins1} 
          width={screenWidth * 0.3}
                height={screenWidth * 0.3} 
         />


</div>
                    
                    </div>
                </div>
            </div>


    );
};

export default ThreatsClassification;