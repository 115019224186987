import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';

import ProjectStatusData from '../../data/tactical-view/ProjectStatus.json';

const ProjectStatus = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const data = props.data.charts.projectStatus;


    const ProgressBar1 = ({ percentage }) => {
        const fullBarStyle = {
            backgroundColor: '#f2f2f2',
            height: '20px',
            width: '100%',
            borderRadius: '5px',
            marginBottom: '5px',
            position: 'relative',
        };

        const progressBarStyle = {
            backgroundColor: 'rgb(0, 143, 251)',
            height: '100%',
            width: `${percentage}%`,
            borderRadius: '5px',
        };

        return (
            <div style={fullBarStyle}>
                <div style={progressBarStyle}></div>
            </div>
        );
    };

    const ProgressBar2 = ({ percentage }) => {
      const fullBarStyle = {
          backgroundColor: '#f2f2f2',
          height: '20px',
          width: '100%',
          borderRadius: '5px',
          marginBottom: '5px',
          position: 'relative',
      };

      const progressBarStyle = {
          backgroundColor: 'rgb(0, 227, 150)',
          height: '100%',
          width: `${percentage}%`,
          borderRadius: '5px',
      };

      return (
          <div style={fullBarStyle}>
              <div style={progressBarStyle}></div>
          </div>
      );
  };


  const ProgressBar3 = ({ percentage }) => {
    const fullBarStyle = {
        backgroundColor: '#f2f2f2',
        height: '20px',
        width: '100%',
        borderRadius: '5px',
        marginBottom: '5px',
        position: 'relative',
    };

    const progressBarStyle = {
        backgroundColor: 'rgb(254, 176, 25)',
        height: '100%',
        width: `${percentage}%`,
        borderRadius: '5px',
    };

    return (
        <div style={fullBarStyle}>
            <div style={progressBarStyle}></div>
        </div>
    );
};


    const series = data.series;   
    
      const options = {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: ['Jan 2021', 'Jan 2020', 'Jan 2019'],
        },
        yaxis: {
          min: 0,
          max: 50,
          tickAmount: 6,
          labels: {
            formatter: function (val) {
              return val.toFixed(0);
            }
          }
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'left',
          offsetX: 40
        },
        fill: {
          opacity: 1
        }
      };

    return (
        <div style={{
            width: '49%', marginLeft: 0, display: 'flex', flexDirection: 'column', height: 500,
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>


            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>


                <div style={{ width: '100%' }}>

                    <h6 style={{ color: '#fff', textAlign: 'left' }}>Projects Status</h6>
                    <hr />


                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width:'100%' }}>

                            <div style={{width:'30%', marginBottom:10}}>
                                <span className="whiteSpan" style={{fontSize:12}}>On Track ({data.onTrack}/{data.total})</span>
                                <ProgressBar1 percentage={data.onTrack/data.total * 100} />
                            </div>

                            <div style={{width:'30%',  marginBottom:10}}>
                                <span className="whiteSpan" style={{fontSize:12}}>Delayed ({data.delayed}/{data.total})</span>
                                <ProgressBar2 percentage={data.delayed/data.total * 100} />
                            </div>

                            <div style={{width:'30%',  marginBottom:10}}>
                                <span className="whiteSpan" style={{fontSize:12}}>Budget Issue ({data.budgetIssues}/{data.total})</span>
                                <ProgressBar3 percentage={data.budgetIssues/data.total * 100} />
                            </div>

                        </div>

                    </div>


                    <div id="chart">
                        <ReactApexChart options={options} series={series} type="bar" height={320} />
                    </div>


                </div>
            </div>


        </div>
    );
};

export default ProjectStatus;
