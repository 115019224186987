import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';

import ControlsData from '../../data/tactical-view/Controls.json';
import { FaCheckCircle } from "react-icons/fa";
import { FaMinusCircle } from "react-icons/fa";
import { MdWarning } from "react-icons/md";


const Controls = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const data = props.data.charts.controls;

    return (
        <div style={{
            width: '49%', marginLeft: 0, display: 'flex', flexDirection: 'column', height: 500,
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>



            {onLoading &&

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

                    <ShimmerCircularImage size={500} />
                    <ShimmerCircularImage size={500} />
                </div>
            }



            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>


                <div style={{ width: '100%' }}>

                    <h6 style={{ color: '#fff', textAlign: 'left' }}>Controls</h6>
                    <hr />


                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>


                        <table style={{ width: '100%' }}>

                            <tbody>

                                <tr>
                                    <td>
                                    </td>
                                    <td colspan="3" style={{ textAlign: 'center', backgroundColor: '#012060' }}>
                                        Cyber Control
                                    </td>

                                </tr>

                                <tr >
                                    <td></td>
                                    <td style={{ backgroundColor: '#94bbff', color: '#000', textAlign:'center' }}>Jan 2021</td>
                                    <td style={{ backgroundColor: '#94bbff', color: '#000', textAlign:'center'  }}>Jan 2020</td>
                                    <td style={{ backgroundColor: '#94bbff', color: '#000', textAlign:'center'  }}>Jan 2019</td>
                                </tr>

                                {data.map(item => (


                                    <tr>
                                        <td>{item.label}</td>

                                        <td style={{ border: '1px solid white', textAlign: 'center' }}>

                                            {item['Jan 2021'] == 'High' &&
                                                <FaCheckCircle size={25} style={{ color: 'green' }} />
                                            }

                                            {item['Jan 2021'] == 'Moderate' &&
                                                
                                                <FaMinusCircle size={25} style={{ color: 'red' }} />
                                            }


                                            {item['Jan 2021'] == 'Low' &&
                                                <MdWarning size={25} style={{ color: 'yellow' }} />
                                            }

                                        </td>
                                        <td style={{ border: '1px solid white', textAlign: 'center' }}>
                                             {item['Jan 2020'] == 'High' &&
                                                <FaCheckCircle size={25} style={{ color: 'green' }} />
                                            }

                                            {item['Jan 2020'] == 'Moderate' &&
                                                
                                                <FaMinusCircle size={25} style={{ color: 'red' }} />
                                            }


                                            {item['Jan 2020'] == 'Low' &&
                                                <MdWarning size={25} style={{ color: 'yellow' }} />
                                            }
                                        </td>
                                        <td style={{ border: '1px solid white', textAlign: 'center' }}>
                                            {item['Jan 2019'] == 'High' &&
                                                <FaCheckCircle size={25} style={{ color: 'green' }} />
                                            }

                                            {item['Jan 2019'] == 'Moderate' &&
                                                
                                                <FaMinusCircle size={25} style={{ color: 'red' }} />
                                            }


                                            {item['Jan 2019'] == 'Low' &&
                                                <MdWarning size={25} style={{ color: 'yellow' }} />
                                            }
                                        </td>
                                    </tr>

                                ))}



                            </tbody>
                        </table>


                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30, width: '100%', alignItems: 'flex-start' }}>

                            <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center' }}>

                                <FaCheckCircle size={16} style={{ color: 'green' }} />
                                <span className="whiteSpan" style={{ marginLeft: 10, fontSize: 13 }}>High/Full Coverage</span>

                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 10, alignItems:'center' }}>

                                <FaMinusCircle size={16} style={{ color: 'red' }} />
                                <span className="whiteSpan" style={{ marginLeft: 10, fontSize: 13 }}>Moderate Coverage</span>

                            </div>


                            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 10, alignItems: 'center' }}>

                                <MdWarning size={16} style={{ color: 'yellow' }} />
                                <span className="whiteSpan" style={{ marginLeft: 10, fontSize: 13 }}>Low Coverage</span>

                            </div>

                        </div>



                    </div>
                </div>
            </div>


        </div>
    );
};

export default Controls;
