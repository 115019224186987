import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";



const MaturityIndexTables = () => {

    const [onLoading, setOnLoading] = useState(false)


    const [parentWidth, setParentWidth] = useState(0);

    useEffect(() => {
      const updateParentWidth = () => {
        const parentDiv = document.getElementById('parentDiv');
        if (parentDiv) {
          const width = parentDiv.offsetWidth;
          setParentWidth(width * 0.95); 
        }
      };
  
      updateParentWidth();
  
      window.addEventListener('resize', updateParentWidth);
  
      return () => {
        window.removeEventListener('resize', updateParentWidth);
      };
    }, []);
   

    return (
        <div style={{
            width: '100%', marginLeft: 0, display: 'flex', flexDirection: 'column', 
           
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>



            {onLoading &&

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

                    <ShimmerCircularImage size={500} />
                    <ShimmerCircularImage size={500} />
                </div>
            }


            <div style={{ display: 'flex', flexDirection: 'row',
             justifyContent: 'space-between', marginTop: 10 }}>


                <div style={{ width: '24%' }}>  
                <span style={{fontSize:14, backgroundColor:'#fff', width:'100%', color:'#000', padding:10, fontWeight:'bold', display:'block'}}>Maturity Index - Policy Defined</span>                           

                        <table style={{width:'100%', marginTop:5}}>
                       
                             <thead>
                                <th style={{padding:10, width:'70%',color:'#fff'}}>Policy Defined</th>
                                <th style={{padding:10,color:'#fff'}}>Maturity</th>

                             </thead>

                             <tbody>

                                 <tr>
                                   <td style={{fontSize:12}}>No Policy</td>
                                   <td style={{fontSize:12}}>1</td>
                                </tr>

                                <tr>
                                   <td style={{fontSize:12}}>Informal Policy</td>
                                   <td style={{fontSize:12}}>1</td>
                                </tr>

                                <tr>
                                   <td style={{fontSize:12}}>Partially Written Policy</td>
                                   <td style={{fontSize:12}}>0</td>
                                </tr>

                                <tr>
                                   <td style={{fontSize:12}}>Written Policy</td>
                                   <td style={{fontSize:12}}>0</td>
                                </tr>

                                <tr>
                                   <td style={{fontSize:12}}>Approved Written Policy</td>
                                   <td style={{fontSize:12}}>0</td>
                                </tr>

                                <tr>
                                   <td style={{fontSize:12}}>Not Applicable</td>
                                   <td style={{fontSize:12}}>0</td>
                                </tr>
                             </tbody>


                          </table>

                </div>

                <div style={{ width: '24%' }}>                             

                <span style={{fontSize:14, backgroundColor:'#fff', width:'100%', color:'#000', padding:10, fontWeight:'bold', display:'block'}}>Maturity Index - Control Implemented</span>   
<table style={{width:'100%', marginTop:10}}>

     <thead>
        <th style={{padding:10,width:'70%',color:'#fff'}}>Control Implemented</th>
        <th style={{padding:10,color:'#fff'}}>Maturity</th>

     </thead>

     <tbody>

         <tr>
           <td style={{fontSize:12}}>Not Implemented</td>
           <td style={{fontSize:12}}>1</td>
        </tr>

        <tr>
           <td style={{fontSize:12}}>Parts of Policy Implemented</td>
           <td style={{fontSize:12}}>0</td>
        </tr>

        <tr>
           <td style={{fontSize:12}}>Implemented on Some Systems</td>
           <td style={{fontSize:12}}>0</td>
        </tr>

        <tr>
           <td style={{fontSize:12}}>Implemented on Most Systems</td>
           <td style={{fontSize:12}}>1</td>
        </tr>

        <tr>
           <td style={{fontSize:12}}>Implemented on All Systems</td>
           <td style={{fontSize:12}}>0</td>
        </tr>

        <tr>
           <td style={{fontSize:12}}>Not Applicable</td>
           <td style={{fontSize:12}}>0</td>
        </tr>
     </tbody>


  </table>

</div>

<div style={{ width: '24%' }}>                             

<span style={{fontSize:14, backgroundColor:'#fff', width:'100%', color:'#000', padding:10, fontWeight:'bold', display:'block'}}>Maturity Index - Control Reported</span>   
<table style={{width:'100%', marginTop:10}}>

     <thead>
        <th style={{padding:10, width:'70%',color:'#fff'}}>Control Reported</th>
        <th style={{padding:10,color:'#fff'}}>Maturity</th>

     </thead>

     <tbody>

         <tr>
           <td style={{fontSize:12}}>Not Reported</td>
           <td style={{fontSize:12}}>0</td>
        </tr>

        <tr>
           <td style={{fontSize:12}}>Parts of Policy Reported</td>
           <td style={{fontSize:12}}>0</td>
        </tr>

        <tr>
           <td style={{fontSize:12}}>Reported on Some Systems</td>
           <td style={{fontSize:12}}>2</td>
        </tr>

        <tr>
           <td style={{fontSize:12}}>Reported on Most Systems</td>
           <td style={{fontSize:12}}>0</td>
        </tr>

        <tr>
           <td style={{fontSize:12}}>Reported on All Systems</td>
           <td style={{fontSize:12}}>0</td>
        </tr>

        <tr>
           <td style={{fontSize:12}}>Not Applicable</td>
           <td style={{fontSize:12}}>0</td>
        </tr>
     </tbody>


  </table>

</div>


<div style={{ width: '24%' }}>                             
<span style={{fontSize:14, backgroundColor:'#fff', width:'100%', color:'#000', padding:10, fontWeight:'bold', display:'block'}}>Maturity Index - Control Automated</span>   
<table style={{width:'100%'}}>

     <thead>
        <th style={{padding:10,width:'70%',color:'#fff'}}>Control Automated</th>
        <th style={{padding:10,color:'#fff'}}>Maturity</th>

     </thead>

     <tbody>

         <tr>
           <td style={{fontSize:12}}>Not Automated</td>
           <td style={{fontSize:12}}>0</td>
        </tr>

        <tr>
           <td style={{fontSize:12}}>Parts of Policy Automated</td>
           <td style={{fontSize:12}}>0</td>
        </tr>

        <tr>
           <td style={{fontSize:12}}>Automated on Some Systems</td>
           <td style={{fontSize:12}}>0</td>
        </tr>

        <tr>
           <td style={{fontSize:12}}>Automated on Most Systems</td>
           <td style={{fontSize:12}}>1</td>
        </tr>

        <tr>
           <td style={{fontSize:12}}>Automated on All Systems</td>
           <td style={{fontSize:12}}>1</td>
        </tr>

        <tr>
           <td style={{fontSize:12}}>Not Applicable</td>
           <td style={{fontSize:12}}>0</td>
        </tr>
     </tbody>


  </table>

</div>


            </div>


        </div>
    );
};

export default MaturityIndexTables;