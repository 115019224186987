import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

//import data from '../../../datanew/Australia/data/opsView.json';

const OperatingSystemDistribution = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const [screenWidth, setScreenWidth] = useState(1000);

    const data = props.data;

    console.log('data:', data)

  useEffect(() => {
      setScreenWidth (window.innerWidth - ((window.innerWidth * 25)/100))
  }, []);

  const options = {
    chart: {
      type: 'donut',      
    },
    legend:{
        show:true,
        position:'bottom'
      },
    labels: data.charts.operatingSystemsDistribution.map(item => item.name),
  };

  const series = data.charts.operatingSystemsDistribution.map(item => item.value);

   

    return (
        <div style={{
            width: '32%', marginLeft: 0, display: 'flex', flexDirection: 'column', 
            height: 450,
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>




            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-betweem', marginTop: 10 }}>


            <div style={{ height: 60, }}>

                    <h6 style={{ color: '#fff', textAlign: 'left' }}>
                    Operating System Distribution</h6>
                    <hr />

                    </div>


                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height:340 }}>
                         
                    <ReactApexChart options={options} series={series} 
                    type="donut" height={screenWidth * 0.2} width={screenWidth * 0.2}/>

                    </div>
                </div>


        </div>
    );
};

export default OperatingSystemDistribution;