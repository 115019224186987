import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

import ResourcesCardsData from '../../../data/ops-view/SecurityStackCloudSecurity/ResourcesCards.json';
import iam from '../../../../../assets/images/iam.png'
import { FaNetworkWired } from "react-icons/fa";
import { GiProcessor } from "react-icons/gi";
import { FaDatabase } from "react-icons/fa";
import { MdOutlineSecurity } from "react-icons/md";
import { MdManageAccounts } from "react-icons/md";
import { AiFillContainer } from "react-icons/ai";
import { SiKubernetes } from "react-icons/si";


const ResourcesCards = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const data = props.data.charts;

    const [parentWidth, setParentWidth] = useState(0);

    useEffect(() => {
      const updateParentWidth = () => {
        const parentDiv = document.getElementById('parentDiv');
        if (parentDiv) {
          const width = parentDiv.offsetWidth;
          setParentWidth(width * 0.95); // Assigning 90% width
        }
      };
  
      updateParentWidth();
  
      window.addEventListener('resize', updateParentWidth);
  
      return () => {
        window.removeEventListener('resize', updateParentWidth);
      };
    }, []);
   

    return (
        <div style={{
            width: '100%', marginLeft: 0, display: 'flex', flexDirection: 'column', 
            marginTop: '2%',  padding: 20, borderRadius: 15, 
        }}>



         

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>


                <div style={{ width: '100%' }}>

                   

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexWrap:'wrap' }}>

                        <div style={{display:'flex', flexDirection:'column', 
                            justifyContent:'space-between', alignItems:'center', width:'23%', backgroundColor:'#fff', padding:20, borderRadius:15, 
                                height:180}}>

                            <img src={iam} width="50"/>

                            <span style={{color:'#000', fontSize:25, fontWeight:'bold', textAlign:'center'}}>{data.resources[0].value}</span>
                            <span style={{color:'#000', fontSize:17, fontWeight:'bold', textAlign:'center'}}>{data.resources[0].name}</span>

                        </div>


                        <div style={{display:'flex', flexDirection:'column', 
                            justifyContent:'space-between', alignItems:'center', width:'23%', backgroundColor:'#fff', padding:20, borderRadius:15, 
                                height:180}}>

<FaNetworkWired size={50} style={{color:'#1e88e5'}}/>

                            <span style={{color:'#000', fontSize:25, fontWeight:'bold', textAlign:'center'}}>{data.resources[1].value}</span>
                            <span style={{color:'#000', fontSize:17, fontWeight:'bold', textAlign:'center'}}>{data.resources[1].name}</span>

                        </div>


                        <div style={{display:'flex', flexDirection:'column', 
                            justifyContent:'space-between', alignItems:'center', width:'23%', backgroundColor:'#fff', padding:20, borderRadius:15, 
                                height:180}}>

                                    <GiProcessor size={50} style={{color:'#388e3c'}}/>

                            <span style={{color:'#000', fontSize:25, fontWeight:'bold', textAlign:'center'}}>{data.resources[2].value}</span>
                            <span style={{color:'#000', fontSize:17, fontWeight:'bold', textAlign:'center'}}>{data.resources[2].name}</span>

                        </div>


                        <div style={{display:'flex', flexDirection:'column', 
                            justifyContent:'space-between', alignItems:'center', width:'23%', backgroundColor:'#fff', padding:20, borderRadius:15, 
                                height:180}}>


<FaDatabase size={50} style={{color:'#9c27b0'}}/>


                            <span style={{color:'#000', fontSize:25, fontWeight:'bold', textAlign:'center'}}>{data.resources[3].value}</span>
                            <span style={{color:'#000', fontSize:17, fontWeight:'bold', textAlign:'center'}}>{data.resources[3].name}</span>

                        </div>


                        <div style={{display:'flex', flexDirection:'column', 
                            justifyContent:'space-between', alignItems:'center', width:'23%', backgroundColor:'#fff', padding:20, borderRadius:15, 
                                height:180, marginTop:30}}>

<MdOutlineSecurity size={50} style={{color:'#607d8b'}}/>


                            <span style={{color:'#000', fontSize:25, fontWeight:'bold', textAlign:'center'}}>{data.resources[4].value}</span>
                            <span style={{color:'#000', fontSize:17, fontWeight:'bold', textAlign:'center'}}>{data.resources[4].name}</span>

                        </div>


                        <div style={{display:'flex', flexDirection:'column', 
                            justifyContent:'space-between', alignItems:'center', width:'23%', backgroundColor:'#fff', padding:20, borderRadius:15, 
                                height:180, marginTop:30}}>

<MdManageAccounts size={50} style={{color:'#009688'}}/>


                            <span style={{color:'#000', fontSize:25, fontWeight:'bold', textAlign:'center'}}>{data.resources[5].value}</span>
                            <span style={{color:'#000', fontSize:17, fontWeight:'bold', textAlign:'center'}}>{data.resources[5].name}</span>

                        </div>



                        <div style={{display:'flex', flexDirection:'column', 
                            justifyContent:'space-between', alignItems:'center', width:'23%', backgroundColor:'#fff', padding:20, borderRadius:15, 
                                height:180, marginTop:30}}>

<AiFillContainer size={50} style={{color:'#ff9800'}}/>


                            <span style={{color:'#000', fontSize:25, fontWeight:'bold', textAlign:'center'}}>{data.resources[6].value}</span>
                            <span style={{color:'#000', fontSize:17, fontWeight:'bold', textAlign:'center'}}>{data.resources[6].name}</span>

                        </div>



                        <div style={{display:'flex', flexDirection:'column', 
                            justifyContent:'space-between', alignItems:'center', width:'23%', backgroundColor:'#fff', padding:20, borderRadius:15, 
                                height:180, marginTop:30}}>

<SiKubernetes size={50} style={{color:'#1e88e5'}}/>


                            <span style={{color:'#000', fontSize:25, fontWeight:'bold', textAlign:'center'}}>{data.resources[7].value}</span>
                            <span style={{color:'#000', fontSize:17, fontWeight:'bold', textAlign:'center'}}>{data.resources[7].name}</span>

                        </div>



                         
                    </div>


                    
                </div>
            </div>


        </div>
    );
};

export default ResourcesCards;