import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';

import FRTThisMonthData from '../../data/tactical-view/FRTThisMonth.json';


const FRTThisMonth = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const data = props.data.charts.frtThisMonth;

    return (
        <div style={{
            width: '49%', marginLeft: 0, display: 'flex', flexDirection: 'column', height: 470,
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>


            <div style={{ display: 'flex', flexDirection: 'column', 
                justifyContent: 'space-between', marginTop: 10 }}>


                <div style={{ height: 60, }}>

                    <h6 style={{ color: '#fff', textAlign: 'left' }}>FRT this Month</h6>
                    <hr />

                    </div>


                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                        <div style={{
                            display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#fff', padding: 10,
                            borderRadius: 10, width: 200
                        }}>

                            <span style={{ fontSize: 30, color: '#000' }}>
                                {data.averageInMinutes} <span style={{ fontSize: 20, color: '#000' }}>mins</span>
                            </span>
                            <span style={{ fontSize: 14, color: '#000' }}>
                                Average
                            </span>
                        </div>




                    </div>

                    <table style={{ marginTop: 20 }}>

                        <thead>
                            <th style={{ padding: 10 }}>Agent</th>
                            <th style={{ padding: 10 }}>FRT</th>
                        </thead>

                        <tbody>

                            {data.frtData.map(item => (

                                <tr>
                                    <td>{item.agent}</td>
                                    <td>{item.frt}</td>
                                </tr>

                            ))}


                        </tbody>

                    </table>



                </div>


            </div>


       
    );
};

export default FRTThisMonth;
