import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

import CWETop25MostDangerousData from '../../../data/ops-view/SecurityStackAppSecurity/CWETop25MostDangerous.json';

const CWETop25MostDangerous = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const data = props.data.charts;

    const [screenWidth, setScreenWidth] = useState(1000);

    useEffect(() => {
        setScreenWidth (window.innerWidth - ((window.innerWidth * 25)/100))
    }, []);

    const labels = data.cweTop25MostDangerousSoftwareWeaknesses.map(item => item.cwe);
    const values = data.cweTop25MostDangerousSoftwareWeaknesses.map(item => item.value);

    // Generating random colors for the bars
 //   const colors = Array.from({ length: values.length }, () => `#${Math.floor(Math.random() * 16777215).toString(16)}`);

    const colors = [
        '#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800',
        '#9C27B0', '#03A9F4', '#4CAF50', '#F44336', '#FFEB3B',
        '#795548', '#607D8B', '#9E9E9E', '#3F51B5', '#00BCD4',
        '#8BC34A', '#CDDC39', '#FF5722', '#FFC107', '#2196F3',
        '#9C27B0', '#03A9F4', '#4CAF50', '#F44336', '#FFEB3B'
      ];
  // Chart options
  const options = {
    chart: {
      type: 'bar',
      toolbar: {
        show: true
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        distributed: true,
        barHeight: '80%',
        dataLabels: {
          position: 'top'
        }
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ['#fff']
      }
    },
    xaxis: {
      categories: labels
    },
    yaxis: {
      labels: {
        show: false
      }
    },
    colors: colors,
    tooltip: {
      enabled: true
    }
  };

  // Chart series
  const series = [{
    name: 'Values',
    data: values
  }];

  console.log('screenWidth:', screenWidth)
   

    return (
        <div style={{
            width: '100%', marginLeft: 0, display: 'flex', 
            flexDirection: 'column', height:570,
            marginTop: '2%',
             background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>



            {onLoading &&

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

                    <ShimmerCircularImage size={500} />
                    <ShimmerCircularImage size={500} />
                </div>
            }


            <div style={{ display: 'flex', 
            flexDirection: 'row', justifyContent: 'space-around', 
            marginTop: 10 }} id="cweTop25Div">


                <div style={{ width: '100%' }}>

                    <h6 style={{ color: '#fff', textAlign: 'left' }}>
                        CWE Top 25 Most Dangerous Software Weaknesses</h6>
                    <hr />

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} id="parentDiv">
                         
                    <ReactApexChart options={options} 
                        series={series} type="bar" height={500} 
                         width={screenWidth}/>

                    </div>
                </div>
            </div>


        </div>
    );
};

export default CWETop25MostDangerous;