import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import GaugeChart from 'react-gauge-chart'

import CyberHygieneScoreData from '../../../data/ops-view/SecurityStackEndpointSecurity/CyberHygieneScore.json';

const CyberHygieneScore = (props) => {

  const [onLoading, setOnLoading] = useState(false)

  const data = props.data.charts;

  const [parentWidth, setParentWidth] = useState(0);
  const [screenWidth, setScreenWidth] = useState(1000);

  useEffect(() => {
      setScreenWidth (window.innerWidth - ((window.innerWidth * 25)/100))
  }, []);


  useEffect(() => {
    const updateParentWidth = () => {
      const parentDiv = document.getElementById('parentDiv');
      if (parentDiv) {
        const width = parentDiv.offsetWidth;
        setParentWidth(width * 0.95); // Assigning 90% width
      }
    };

    updateParentWidth();

    window.addEventListener('resize', updateParentWidth);

    return () => {
      window.removeEventListener('resize', updateParentWidth);
    };
  }, []);


  return (
    <div style={{
      width: '49%', marginLeft: 0, display: 'flex', flexDirection: 'column', height: 550,
      marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
    }}>




      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 10 }}>

      <div style={{ height: 60, }}>

          <h6 style={{ color: '#fff', textAlign: 'left' }}>Cyber Hygiene Score</h6>
          <hr />

          </div>


          <div style={{
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
            alignItems: 'center',
          }}>

<div style={{
            display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',height: 400, width:'60%',
            alignItems: 'center',
          }}>

            <GaugeChart id="gauge-chart3"
              nrOfLevels={3}
              colors={['#fa4647', '#e5e43f', '#22bb3d']}
              arcWidth={0.2}
              percent={data.cyberHygieneScore.score / 100}
              hideText={true}
             
            />
            </div>



            <span style={{ fontSize: 45, color: '#fff', marginTop: -250 }}>{data.cyberHygieneScore.score}</span>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 150, }}>

              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ width: 10, height: 10, backgroundColor: '#fa4647' }}></div>
                <span className="whiteSpan" style={{marginLeft:10}}>Low</span>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft:30 }}>
                <div style={{ width: 10, height: 10, backgroundColor: '#e5e43f' }}></div>
                <span className="whiteSpan" style={{marginLeft:10}}>Medium</span>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center',  marginLeft:30 }}>
                <div style={{ width: 10, height: 10, backgroundColor: '#22bb3d' }}></div>
                <span className="whiteSpan" style={{marginLeft:10}}>High</span>
              </div>

            </div>




          </div>
        </div>
      </div>


  );
};

export default CyberHygieneScore;