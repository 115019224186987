import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';
import '../../style.css';
import riskLevelChartData from '../../data/strategic-view/riskLevelChart.json';

import { Shimmer, Breathing } from 'react-shimmer'
import { FaUsers } from "react-icons/fa";
import { MdApps } from "react-icons/md";
import { FaObjectGroup } from "react-icons/fa";
import { AiOutlineSecurityScan } from "react-icons/ai";
import { MdErrorOutline } from "react-icons/md";
import { SiHelpdesk } from "react-icons/si";
import { IoIosBusiness } from "react-icons/io";

const OrganizationalDataRiskCards = (props) => {

  const [onLoading, setOnLoading] = useState(false)
  const [screenWidth, setScreenWidth] = useState(1000);

  const data = props.data;


  useEffect(() => {
    setScreenWidth(window.innerWidth - ((window.innerWidth * 25) / 100))
  }, []);


  return (
    <div style={{
      width: '100%', marginLeft: 0,
      display: 'flex', flexDirection: 'column',
      marginTop: '0%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
    }}>

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>

        <div style={{ width: '100%' }}>


          {/* Organizational Data Risk Dashboard - Cards - START */}
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', flexWrap: 'wrap' }}>

            <div className="dashboardCard" style={{
              display: 'flex', flexDirection: 'column',
              borderRadius: 10, padding: 20,alignItems:'center'
            }}>


              <FaUsers size={40} style={{ color: '#ffaa05' }} />

              <h3 style={{ color: '#fff', fontWeight: 600, fontSize: 20, textAlign:'center', marginTop:20 }}>{data.charts.operationalDataRiskCards[0].value}</h3>
              <span style={{ fontSize: 14, color: '#ffaa05',textAlign:'center', width:'100%' }}>{data.charts.operationalDataRiskCards[0].name}</span>
             

            </div>


            <div className="dashboardCard" style={{ display: 'flex', flexDirection: 'column', borderRadius: 10, padding: 20,alignItems:'center' }}>

                  <FaObjectGroup size={40} style={{ color: '#7366ff' }} />
                
                  <h3 style={{ color: '#fff', fontWeight: 600, fontSize: 20, textAlign:'center', marginTop:20 }}>{data.charts.operationalDataRiskCards[1].value} </h3>                
                  <span style={{ fontSize: 14, color: '#7366ff',textAlign:'center', width:'100%' }}>{data.charts.operationalDataRiskCards[1].name}</span>              

            </div>

            <div className="dashboardCard" style={{ display: 'flex', flexDirection: 'column', borderRadius: 10, padding: 20,alignItems:'center' }}>


                <IoIosBusiness size={40} style={{ color: '#1ecbe1' }} />

              
                <h3 style={{ color: '#fff', fontWeight: 600, fontSize: 20, textAlign:'center', marginTop:20 }}>{data.charts.operationalDataRiskCards[2].value}</h3>               
                <span style={{ fontSize: 16, color: '#1ecbe1',textAlign:'center', width:'100%' }}>{data.charts.operationalDataRiskCards[2].name}</span>           


            </div>


            <div className="dashboardCard" style={{ display: 'flex', flexDirection: 'column', borderRadius: 10, padding: 20,alignItems:'center' }}>

                <MdApps size={40} style={{ color: '#f73164', }} />

              
                <h3 style={{ color: '#fff', fontWeight: 600, fontSize: 20, textAlign:'center', marginTop:20 }}>{data.charts.operationalDataRiskCards[3].value}</h3>
               
                <span style={{ fontSize: 16, color: '#f73164', textAlign:'center', width:'100%'}}>{data.charts.operationalDataRiskCards[3].name}</span>
             

            </div>

          </div>
          {/* END -Organizational Data Risk Dashboard - Cards */}





        </div>

      </div>
    </div>

  );
};

export default OrganizationalDataRiskCards;
