import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";


import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const Incidents = (props) => {

  const data = props.data.charts;

  const data1 = {
    labels: ['Fail', 'Pass'],
    datasets: [
      {
        data: [data.incidents[0].success, data.incidents[0].blocked],
        backgroundColor: ['#5dbc53', '#d76358'],
        hoverBackgroundColor: ['#5dbc53', '#d76358']
      }
    ],
    text: ""
  };

  const options1 = {
    plugins: {
      legend: {
        display: false
      }
    },
    cutout: 60
  };

  const plugins1 = [{
    id: 'pluginId',
    beforeDraw: function (chart) {
      var width = chart.width,
        height = chart.height,
        ctx = chart.ctx;
      ctx.restore();
      var fontSize = (height / 160).toFixed(2);
      ctx.font = fontSize + "em sans-serif";
      ctx.textBaseline = "top";
      ctx.textColor = "white";
      var text = `${((data.incidents[0].success) / (data.incidents[0].success + data.incidents[0].blocked) * 100).toFixed(0)}%`,
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 2;
      ctx.fillStyle = '#fff';
      ctx.fillText(text, textX, textY);
      ctx.save();
    }
  }]

  //
  const data2 = {
    labels: ['Fail', 'Pass'],
    datasets: [
      {
        data: [data.incidents[1].success, data.incidents[1].blocked],
        backgroundColor: ['#5dbc53', '#d76358'],
        hoverBackgroundColor: ['#5dbc53', '#d76358']
      }
    ],
    text: ""
  };

  const options2 = {
    plugins: {
      legend: {
        display: false
      }
    },
    cutout: 60
  };

  const plugins2 = [{
    id: 'pluginId',
    beforeDraw: function (chart) {
      var width = chart.width,
        height = chart.height,
        ctx = chart.ctx;
      ctx.restore();
      var fontSize = (height / 160).toFixed(2);
      ctx.font = fontSize + "em sans-serif";
      ctx.textBaseline = "top";
      ctx.textColor = "white";
      var text = `${((data.incidents[1].success) / (data.incidents[1].success + data.incidents[1].blocked) * 100).toFixed(0)}%`,
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 2;
      ctx.fillStyle = '#fff';
      ctx.fillText(text, textX, textY);
      ctx.save();
    }
  }]

  //
  const data3 = {
    labels: ['Fail', 'Pass'],
    datasets: [
      {
        data: [data.incidents[2].success, data.incidents[2].blocked],
        backgroundColor: ['#5dbc53', '#d76358'],
        hoverBackgroundColor: ['#5dbc53', '#d76358']
      }
    ],
    text: ""
  };

  const options3 = {
    plugins: {
      legend: {
        display: false
      }
    },
    cutout: 60
  };

  const plugins3 = [{
    id: 'pluginId',
    beforeDraw: function (chart) {
      var width = chart.width,
        height = chart.height,
        ctx = chart.ctx;
      ctx.restore();
      var fontSize = (height / 160).toFixed(2);
      ctx.font = fontSize + "em sans-serif";
      ctx.textBaseline = "top";
      ctx.textColor = "white";
      var text = `${((data.incidents[2].success) / (data.incidents[2].success + data.incidents[2].blocked) * 100).toFixed(0)}%`,
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 2;
      ctx.fillStyle = '#fff';
      ctx.fillText(text, textX, textY);
      ctx.save();
    }
  }]

  //
  const data4 = {
    labels: ['Fail', 'Pass'],
    datasets: [
      {
        data: [data.incidents[3].success, data.incidents[3].blocked],
        backgroundColor: ['#5dbc53', '#d76358'],
        hoverBackgroundColor: ['#5dbc53', '#d76358']
      }
    ],
    text: ""
  };

  const options4 = {
    plugins: {
      legend: {
        display: false
      }
    },
    cutout: 60
  };

  const plugins4 = [{
    id: 'pluginId',
    beforeDraw: function (chart) {
      var width = chart.width,
        height = chart.height,
        ctx = chart.ctx;
      ctx.restore();
      var fontSize = (height / 160).toFixed(2);
      ctx.font = fontSize + "em sans-serif";
      ctx.textBaseline = "top";
      ctx.textColor = "white";
      var text = `${((data.incidents[3].success) / (data.incidents[3].success + data.incidents[3].blocked) * 100).toFixed(0)}%`,
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 2;
      ctx.fillStyle = '#fff';
      ctx.fillText(text, textX, textY);
      ctx.save();
    }
  }]


  //
  const data5 = {
    labels: ['Fail', 'Pass'],
    datasets: [
      {
        data: [data.incidents[4].success, data.incidents[4].blocked],
        backgroundColor: ['#5dbc53', '#d76358'],
        hoverBackgroundColor: ['#5dbc53', '#d76358']
      }
    ],
    text: ""
  };

  const options5 = {
    plugins: {
      legend: {
        display: false
      }
    },
    cutout: 60
  };

  const plugins5 = [{
    id: 'pluginId',
    beforeDraw: function (chart) {
      var width = chart.width,
        height = chart.height,
        ctx = chart.ctx;
      ctx.restore();
      var fontSize = (height / 160).toFixed(2);
      ctx.font = fontSize + "em sans-serif";
      ctx.textBaseline = "top";
      ctx.textColor = "white";
      var text = `${((data.incidents[4].success) / (data.incidents[4].success + data.incidents[4].blocked) * 100).toFixed(0)}%`,
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 2;
      ctx.fillStyle = '#fff';
      ctx.fillText(text, textX, textY);
      ctx.save();
    }
  }]

  const [onLoading, setOnLoading] = useState(false)
  const [parentWidth, setParentWidth] = useState(0);

  const [screenWidth, setScreenWidth] = useState(0);
  useEffect(() => {

    setScreenWidth(window.innerWidth - ((window.innerWidth * 25) / 100));

  }, []);

  useEffect(() => {
    const updateParentWidth = () => {
      const parentDiv = document.getElementById('parentDiv');
      if (parentDiv) {
        const width = parentDiv.offsetWidth;
        setParentWidth(width * 0.95); // Assigning 90% width
      }
    };

    updateParentWidth();

    window.addEventListener('resize', updateParentWidth);

    return () => {
      window.removeEventListener('resize', updateParentWidth);
    };
  }, []);


  return (
    <div style={{
      width: '100%', marginLeft: 0, display: 'flex', flexDirection: 'column',

      marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
    }}>
      

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>


        <div style={{ width: '100%' }}>

          <h6 style={{ color: '#fff', textAlign: 'left' }}>Incidents</h6>
          <hr />


          <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
            alignItems: 'center',
          }}>


            <div style={{
              display: 'flex', flexDirection: 'column',
              justifyContent: 'space-between', width: '15%', alignItems: 'center'
            }}>


              <Doughnut
                type="doughnut"
                data={data1}
                options={options1}
                plugins={plugins1}

              />



              <span style={{ color: '#fff', fontSize: 15, marginTop: 20 }}>{data.incidents[0].date} </span>
            </div>



            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '15%', alignItems: 'center' }}>


              <Doughnut
                type="doughnut"
                data={data2}
                options={options2}
                plugins={plugins3}

              />

              <span style={{ color: '#fff', fontSize: 15, marginTop: 20 }}>{data.incidents[1].date} </span>

            </div>


            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '15%', alignItems: 'center' }}>



              <Doughnut
                type="doughnut"
                data={data3}
                options={options3}
                plugins={plugins3}

              />

              <span style={{ color: '#fff', fontSize: 15, marginTop: 20 }}>{data.incidents[2].date} </span>

            </div>


            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '15%', alignItems: 'center' }}>



              <Doughnut
                type="doughnut"
                data={data4}
                options={options4}
                plugins={plugins4}

              />

              <span style={{ color: '#fff', fontSize: 15, marginTop: 20 }}>{data.incidents[3].date} </span>

            </div>


            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '15%', alignItems: 'center' }}>


              <Doughnut
                type="doughnut"
                data={data5}
                options={options5}
                plugins={plugins5}

              />
              <span style={{ color: '#fff', fontSize: 15, marginTop: 20 }}>{data.incidents[4].date} </span>

            </div>


          </div>
        </div>
      </div>


    </div>
  );
};

export default Incidents;