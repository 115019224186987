import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

import NetworkSecurityCardsData from '../../../data/ops-view/SecurityStackNetworkSecurity/NetworkSecurityCards.json';
import { CgWebsite } from "react-icons/cg";
import { MdAppBlocking } from "react-icons/md";
import { GiFirewall } from "react-icons/gi";


const NetworkSecurityCards = (props) => {

  const [onLoading, setOnLoading] = useState(false)

  const data = props.data.charts;

  const [parentWidth, setParentWidth] = useState(0);  


  useEffect(() => {
    const updateParentWidth = () => {
      const parentDiv = document.getElementById('parentDiv');
      if (parentDiv) {
        const width = parentDiv.offsetWidth;
        setParentWidth(width * 0.95); // Assigning 90% width
      }
    };

    updateParentWidth();

    window.addEventListener('resize', updateParentWidth);

    return () => {
      window.removeEventListener('resize', updateParentWidth);
    };
  }, []);


  return (
    <div style={{
      width: '100%', marginLeft: 0, display: 'flex', flexDirection: 'column',
      marginTop: '0%',  padding: 20, borderRadius: 15
    }}>    



      <div style={{ display: 'flex', flexDirection: 'row',
       justifyContent: 'space-around', marginTop: 0 }}>


        <div style={{ width: '100%' }}>          

          <div style={{
            display: 'flex', flexDirection: 'row', 
            justifyContent: 'space-between', alignItems: 'center',
             flexWrap:'wrap',
            alignItems: 'center', height: '100%'
          }}>


            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor:'#fff', 
            borderRadius:5, padding:20, width:'21%', height:150, alignItems:'center'}}>

                    <CgWebsite size={40} style={{color:'#1e88e5'}}/>
                    <span style={{color:'#000', fontSize:25, textAlign:'center', fontWeight:'bold'}}>{data.networkSecurityOverview[0].value}</span>
                    <span style={{color:'#000', fontSize:16, textAlign:'center', fontWeight:'bold'}}>{data.networkSecurityOverview[0].name}</span>
            </div>

            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor:'#fff', 
            borderRadius:5, padding:20, width:'21%', height:150, alignItems:'center'}}>

                    <MdAppBlocking size={40} style={{color:'#388e3c'}}/>

                    <span style={{color:'#000', fontSize:25, textAlign:'center', fontWeight:'bold'}}>{data.networkSecurityOverview[1].value}</span>
                    <span style={{color:'#000', fontSize:16, textAlign:'center',fontWeight:'bold'}}>{data.networkSecurityOverview[1].name}</span>
            </div>


            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', 
            backgroundColor:'#fff', borderRadius:5, padding:20, width:'21%', height:150, alignItems:'center'}}>

                    <GiFirewall size={40} style={{color:'#9c27b0'}}/>

                    <span style={{color:'#000', fontSize:25, textAlign:'center', fontWeight:'bold'}}>{data.networkSecurityOverview[2].value}</span>
                    <span style={{color:'#000', fontSize:16, textAlign:'center',fontWeight:'bold'}}>{data.networkSecurityOverview[2].name}</span>
            </div>


            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
             backgroundColor:'#fff', borderRadius:5, padding:20, width:'21%', height:150, alignItems:'center'}}>

                    <span style={{color:'#000', fontSize:14, textAlign:'center', fontWeight:'bold'}}>NFV Services</span>

                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginTop:10}}>

                         <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between'}}>

                            <span style={{color:'#000', fontSize:12, textAlign:'center', fontWeight:'bold'}}>Endpoint</span>
                            <span style={{color:'#000', fontSize:14, textAlign:'center',fontWeight:'bold'}}>0</span>

                         </div>

                         <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between'}}>

                            <span style={{color:'#000', fontSize:12, textAlign:'center', fontWeight:'bold'}}>Transparent</span>
                            <span style={{color:'#000', fontSize:14, textAlign:'center',fontWeight:'bold'}}>1</span>

                         </div>


                    </div>
                    
            </div>        



          </div>
        </div>
      </div>


    </div>
  );
};

export default NetworkSecurityCards;