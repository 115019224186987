import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

import CloudAccountRiskAssessmentData from '../../../data/ops-view/SecurityStackCloudSecurity/CloudAccountRiskAssessment.json';
import { CiWarning } from "react-icons/ci";


const CloudAccountRiskAssessment = (props) => {

  const [onLoading, setOnLoading] = useState(false)

  const data = props.data.charts;

  const [parentWidth, setParentWidth] = useState(0);  

  const theContainer = {
    backgroundColor: '#ccc',
    height: '10px',
    borderRadius: '0px',
    position: 'relative',
    width: '40%',
    marginTop: 10,
    marginBottom: 10
  };

  const riskProgressStyle1 = {
    backgroundColor: '#bb0406',
    height: '100%',
    borderRadius: '0px',
    width: `${data.cloudAcccountRiskAssessment.failPercent}%`,
  };

  const riskProgressStyle2 = {
    backgroundColor: '#ff8c4e',
    height: '100%',
    borderRadius: '0px',
    width: `${data.cloudAcccountRiskAssessment.warnPercent}%`,
  };

  const riskProgressStyle3 = {
    backgroundColor: '#99a2aa',
    height: '100%',
    borderRadius: '0px',
    width: `${data.cloudAcccountRiskAssessment.unknownPercent}%`,
  };



  useEffect(() => {
    const updateParentWidth = () => {
      const parentDiv = document.getElementById('parentDiv');
      if (parentDiv) {
        const width = parentDiv.offsetWidth;
        setParentWidth(width * 0.95); // Assigning 90% width
      }
    };

    updateParentWidth();

    window.addEventListener('resize', updateParentWidth);

    return () => {
      window.removeEventListener('resize', updateParentWidth);
    };
  }, []);


  return (
    <div style={{
      width: '49%', marginLeft: 0, display: 'flex', flexDirection: 'column', height: 350,
      marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
    }}>
    


      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 10 }}>


      <div style={{ height: 60, }}>

          <h6 style={{ color: '#fff', textAlign: 'left' }}>Cloud Account Risk Assessment</h6>
          <hr />

      </div>


          <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
            alignItems: 'center', height: 250
          }}>


            <div style={{ display: 'flex', flexDirection: 'column', borderRadius: 5, border: '1px solid white', padding: 10, 
          backgroundColor:'#fff', alignItems:'center', justifyContent:'space-between', width:150, height:150 }}>

            <CiWarning size={40} style={{color:'red'}}/>

              <span style={{ fontSize: 25, textAlign: 'center', color:'#000', fontWeight:'bold' }}>
                {data.cloudAcccountRiskAssessment.securityRisks}</span>
              <span style={{ fontSize: 18, textAlign: 'center',color:'#000', }}>Security Risks</span>

            </div>


            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width:'60%' }}>

              <div style={{ width: '100%', display:'flex', flexDirection:'row',justifyContent: 'space-between', }}>
                <span style={{ fontWeight: 'bold', width:'20%',color:'#fff' }}>Fail</span>
                <div style={theContainer}>
                  <div style={riskProgressStyle1}></div>
                </div>
                <span style={{ fontWeight: 'bold', width:'20%',color:'#fff'  }}>{data.cloudAcccountRiskAssessment.failPercent}%</span>
              </div>

              <div style={{ width: '100%', display:'flex', flexDirection:'row',justifyContent: 'space-between', }}>
                <span style={{ fontWeight: 'bold',width:'20%',color:'#fff'  }}>Warn</span>
                <div style={theContainer}>
                  <div style={riskProgressStyle2}></div>
                </div>
                <span style={{ fontWeight: 'bold', width:'20%',color:'#fff'  }}>{data.cloudAcccountRiskAssessment.warnPercent}%</span>
              </div>

              <div style={{ width: '100%', display:'flex', flexDirection:'row',justifyContent: 'space-between',}}>
                <span style={{ fontWeight: 'bold', width:'20%',color:'#fff'  }}>Unknown</span>
                <div style={theContainer}>
                  <div style={riskProgressStyle3}></div>
                </div>
                <span style={{ fontWeight: 'bold', width:'20%',color:'#fff'  }}>{data.cloudAcccountRiskAssessment.unknownPercent}%</span>
              </div>

            </div>



          </div>
        </div>
      </div>


  );
};

export default CloudAccountRiskAssessment;