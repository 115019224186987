import React, { useEffect, useState, useRef } from 'react'
import { AppContent, AppSidebar, AppFooter, AppHeader } from '../components/index'

const DefaultLayout = () => {  

  return (
    <div>
     
      <div className="wrapper d-flex flex-column min-vh-100" >
        <AppHeader/>
        <div className="body flex-grow-1 px-3" style={{display:'flex', flexDirection:'row'}}>
          <AppSidebar/>
          <AppContent/>
        </div>       
      </div>
    </div>
  )
}

export default DefaultLayout
