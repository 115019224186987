import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

//import data from '../../../datanew/Australia/data/opsView.json';

const HostsPerOSByItemType = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const [screenWidth, setScreenWidth] = useState(1000);

    const data = props.data;

  useEffect(() => {
      setScreenWidth (window.innerWidth - ((window.innerWidth * 25)/100))
  }, []);

  const colors = ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A']; // Define different colors for each bar

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: data.charts.hostsPerOSByItemType.categories,
    },
    colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A'],
    title: {
      text: 'Hosts Per OS By Item Type',
      align: 'center',
      style: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#333'
      }
    },
    grid: {
      borderColor: '#f1f1f1'
    },
  };

  const series = [{
    name: 'Item Count',
    data: data.charts.hostsPerOSByItemType.series[0].data,    
  }];
   

    return (
        <div style={{
            width: '49%', marginLeft: 0, display: 'flex',
             flexDirection: 'column', height: 470,
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>



            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 10 }}>


            <div style={{ height: 60, }}>

                    <h6 style={{ color: '#fff', textAlign: 'left' }}>
                    Hosts Per OS By Item Type</h6>
                    <hr />

            </div>


                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height:330 }} >
                         
                    <ReactApexChart options={options}
                     series={series} type="bar" 
                     height={350} width={screenWidth * 0.5}/>


                    </div>
                </div>
            </div>


        
    );
};

export default HostsPerOSByItemType;