import React, { useState, useEffect, useRef } from "react";

import axios from 'axios';

import {
  CCol,
  CRow,
  CContainer,
} from '@coreui/react'

import { Link } from "react-router-dom";

import './style.css';

import { HiOutlineDesktopComputer } from "react-icons/hi";
import { TbApi } from "react-icons/tb";
import { AiOutlineIssuesClose } from "react-icons/ai";
import { FiAlertCircle } from "react-icons/fi";
import { MdOutlinePersonalInjury } from "react-icons/md";
import { AiOutlineCloudServer } from "react-icons/ai";

import { BsCollection } from "react-icons/bs";
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Switch from '@mui/material/Switch';

import { Shimmer, Breathing } from 'react-shimmer'
import { FaUsers } from "react-icons/fa";
import { MdApps } from "react-icons/md";
import { FaObjectGroup } from "react-icons/fa";
import { AiOutlineSecurityScan } from "react-icons/ai";
import { MdErrorOutline } from "react-icons/md";
import { SiHelpdesk } from "react-icons/si";
import { IoIosBusiness } from "react-icons/io";
import GaugeChart from 'react-gauge-chart'

import riskLevelChartData from './data/strategic-view/riskLevelChart.json';
import projectStatusTrendChart from './data/strategic-view/projectStatusTrendChart.json';

import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import SpiderWebSmall from './components/csat/SpiderWebSmall';

import Chart from 'react-apexcharts';

import data from './datanew/cisControls.json';

const CISDetails = () => {

  const random = () => Math.round(Math.random() * 100)

  const [loadingStats, setLoadingStats] = useState(false)
  const [index, setIndex] = useState(1)
  const [cisControl, setCisControl] = useState(null)

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const paramName = queryParams.get('index');

    console.log('paramName:',paramName-1)
    setIndex(paramName);    
    
    setCisControl(data.cisControls[paramName - 1])
    

  }, []);

  const [expandedItem, setExpandedItem] = useState(null);

  const toggleExpand = (id) => {
    if (expandedItem === id) {
      setExpandedItem(null);
    } else {
      setExpandedItem(id);
    }
  };


  console.log("cisControl:",cisControl)


  const ref = useRef(null);  

  return (
    <>

      <CContainer style={{ marginBottom: 50 }}>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}>

          <h4>{cisControl && cisControl.name} Dashboard</h4>

          <div style={{ display: 'none', flexDirection: 'row', justifyContent: 'space-between' }}>

            <select value="select" style={{ marginLeft: 10, marginRight: 10, fontSize:13, padding:5 }}>
              <option value="select" disabled>Select Region</option>
            </select>

            <select value="select" style={{ marginLeft: 10, marginRight: 10, fontSize:13, padding:5 }}>
              <option value="select" disabled>Select City</option>
            </select>

            <select value="select" style={{ marginLeft: 10, marginRight: 10, fontSize:13, padding:5}}>
              <option value="select" disabled>Select Office</option>
            </select>
          </div>

        </div>       

       


        <div style={{ display: 'flex', flexDirection: 'row', 
          justifyContent: 'space-between', 
          width: '100%', flexWrap: 'wrap', marginTop: 20 }}>


           <span className="whiteSpan" style={{fontSize:19, fontWeight:'normal'}}>{cisControl && cisControl.subtitle}</span>
           <span className="whiteSpan" style={{fontSize:14, fontWeight:'normal'}}>{cisControl && cisControl.description}</span>

 
       

        </div>

        <div style={{
                    display: 'flex', flexDirection: 'row',
                    justifyContent: 'space-between', width: '100%',
                    flexWrap: 'wrap', marginTop: 20,
                }}>

                    <div style={{
                        display: 'flex', flexDirection: 'column',
                        justifyContent: 'space-between',
                        backgroundColor: '#fff', height: 120,
                         padding: 20, borderRadius: 10, width: '19%'
                    }}>

                        <span style={{ fontSize: 16, color: '#000', textAlign: 'center', fontWeight: 'bold' }}>Control Average Score</span>
                        <span style={{ fontSize: 30, color: '#000', textAlign: 'center' }}>{cisControl && cisControl.controlAverageScore}</span>

                    </div>

                    <div style={{
                        display: 'flex', flexDirection: 'column',
                        justifyContent: 'space-between',
                        backgroundColor: '#fff', height: 120,
                         padding: 20, borderRadius: 10, width: '19%'
                    }}>

                        <span style={{ fontSize: 16, color: '#000', textAlign: 'center', fontWeight: 'bold' }}>Control Industry Average Score</span>
                        <span style={{ fontSize: 30, color: '#000', textAlign: 'center' }}>{cisControl && cisControl.controlIndustryAverageScore}</span>

                    </div>


                    <div style={{
                        display: 'flex', flexDirection: 'column',
                        justifyContent: 'space-between',
                        backgroundColor: '#fff', height: 120,
                         padding: 20, borderRadius: 10, width: '19%'
                    }}>

                        <span style={{ fontSize: 16, color: '#000', textAlign: 'center', fontWeight: 'bold' }}>Percentaege Completed</span>
                        <span style={{ fontSize: 30, color: '#000', textAlign: 'center' }}>{cisControl && cisControl.percentageCompleted}</span>

                    </div>


                    <div style={{
                        display: 'flex', flexDirection: 'column',
                        justifyContent: 'space-between',
                        backgroundColor: '#fff', height: 120,
                         padding: 20, borderRadius: 10, width: '19%'
                    }}>

                        <span style={{ fontSize: 16, color: '#000', textAlign: 'center', fontWeight: 'bold' }}>Percentage Validated</span>
                        <span style={{ fontSize: 30, color: '#000', textAlign: 'center' }}>{cisControl && cisControl.percentageValidated}</span>

                    </div>

                    <div style={{
                        display: 'flex', flexDirection: 'column',
                        justifyContent: 'space-between',
                        backgroundColor: '#fff', height: 120,
                         padding: 20, borderRadius: 10, width: '19%'
                    }}>

                        <span style={{ fontSize: 16, color: '#000', textAlign: 'center', fontWeight: 'bold' }}>Overall Score</span>
                        <span style={{ fontSize: 30, color: '#000', textAlign: 'center' }}>{cisControl && cisControl.overallScore}</span>

                    </div>

         </div>     



          <div style={{
                    display: 'flex', flexDirection: 'row',
                    justifyContent: 'space-between', width: '100%',
                    flexWrap: 'wrap', marginTop: 20,
                }}>      


        <div style={{
            width: '72%', marginLeft: 0, display: 'flex', 
            flexDirection: 'column', 
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>

  <div>
  <div>
      {cisControl && cisControl.policies.map(objective => (
        <div key={objective.id} style={{ marginBottom: '20px', padding:10,
         border:'1px solid white', borderRadius:5 }}>
          <div onClick={() => toggleExpand(objective.id)}
           style={{ cursor: 'pointer', display:'flex', 
           flexDirection:'row', justifyContent:'space-between', alignItems:'center' }}>
            <span className="whiteSpan" style={{fontSize:14}}>{objective.id} - {objective.name} - {objective.group}</span>
           <div>
            <Switch 
            defaultChecked={true} 
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <span className="whiteSpan" style={{fontSize:14}}>Applicable</span>
          </div>
          </div>
          {expandedItem === objective.id && (
            <div>
              <p style={{fontSize:15, marginTop:30}}>{objective.description}</p>

              <div style={{display:'flex', flexDirection:'row', 
                   justifyContent:'flex-start'}}>

                {objective && objective.tags.map(tag => (
                    <span key={tag} style={{ fontSize:9, backgroundColor: '#2185d0', color: '#fff', marginRight:5,
                    padding:5, fontWeight:'bold', borderRadius:3 }}  title={tag.hoverText}>
                    {tag}
                  </span>
                  ))}

              </div>

              <div style={{display:'flex', flexDirection:'row', 
                  justifyContent:'space-between', marginTop:50}}>

                  <div style={{display:'flex', flexDirection:'column', 
                      justifyContent:'space-between'}}>

                        <div style={{display:'flex', flexDirection:'row', 
                              justifyContent:'space-between',marginTop:20, alignItems:'center'}}>

                                <span className="whiteSpan">Policy Defined</span>

                                <select style={{marginLeft:20, height:40, width:300}}>
                                   <option>No Policy</option>
                                   <option>Informal Policy</option>
                                   <option>Partially Written Policy</option>
                                   <option>Written Policy</option>
                                   <option>Approved Written Policy</option>
                                   <option>Not Applicable</option>
                                </select>

                        </div>       

                         <div style={{display:'flex', flexDirection:'row', 
                              justifyContent:'space-between',marginTop:20,  alignItems:'center'}}>

                                <span className="whiteSpan">Control Implemented</span>

                                <select style={{marginLeft:20, height:40, width:300}}>
                                   <option>Not Implemented</option>
                                   <option>Parts of Policy Implemented</option>
                                   <option>Implemented on Some Systems</option>
                                   <option>Implemented on Most Systems</option>
                                   <option>Implemented on All Systems</option>
                                   <option>Not Applicable</option>
                                </select>

                        </div>   

                         <div style={{display:'flex', flexDirection:'row', 
                              justifyContent:'space-between', marginTop:20, alignItems:'center'}}>

                                <span className="whiteSpan">Control Automated</span>

                                <select style={{marginLeft:20, height:40, width:300}}>
                                   <option>No Policy</option>
                                   <option>Informal Policy</option>
                                   <option>Partially Written Policy</option>
                                   <option>Written Policy</option>
                                   <option>Approved Written Policy</option>
                                   <option>Not Applicable</option>
                                </select>

                        </div>      


                         <div style={{display:'flex', flexDirection:'row', 
                              justifyContent:'space-between', marginTop:20, alignItems:'center'}}>

                                <span className="whiteSpan">Control Reported</span>

                                <select style={{marginLeft:20, height:40,  width:300}}>
                                   <option>No Policy</option>
                                   <option>Informal Policy</option>
                                   <option>Partially Written Policy</option>
                                   <option>Written Policy</option>
                                   <option>Approved Written Policy</option>
                                   <option>Not Applicable</option>
                                </select>

                        </div>      

                        <div style={{display:'flex', flexDirection:'row', 
                              justifyContent:'space-between', marginTop:50, alignItems:'center'}}>

                                <span className="whiteSpan">Asset Type</span>
                                <span className="whiteSpan">Devices</span>                               

                        </div>   

                        <hr style={{border:'1px solid #f5f5f5'}}/>       

                        <div style={{display:'flex', flexDirection:'row', 
                              justifyContent:'space-between', marginTop:10, alignItems:'center'}}>

                                <span className="whiteSpan">Security Function</span>
                                <span className="whiteSpan">Identify</span>                               

                        </div>  

                        <hr style={{border:'1px solid #f5f5f5'}}/>        

                        <div style={{display:'flex', flexDirection:'row', 
                              justifyContent:'space-between', marginTop:10, alignItems:'center'}}>

                                <span className="whiteSpan">Assigned By</span>
                                <span className="whiteSpan">John</span>                               

                        </div>   

                        <hr style={{border:'1px solid #f5f5f5'}}/>        

                        <div style={{display:'flex', flexDirection:'row', 
                              justifyContent:'space-between', marginTop:10, alignItems:'center'}}>

                                <span className="whiteSpan">Completed By</span>
                                <span className="whiteSpan">David</span>                               

                        </div>     

                        <hr style={{border:'1px solid #f5f5f5'}}/>       

                        <div style={{display:'flex', flexDirection:'row', 
                              justifyContent:'space-between', marginTop:10, alignItems:'center'}}>

                                <span className="whiteSpan">Validated By</span>
                                <span className="whiteSpan">Jeff</span>                               

                        </div>     

                        <hr style={{border:'1px solid #f5f5f5'}}/>          

                        <div style={{display:'flex', flexDirection:'row', 
                              justifyContent:'space-between', marginTop:10, alignItems:'center'}}>

                                <span className="whiteSpan">Assigned To</span>
                                <span className="whiteSpan">Ravi</span>                               

                        </div>  

                        <button style={{marginTop:30, height:40}}>Complete Sub-Control</button>  

                  </div>      



              </div>  

            </div>
          )}
        </div>
      ))}
    </div>
    </div>



          </div>


                <SpiderWebSmall/> 

          </div>


          

      </CContainer>
    </>
  )
}

export default CISDetails