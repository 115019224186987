import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";



const CloudAccounts = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const data = props.data.charts.cloudAccounts;

    const [parentWidth, setParentWidth] = useState(0);

    useEffect(() => {
      const updateParentWidth = () => {
        const parentDiv = document.getElementById('parentDiv');
        if (parentDiv) {
          const width = parentDiv.offsetWidth;
          setParentWidth(width * 0.95); // Assigning 90% width
        }
      };
  
      updateParentWidth();
  
      window.addEventListener('resize', updateParentWidth);
  
      return () => {
        window.removeEventListener('resize', updateParentWidth);
      };
    }, []);

    const customColors = ['#00e396', '#FFBF00'];


    const chartData = data.map(account => ({
      name: account.name,
      data: account.count,
    }));
  
    // Configuration for the pie chart
    const options = {
      chart: {
        type: 'donut',
      },
      labels: chartData.map(item => item.name),
      legend: {
        position: 'bottom', // Place legends at the bottom
      },
      colors: customColors,
    };

    return (
        <div style={{
            width: '29%', marginLeft: 0, display: 'flex', flexDirection: 'column', 
            height: 400,
            marginTop: '2%', 
            background: 'linear-gradient(135deg, #161c2a, #1a2130)', 
            padding: 20, borderRadius: 15
        }}>
            

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 10 }}>


                
            <div style={{ height: 60, }}>

                    <h6 style={{ color: '#fff', textAlign: 'left' }}>Cloud Accounts</h6>
                    <hr />

            </div>


                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>

                    <ReactApexChart options={options}
                     series={chartData.map(item => item.data)}
                      type="donut" height={300} />



                    </div>
                </div>
            </div>


        
    );
};

export default CloudAccounts;