import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';
import '../../style.css';


const ComplianceStatus = (props) => {

  const [onLoading, setOnLoading] = useState(false)

  const data = props.data;

  const [selectedCompliance, setSelectedCompliance] = useState(data.charts.complianceStatus[0].name)


  const handleChange = (event) => {
    setSelectedCompliance(event.target.value);
  };


  let g1Percentage = data.charts.complianceStatus[0].values[0].completedPercent;
  let g2Percentage = data.charts.complianceStatus[0].values[1].completedPercent;
  let g3Percentage = data.charts.complianceStatus[0].values[2].completedPercent;

  if (selectedCompliance == 'CIS v8') {
    g1Percentage = data.charts.complianceStatus[0].values[0].completedPercent;
    g2Percentage = data.charts.complianceStatus[0].values[1].completedPercent;
    g3Percentage = data.charts.complianceStatus[0].values[2].completedPercent;
  } else if (selectedCompliance == 'CIS v9') {
    g1Percentage = data.charts.complianceStatus[1].values[0].completedPercent;
    g2Percentage = data.charts.complianceStatus[1].values[1].completedPercent;
    g3Percentage = data.charts.complianceStatus[1].values[2].completedPercent;
  }

  let containerStyle = {
    backgroundColor: '#6eb5f9',
    height: '10px',
    borderRadius: '5px',
    position: 'relative',
    width: '100%',
    marginTop: 10,
    marginBottom: 10
  };

  let progressStyle1 = {
    backgroundColor: '#2a63a8',
    height: '100%',
    borderRadius: '5px',
    width: `${g1Percentage}%`,
  };

  let progressStyle2 = {
    backgroundColor: '#2a63a8',
    height: '100%',
    borderRadius: '5px',
    width: `${g2Percentage}%`,
  };

  let progressStyle3 = {
    backgroundColor: '#2a63a8',
    height: '100%',
    borderRadius: '5px',
    width: `${g3Percentage}%`,
  };



  return (
    <div style={{
      width: '49%', marginLeft: 0, display: 'flex',
      flexDirection: 'column', height: 520,
      marginTop: '2%',
      background: 'linear-gradient(135deg, #161c2a, #1a2130)',
      padding: 20, borderRadius: 15
    }}>

      {!onLoading ?


        <div style={{
          display: 'flex', flexDirection: 'column',
          justifyContent: 'flex-start', marginTop: 10
        }}>



          <div style={{ height: 100 }}>

            <h4 style={{ color: '#fff', textAlign: 'left' }}>Compliance Status</h4>

            <div style={{
              display: 'flex', flexDirection: 'row',
              justifyContent: 'space-between'
            }}>
              <h6 style={{ color: '#fff', textAlign: 'left' }}>Compliance</h6>

              <select style={{ fontSize: 13, padding: 5 }} value={selectedCompliance} onChange={handleChange}>

                {data && data.charts.complianceStatus.map((item, index) => (
                  <option value={item.name}>{item.name}</option>
                ))}


              </select>
            </div>
            <hr />

          </div>

          <div style={{
            height: 420, display: 'flex',
            flexDirection: 'row', alignItems: 'center'
          }}>

            <div>

              <div>

                <span className="whiteSpan" style={{ fontWeight: 'bold' }}>G1</span>
                <div style={containerStyle}>
                  <div style={progressStyle1}></div>
                </div>
                <span className="whiteSpan" style={{ fontWeight: 'normal', fontSize:13 }}>{g1Percentage} % Pending</span>

                <br /> <br />

                <span className="whiteSpan" style={{ fontWeight: 'bold' }}>G2</span>
                <div style={containerStyle}>
                  <div style={progressStyle2}></div>
                </div>
                <span className="whiteSpan" style={{ fontWeight: 'normal', fontSize:13 }}>{g2Percentage} % Pending</span>

                <br /> <br />

                <span className="whiteSpan" style={{ fontWeight: 'bold' }}>G3</span>
                <div style={containerStyle}>
                  <div style={progressStyle3}></div>
                </div>
                <span className="whiteSpan" style={{ fontWeight: 'normal' , fontSize:13}}>{g3Percentage} % Pending</span>

              </div>

              <div>

                <div className="legend">

                  <div className="legend-item">
                    <div className="legend-box cynomi-coverage"></div>
                    <span className="whiteSpan" style={{ fontSize: 13 }}>Company Coverage</span>
                  </div>

                  <div className="legend-item">
                    <div className="legend-box company-coverage"></div>
                    <span className="whiteSpan" style={{ fontSize: 13 }}>Not Completed</span>
                  </div>
                 
                  <div className="legend-item">
                    <div className="legend-box total-controls"></div>
                    <span className="whiteSpan" style={{ fontSize: 13 }}>Total Controls</span>
                  </div>
                </div>

              </div>

            </div>

          </div>


        </div>


        :
        <>
          <h4 style={{ color: '#000', textAlign: 'center' }}>Compliance Status</h4>
          <hr />
          <span style={{ textAlign: 'center', marginTop: '30%' }}>No data found</span>
        </>
      }


    </div>




  );
};

export default ComplianceStatus;
