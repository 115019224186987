import React, { useState, useEffect, useRef } from "react";

import axios from 'axios';

import {
  CCol,
  CRow,
  CContainer,
} from '@coreui/react'

import { Link } from "react-router-dom";

import './style.css';



import SecurityStackOverview from "./components/ops/SecurityStackOverview";
import CloudAccounts from "./components/ops/cloud-security/CloudAccounts";
import CloudProvidersOverview from "./components/ops/cloud-security/CloudProvidersOverview";
import ResourcesCards from "./components/ops/cloud-security/ResourcesCards";
import Findings from "./components/ops/cloud-security/Findings";
import Trends from "./components/ops/cloud-security/Trends";
import CloudAccountRiskAssessment from "./components/ops/cloud-security/CloudAccountRiskAssessment";
import CloudAccountScoreAssessment from "./components/ops/cloud-security/CloudAccountScoreAssessment";
import AWSFailuresByControlCriticality from "./components/ops/cloud-security/AWSFailuresByControlCriticality";
import AzureFailuresByControlCriticality from "./components/ops/cloud-security/AzureFailuresByControlCriticality";
import GCPFailuresByControlCriticality from "./components/ops/cloud-security/GCPFailuresByControlCriticality";
import AWSTop5FailedControls from "./components/ops/cloud-security/AWSTop5FailedControls";
import AzureTop5FailedControls from "./components/ops/cloud-security/AzureTop5FailedControls";
import GCPTop5FailedControls from "./components/ops/cloud-security/GCPTop5FailedControls";
import AWSCISCoverage from "./components/ops/cloud-security/AWSCISCoverage";
import AzureCISCompliance from "./components/ops/cloud-security/AzureCISCompliance";
import GCPCISCompliance from "./components/ops/cloud-security/GCPCISCompliance";

import NetworkSecurityCards from "./components/ops/network-security/NetworkSecurityCards";
import Incidents from "./components/ops/network-security/Incidents";
import TopSites from "./components/ops/network-security/TopSites";
import SourceIPsDenied from "./components/ops/network-security/SourceIPsDenied";
import DestinationsBlocked from "./components/ops/network-security/DestinationsBlocked";
import TopRules from "./components/ops/network-security/TopRules";
import SitesByEvent from "./components/ops/network-security/SitesByEvent";
import TotalEvents from "./components/ops/network-security/TotalEvents";


import EmailSecurityCards from "./components/ops/email-security/EmailSecurityCards";
import ThreatsClassification from "./components/ops/email-security/ThreatsClassification";
import ThreatStatusDistribution from "./components/ops/email-security/ThreatStatusDistribution";
import ThreatsTypesDistribution from "./components/ops/email-security/ThreatsTypesDistribution";
import Top10ClicksPermittedURL from "./components/ops/email-security/Top10ClicksPermittedURL";
import Top10ClicksBlockedURL from "./components/ops/email-security/Top10ClicksBlockedURL";
import TopSendersForBlockedMessages from "./components/ops/email-security/TopSendersForBlockedMessages";
import TopRecipientsForBlockedMessages from "./components/ops/email-security/TopRecipientsForBlockedMessages";
import MessagesBlockedScoresTimeline from "./components/ops/email-security/MessagesBlockedScoresTimeline";


import ServerSecurityCards from "./components/ops/server-security/ServerSecurityCards";
import SystemsByType from "./components/ops/server-security/SystemsByType";
import SystemsHealth from "./components/ops/server-security/SystemsHealth";
import RecentActivity from "./components/ops/server-security/RecentActivity";
import PasswordCheckouts from "./components/ops/server-security/PasswordCheckouts";
import ContinuousWebServerMonitoring from "./components/ops/server-security/ContinuousWebServerMonitoring";
import SystemLogins from "./components/ops/server-security/SystemLogins";
import ActiveSessions from "./components/ops/server-security/ActiveSessions";
import TopSystemCheckouts from "./components/ops/server-security/TopSystemCheckouts";

import EndpointSecurityCards from "./components/ops/endpoint-security/EndpointSecurityCards";
import DeviceUniverse from "./components/ops/endpoint-security/DeviceUniverse";
import CyberHygieneScore from "./components/ops/endpoint-security/CyberHygieneScore";
import Detection from "./components/ops/endpoint-security/Detection";
import PrioritizedRisks from "./components/ops/endpoint-security/PrioritizedRisks";
import FactorsInfluencingRiskPrioritization from "./components/ops/endpoint-security/FactorsInfluencingRiskPrioritization";
import Queries from "./components/ops/endpoint-security/Queries";
import ThreatIndicators from "./components/ops/endpoint-security/ThreatIndicators";
import Vulnerabilities from "./components/ops/endpoint-security/Vulnerabilities";
import SoftwareAssets from "./components/ops/endpoint-security/SoftwareAssets";
import Compliance from "./components/ops/endpoint-security/Compliance";
import MissingPatches from "./components/ops/endpoint-security/MissingPatches";
import Benchmarks from "./components/ops/endpoint-security/Benchmarks";

import AppSecurityCards from "./components/ops/app-security/AppSecurityCards";
import FindingsOverview from "./components/ops/app-security/FindingsOverview";
import UniqueFindingDistribution from "./components/ops/app-security/UniqueFindingDistribution";
import CWETop25MostDangerous from "./components/ops/app-security/CWETop25MostDangerous";
import OWASPDistribution from "./components/ops/app-security/OWASPDistribution";

import CategoryBreakdown from "./components/ops/assets-inventory/CategoryBreakdown";
import OperatingSystemDistribution from "./components/ops/assets-inventory/OperatingSystemDistribution";
import TopHardwareManufacturer from "./components/ops/assets-inventory/TopHardwareManufacturer";
import TopClientOperatingSystem from "./components/ops/assets-inventory/TopClientOperatingSystem";
import TopClientApplicationCategories from "./components/ops/assets-inventory/TopClientApplicationCategories";
import ItemsByStatus from "./components/ops/assets-inventory/ItemsByStatus";
import PhysicalVsVirtualItems from "./components/ops/assets-inventory/PhysicalVsVirtualItems";
import HostsPerOSByItemType from "./components/ops/assets-inventory/HostsPerOSByItemType";
import ItemsByClass from "./components/ops/assets-inventory/ItemsByClass";

import IncidentCards from "./components/ops/tickets-issues/IncidentCards";
import AverageTimeToResolution from "./components/ops/tickets-issues/AverageTimeToResolution";
import IncidentsStatus from "./components/ops/tickets-issues/IncidentsStatus";
import ResolvedUnresolvedIncidents from "./components/ops/tickets-issues/ResolvedUnresolvedIncidents";
import NewIncidents from "./components/ops/tickets-issues/NewIncidents";
import NewIncidentsByCategory from "./components/ops/tickets-issues/NewIncidentsByCategory";
import CreatedClosedIncidents from "./components/ops/tickets-issues/CreatedClosedIncidents";
import IncidentCards2 from "./components/ops/tickets-issues/IncidentCards2";
import NewIncidentForecast from "./components/ops/tickets-issues/NewIncidentForecast";
import UnresolvedVsResolvedIncidents from "./components/ops/tickets-issues/UnresolvedVsResolvedIncidents";
import IncidentsByCategory from "./components/ops/tickets-issues/IncidentsByCategory";


import australiaData from './datanew/Australia/data/opsView.json';
import sydneyData from './datanew/Australia/Sydney/data/opsView.json';
import sydneyCBDData from './datanew/Australia/Sydney/CBD/data/opsView.json';

import europeData from './datanew/Europe/data/opsView.json';
import berlinData from './datanew/Europe/Berlin/data/opsView.json';
import centralBerlinData from './datanew/Europe/Berlin/Central Berlin/data/opsView.json';

import indiaData from './datanew/India/data/opsView.json';
import bangaloreData from './datanew/India/Bangalore/data/opsView.json';
import indiranagarData from './datanew/India/Bangalore/Indiranagar/data/opsView.json';

import singaporeData from './datanew/Singapore/data/opsView.json';
import singaporeCityData from './datanew/Singapore/Singapore City/data/opsView.json';
import marinaBayData from './datanew/Singapore/Singapore City/Marina Bay/data/opsView.json';

import usaData from './datanew/USA/data/opsView.json';
import losAngelesData from './datanew/USA/Los Angeles/data/opsView.json';
import longBeachData from './datanew/USA/Los Angeles/Long Beach/data/opsView.json';


const OpsView = () => {

  const random = () => Math.round(Math.random() * 100)  

  const [selectedRegion, setSelectedRegion] = useState('Australia');
  const [selectedCity, setSelectedCity] = useState('select');
  const [selectedOffice, setSelectedOffice] = useState('select');

  const handleRegionChange = (event) => {
    setSelectedRegion(event.target.value);
    setSelectedCity('select');
    setSelectedOffice('select');
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
    setSelectedOffice('select');
  };

  const handleOfficeChange = (event) => {
    setSelectedOffice(event.target.value);
  };

  const numRows = 5;
  const numCols = 5;

  // Define the width and height of each hexagon
  const hexagonSize = 60; // Adjust as needed

  // Generate an array of coordinates for the hexagons
  const hexagonCoordinates = [];
  for (let row = 0; row < numRows; row++) {
    for (let col = 0; col < numCols; col++) {
      const x = col * hexagonSize * 1.5 + (row % 2) * hexagonSize * 0.75;
      const y = row * hexagonSize * Math.sqrt(3) / 2;
      hexagonCoordinates.push({ x, y });
    }
  }


  const regions = [
    {
      "name":"Australia",
      "cities":[
        {
          "name":"Sydney",
          "offices":[
            "CBD",            
          ]          
        },      

      ]
    },
    {
      "name":"Europe",
      "cities":[
        
        {
          "name":"Berlin",
          "offices":[
            "Central Berlin",                      
          ]          
        },        
      ]
    },
    {
      "name":"India",
      "cities":[
        
        {
          "name":"Bangalore",
          "offices":[            
            "Indiranagar",                   
          ]          
        },        
      ]
    },
    {
      "name":"Singapore",
      "cities":[
        {
          "name":"Singapore City",
          "offices":[            
            "Marina Bay",                                
          ]          
        },        
      ]
    },
    {
      "name":"USA",
      "cities":[        
        {
          "name":"Los Angeles",
          "offices":[            
            "Long Beach",
          ]          
        },      
        
      ]
    }
  ]

  let data = australiaData;


  if(selectedRegion == 'Australia' && selectedCity == 'select' && selectedOffice == 'select'){
    data = australiaData;

    console.log('1')
  }

  if(selectedRegion == 'Australia' && selectedCity == 'Sydney' && selectedOffice == 'select'){
    data = sydneyData;
    console.log('2')
  }

  if(selectedRegion == 'Australia' && selectedCity == 'Sydney' && selectedOffice == 'CBD'){
    data = sydneyCBDData;
    console.log('3')
  }


  if(selectedRegion == 'Europe' && selectedCity == 'select' && selectedOffice == 'select'){
    data = europeData;
    console.log('4')
  }

  if(selectedRegion == 'Europe' && selectedCity == 'Berlin' && selectedOffice == 'select'){
    data = berlinData;
    console.log('5')
  }

  if(selectedRegion == 'Europe' && selectedCity == 'Berlin' && selectedOffice == 'Central Berlin'){
    data = centralBerlinData;
    console.log('6')
  }


  if(selectedRegion == 'India' && selectedCity == 'select' && selectedOffice == 'select'){
    data = indiaData;
    console.log('7')
  }

  if(selectedRegion == 'India' && selectedCity == 'Bangalore' && selectedOffice == 'select'){
    data = bangaloreData;
    console.log('8')
  }

  if(selectedRegion == 'India' && selectedCity == 'Bangalore' && selectedOffice == 'Indiranagar'){
    data = indiranagarData;
    console.log('9')
  }

  if(selectedRegion == 'Singapore' && selectedCity == 'select' && selectedOffice == 'select'){
    data = singaporeData;
    console.log('10')
  }

  if(selectedRegion == 'Singapore' && selectedCity == 'Singapore City' && selectedOffice == 'select'){
    data = singaporeCityData;
    console.log('11')
  }

  if(selectedRegion == 'Singapore' && selectedCity == 'Singapore City' && selectedOffice == 'Marina Bay'){
    data = marinaBayData;
    console.log('12')
  }

  if(selectedRegion == 'USA' && selectedCity == 'select' && selectedOffice == 'select'){
    data = usaData;
    console.log('13')
  }

  if(selectedRegion == 'USA' && selectedCity == 'Los Angeles' && selectedOffice == 'select'){
    data = losAngelesData;
    console.log('14')
  }

  if(selectedRegion == 'USA' && selectedCity == 'Los Angeles' && selectedOffice == 'Long Beach'){
    data = longBeachData;
    console.log('15')
  }

  console.log('none:', data)

  return (
    <>

      <CContainer style={{ marginBottom: 50 }}>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}>

          <h4>Ops View</h4>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

            <select
              value={selectedRegion}
              onChange={handleRegionChange}
              style={{ marginLeft: 10, marginRight: 10, fontSize: 13, padding: 5 }}
            >
              <option value="select" disabled>Select Region</option>
              {regions.map(region => (
                <option key={region.name} value={region.name}>{region.name}</option>
              ))}
            </select>

            <select
              value={selectedCity}
              onChange={handleCityChange}
              style={{ marginLeft: 10, marginRight: 10, fontSize: 13, padding: 5 }}
            >
              <option value="select" disabled>Select City</option>
              {selectedRegion !== 'select' && regions.find(region => region.name === selectedRegion).cities.map(city => (
                <option key={city.name} value={city.name}>{city.name}</option>
              ))}
            </select>

            <select
              value={selectedOffice}
              onChange={handleOfficeChange}
              style={{ marginLeft: 10, marginRight: 10, fontSize: 13, padding: 5 }}
            >
              <option value="select" disabled>Select Office</option>
              {selectedCity !== 'select' && selectedRegion !== 'select' &&
                regions.find(region => region.name === selectedRegion)
                  .cities.find(city => city.name === selectedCity).offices.map((office, index) => (
                    <option key={index} value={office}>{office}</option>
                  ))}
            </select>
          </div>

        </div>



        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', flexWrap: 'wrap', marginTop: 20 }}>


          <SecurityStackOverview
            data={data} />


        </div>


        <div style={{
          width: '100%', backgroundColor: '#89181b', paddingTop: 20, paddingBottom: 20, paddingLeft: 20, paddingRight: 20, marginTop: 40,
          marginBottom: 20, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'
        }}>

          <span style={{ color: 'white', fontSize: 16, fontWeight: 'bold' }}>
            Security Stack - Cloud Security
          </span>


        </div>


        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', flexWrap: 'wrap', marginTop: 20 }}>


          <CloudAccounts
            data={data} />

          <CloudProvidersOverview
            data={data} />

          <ResourcesCards
           data={data}
          />
          <Findings
            data={data} />

          <Trends
            data={data}/>

          <CloudAccountRiskAssessment
            data={data} />

          <CloudAccountScoreAssessment
            data={data} />

          <span style={{ width: '100%', fontSize: 20, color: '#fff', marginTop: 30 }}>Cloud Infrastructure Security Posture</span>

          <AWSFailuresByControlCriticality
            data={data} />

          <AzureFailuresByControlCriticality
            data={data} />

          <GCPFailuresByControlCriticality
            data={data} />

          <AWSTop5FailedControls
            data={data} />

          <AzureTop5FailedControls
            data={data} />

          <GCPTop5FailedControls
            data={data} />

          <AWSCISCoverage
            data={data} />

          <AzureCISCompliance
            data={data} />

          <GCPCISCompliance
            data={data}/>

          <div style={{
            width: '100%', backgroundColor: '#89181b', paddingTop: 20, paddingBottom: 20, paddingLeft: 20, paddingRight: 20, marginTop: 40,
            marginBottom: 20, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'
          }}>

            <span style={{ color: 'white', fontSize: 16, fontWeight: 'bold' }}>
              Security Stack - Network Security
            </span>


          </div>

          <NetworkSecurityCards
            data={data} />

          <Incidents
            data={data} />

          <TopSites
            data={data} />

          <SourceIPsDenied
            data={data} />

          <DestinationsBlocked
            data={data} />

          <TopRules
            data={data} />

          <TotalEvents
            data={data} />

          <SitesByEvent
            data={data} />

          <div style={{
            width: '100%', backgroundColor: '#89181b', paddingTop: 20, paddingBottom: 20, paddingLeft: 20, paddingRight: 20, marginTop: 40,
            marginBottom: 20, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'
          }}>

            <span style={{ color: 'white', fontSize: 16, fontWeight: 'bold' }}>
              Security Stack - Email Security
            </span>


          </div>


          <EmailSecurityCards
            data={data} />

          <ThreatsClassification
            data={data} />

          <ThreatStatusDistribution
            data={data} />

          <ThreatsTypesDistribution
            data={data} />

          <Top10ClicksPermittedURL
            data={data} />

          <Top10ClicksBlockedURL
            data={data}/>

          <TopSendersForBlockedMessages
            data={data} />

          <TopRecipientsForBlockedMessages
            data={data} />

          <MessagesBlockedScoresTimeline
            data={data} />


          <div style={{
            width: '100%', backgroundColor: '#89181b', paddingTop: 20, paddingBottom: 20, paddingLeft: 20, paddingRight: 20, marginTop: 40,
            marginBottom: 20, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'
          }}>

            <span style={{ color: 'white', fontSize: 16, fontWeight: 'bold' }}>
              Security Stack - Server Security
            </span>


          </div>

          <ServerSecurityCards
            data={data} />

          <SystemsByType
            data={data} />

          <SystemsHealth
            data={data} />

          <RecentActivity
            data={data} />

          <PasswordCheckouts
            data={data} />

          <ContinuousWebServerMonitoring
            data={data}
          />

          <SystemLogins
            data={data} />

          <ActiveSessions
            data={data}/>

          <TopSystemCheckouts
            data={data} />


          <div style={{
            width: '100%', backgroundColor: '#89181b', paddingTop: 20, paddingBottom: 20, paddingLeft: 20, paddingRight: 20, marginTop: 40,
            marginBottom: 20, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'
          }}>

            <span style={{ color: 'white', fontSize: 16, fontWeight: 'bold' }}>
              Security Stack - Endpoint Security
            </span>
          </div>


          <EndpointSecurityCards
            data={data} />

          <DeviceUniverse
            data={data} />

          <CyberHygieneScore
            data={data} />

          <Detection
            data={data}/>

          <PrioritizedRisks
            data={data}
          />

          <FactorsInfluencingRiskPrioritization
            data={data} />

          <Queries
            data={data} />

          <ThreatIndicators
            data={data} />

          <Vulnerabilities
            data={data} />

          <SoftwareAssets
            data={data} />

          <Compliance
            data={data} />

          <MissingPatches
            data={data} />

          <Benchmarks
            data={data}/>

          <div style={{
            width: '100%', backgroundColor: '#89181b', paddingTop: 20, paddingBottom: 20, paddingLeft: 20, paddingRight: 20, marginTop: 40,
            marginBottom: 20, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'
          }}>

            <span style={{ color: 'white', fontSize: 16, fontWeight: 'bold' }}>
              Security Stack - App Security
            </span>
          </div>


          <AppSecurityCards
            data={data} />

          <FindingsOverview
            data={data} />

          <UniqueFindingDistribution
            data={data} />

          <CWETop25MostDangerous
            data={data} />

          <OWASPDistribution
            data={data} />


          <div style={{
            width: '100%', backgroundColor: '#89181b', paddingTop: 20, paddingBottom: 20, paddingLeft: 20, paddingRight: 20, marginTop: 40,
            marginBottom: 20, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'
          }}>

            <span style={{ color: 'white', fontSize: 16, fontWeight: 'bold' }}>
              Assets Inventory
            </span>
          </div>

          <CategoryBreakdown 
          data={data}/>

          <OperatingSystemDistribution 
          data={data}/>

          <TopHardwareManufacturer 
          data={data}/>

          <TopClientOperatingSystem 
          data={data}/>

          <TopClientApplicationCategories 
          data={data}/>

          <ItemsByStatus data={data}/>

          <PhysicalVsVirtualItems 
          data={data}/>

          <HostsPerOSByItemType 
          data={data}/>

          <ItemsByClass 
          data={data}/>


          <div style={{
            width: '100%', 
              marginTop: 40,
            marginBottom: 20, 
          }}>  

          </div>


          <div style={{
            width: '100%', backgroundColor: '#89181b', paddingTop: 20, paddingBottom: 20, paddingLeft: 20, paddingRight: 20, marginTop: 40,
            marginBottom: 20, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'
          }}>

            <span style={{ color: 'white', fontSize: 16, fontWeight: 'bold' }}>
              Tickets/Issues
            </span>
          </div>


          <IncidentCards data={data}/>

          <AverageTimeToResolution 
          data={data}/>

          <IncidentsStatus 
          data={data}/>

          <ResolvedUnresolvedIncidents 
          data={data}/>

          <NewIncidents 
          data={data}/>

          <NewIncidentsByCategory 
          data={data}/>

          <CreatedClosedIncidents 
           data={data}/>

          <IncidentCards2 
            data={data}/>

          <NewIncidentForecast 
            data={data}/>

          <UnresolvedVsResolvedIncidents 
            data={data}/>

          <IncidentsByCategory 
            data={data}/>

        </div>

      </CContainer>
    </>
  )
}

export default OpsView