import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

//import data from '../../../datanew/Australia/data/opsView.json';
import GaugeChart from 'react-gauge-chart'

const AverageTimeToResolution = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const [screenWidth, setScreenWidth] = useState(1000);

    useEffect(() => {
        setScreenWidth(window.innerWidth - ((window.innerWidth * 25) / 100))
    }, []);

    const data = props.data;



    return (
        <div style={{
            width: '32%', marginLeft: 0, display: 'flex', flexDirection: 'column', height: 300,
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>



            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 10 }}>


            <div style={{ height: 60, }}>

                    <h6 style={{ color: '#fff', textAlign: 'left' }}>
                        Average Time To Resolution</h6>
                    <hr />

            </div>


                    <div style={{
                        display: 'flex',
                        flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', height:150
                    }} >


<div>

                        <GaugeChart id="gauge-chart3"
                            nrOfLevels={1}
                            colors={['#a4ba0d']}
                            arcWidth={0.3}
                            percent={data.charts.averageTimeToResolution.value}
                            hideText={true}

                        />

<div style={{
                            display: 'flex', flexDirection: 'row',
                            justifyContent: 'space-between', width: '100%'
                        }}>
                            <span style={{color:'#fff'}}>{data.charts.averageTimeToResolution.min} hrs</span>
                            <span style={{ fontSize: 23,color:'#fff' }}>{data.charts.averageTimeToResolution.value} hrs</span>

                            <span style={{color:'#fff'}}>{data.charts.averageTimeToResolution.max} hrs</span>

                        </div>

</div>

                      


                    </div>
                </div>
            </div>


    );
};

export default AverageTimeToResolution;