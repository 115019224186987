import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

import UniqueFindingDistributionData from '../../../data/ops-view/SecurityStackAppSecurity/UniqueFindingDistribution.json';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const UniqueFindingDistribution = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const data = props.data.charts;


    const data1 = {
      labels: [data.uniqueFindingDistribution[0].severity, 
      data.uniqueFindingDistribution[1].severity, 
      data.uniqueFindingDistribution[2].severity, 
      data.uniqueFindingDistribution[3].severity,
      data.uniqueFindingDistribution[4].severity,],
      datasets: [
        {
          data: [data.uniqueFindingDistribution[0].uniqueFindings, 
          data.uniqueFindingDistribution[1].uniqueFindings,
          data.uniqueFindingDistribution[2].uniqueFindings, 
          data.uniqueFindingDistribution[3].uniqueFindings,
          data.uniqueFindingDistribution[4].uniqueFindings],
          backgroundColor: ['#5dbc53', '#d76358'],
          hoverBackgroundColor: ['#5dbc53', '#d76358']
        }
      ],
      text: ""
    };
    
    const options1 = {
      plugins: {
        legend: {
          display: false
        }
      },
      cutout: 80
    };
    
    const plugins1 = [{
      id : 'pluginId',
      beforeDraw: function(chart) {
       var width = chart.width,
           height = chart.height,
           ctx = chart.ctx;
           ctx.restore();
           var fontSize = 0.7;
           ctx.font = fontSize + "em sans-serif";
           ctx.textBaseline = "top";
           ctx.textColor = "white";
           var text = `${(data.uniqueFindingDistribution[0].uniqueFindings +
            data.uniqueFindingDistribution[1].uniqueFindings + 
            data.uniqueFindingDistribution[2].uniqueFindings +
            data.uniqueFindingDistribution[2].uniqueFindings +
            data.uniqueFindingDistribution[3].uniqueFindings)} Unique Findings` ,
           textX = Math.round((width - ctx.measureText(text).width) / 2),
           textY = height / 2;
           ctx.fillStyle = '#fff';
           ctx.fillText(text, textX, textY);
           ctx.save();
      } 
     }]

    const [parentWidth, setParentWidth] = useState(0);
    const [screenWidth, setScreenWidth] = useState(1000);

  useEffect(() => {
      setScreenWidth (window.innerWidth - ((window.innerWidth * 25)/100))
  }, []);

    useEffect(() => {
      const updateParentWidth = () => {
        const parentDiv = document.getElementById('parentDiv');
        if (parentDiv) {
          const width = parentDiv.offsetWidth;
          setParentWidth(width * 0.95); // Assigning 90% width
        }
      };
  
      updateParentWidth();
  
      window.addEventListener('resize', updateParentWidth);
  
      return () => {
        window.removeEventListener('resize', updateParentWidth);
      };
    }, []);
   

    return (
        <div style={{
            width: '49%', marginLeft: 0, display: 'flex',
             flexDirection: 'column', height: 500,
            marginTop: '2%',
             background: 'linear-gradient(135deg, #161c2a, #1a2130)',
              padding: 20, borderRadius: 15
        }}>          


            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 10 }}>




            <div style={{ height: 60, }}>

                    <h6 style={{ color: '#fff', textAlign: 'left' }}>
                    Unique Finding Distribution</h6>
                    <hr />

          </div>


                    <div style={{ display: 'flex', flexDirection: 'row', 
                    justifyContent: 'space-between', alignItems: 'center', height:390 }} >
                         
                    <div style={{width:'40%'}}> 
                    <Doughnut 
          type="doughnut" 
          data={data1} 
          options={options1} 
          plugins={plugins1} 
          width={screenWidth * 0.3}
                height={screenWidth * 0.3} 
         />         

         </div>          



         <div style={{width:'55%'}}> 

<table style={{width:'100%'}}>

  <thead>
    <th style={{padding:5,color:'#fff'}}>Distribution</th>
    <th style={{padding:5,color:'#fff'}}>Unique Findings</th>
    <th style={{padding:5,color:'#fff'}}>Assets Affected</th>
    <th style={{padding:5,color:'#fff'}}>Locations Affected</th>
  </thead>

  <tbody>
     <tr>

      <td>{data.uniqueFindingDistribution[0].severity}</td>
      <td>{data.uniqueFindingDistribution[0].uniqueFindings}</td>
      <td>{data.uniqueFindingDistribution[0].assetsAffected}</td>
      <td>{data.uniqueFindingDistribution[0].locationsAffected}</td>
        
     </tr>
     <tr>

     <td>{data.uniqueFindingDistribution[1].severity}</td>
      <td>{data.uniqueFindingDistribution[1].uniqueFindings}</td>
      <td>{data.uniqueFindingDistribution[1].assetsAffected}</td>
      <td>{data.uniqueFindingDistribution[1].locationsAffected}</td>
        
     </tr>

     <tr>

     <td>{data.uniqueFindingDistribution[2].severity}</td>
      <td>{data.uniqueFindingDistribution[2].uniqueFindings}</td>
      <td>{data.uniqueFindingDistribution[2].assetsAffected}</td>
      <td>{data.uniqueFindingDistribution[2].locationsAffected}</td>
        
     </tr>

     <tr>

     <td>{data.uniqueFindingDistribution[3].severity}</td>
      <td>{data.uniqueFindingDistribution[3].uniqueFindings}</td>
      <td>{data.uniqueFindingDistribution[3].assetsAffected}</td>
      <td>{data.uniqueFindingDistribution[3].locationsAffected}</td>
        
     </tr>

     <tr>

     <td>{data.uniqueFindingDistribution[4].severity}</td>
      <td>{data.uniqueFindingDistribution[4].uniqueFindings}</td>
      <td>{data.uniqueFindingDistribution[4].assetsAffected}</td>
      <td>{data.uniqueFindingDistribution[4].locationsAffected}</td>
        
     </tr>
  </tbody>
    
</table>

</div>

                    

                    </div>
                </div>
            </div>


    );
};

export default UniqueFindingDistribution;