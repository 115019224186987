import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

import ServerSecurityCardsData from '../../../data/ops-view/SecurityStackServerSecurity/ServerSecurityCards.json';
import { FaServer } from "react-icons/fa";

import { MdOutlineSwitchAccount } from "react-icons/md";

const ServerSecurityCards = (props) => {

  const [onLoading, setOnLoading] = useState(false)

  const data = props.data.charts;

  const [parentWidth, setParentWidth] = useState(0);


  useEffect(() => {
    const updateParentWidth = () => {
      const parentDiv = document.getElementById('parentDiv');
      if (parentDiv) {
        const width = parentDiv.offsetWidth;
        setParentWidth(width * 0.95); // Assigning 90% width
      }
    };

    updateParentWidth();

    window.addEventListener('resize', updateParentWidth);

    return () => {
      window.removeEventListener('resize', updateParentWidth);
    };
  }, []);


  return (
    <div style={{
      width: '21%', marginLeft: 0, display: 'flex', flexDirection: 'column', 
      marginTop: '2%', padding: 20, borderRadius: 15
    }}>


    


      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10, height:'100%'  }}>


        <div style={{ width: '100%', height:'100%' }}>

        

          <div style={{
            display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center',
            height:'100%', 
          }}>

            
              <div style={{display:'flex', flexDirection:'column', backgroundColor:'#fff', justifyContent:'space-around',
              borderRadius:5, padding:20, width:'100%', alignItems:'center', height:'45%'}}>

                <FaServer size={40} style={{color:'#1e88e5'}}/>

                <div style={{display:'flex', flexDirection:'column'}}>

                  <span style={{fontSize:30, fontWeight:'bold', color:'#000', marginTop:10,
                   textAlign:'center'}}>{data.serverSecurityOverview[0].value}</span>
                  <span style={{fontSize:20, fontWeight:'normal', 
                  color:'#000', textAlign:'center'}}>{data.serverSecurityOverview[0].name}</span>

</div>

              </div>

              <div style={{display:'flex', flexDirection:'column', backgroundColor:'#fff',justifyContent:'space-around',
               borderRadius:5, padding:20, width:'100%',  alignItems:'center', height:'45%'}}>

              <MdOutlineSwitchAccount size={40} style={{color:'#388e3c'}}/>

              <div style={{display:'flex', flexDirection:'column'}}>
                  <span style={{fontSize:30,
                     fontWeight:'bold', color:'#000', marginTop:10,textAlign:'center'}}>
                      {data.serverSecurityOverview[1].value}</span>
                  <span style={{fontSize:20,
                     fontWeight:'normal', color:'#000', textAlign:'center'}}>
                      {data.serverSecurityOverview[1].name}</span>
              </div>

              </div>




          </div>
        </div>
      </div>


    </div>
  );
};

export default ServerSecurityCards;