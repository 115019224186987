import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
//import Chart from 'react-apexcharts';

import OrganizationalSLAOverviewData from '../../data/tactical-view/OrganizationalSLAOverview.json';


import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const OrganizationalSLAOverview = (props) => {

  const chartData = props.data.charts.organizationalSLAOverview;

  const data1 = {
    labels: ['Within SLA', 'Overdue', 'Closed'],
    datasets: [
      {
        data: [chartData.withinSLA, chartData.overdue, chartData.closed],
        backgroundColor: ['#5dbc53', '#d76358', '#85C1E9'],
        hoverBackgroundColor: ['#5dbc53', '#d76358', '#85C1E9']
      }
    ],
    text: ""
  };

  const options1 = {
    plugins: {
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          color: '#fff'
        }
      }
    },
    cutout: 80
  };

  const plugins1 = [{
    id: 'pluginId',
    beforeDraw: function (chart) {
      var width = chart.width,
        height = chart.height,
        ctx = chart.ctx;
      ctx.restore();
      var fontSize = 1;
      ctx.font = fontSize + "em sans-serif";
      ctx.textBaseline = "top";
      ctx.textColor = "white";
      var text = `${chartData.findings} Findings`,
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 2;
      ctx.fillStyle = '#fff';
      ctx.fillText(text, textX, textY);
      ctx.save();
    }
  }]

  const [screenWidth, setScreenWidth] = useState(1000);

  useEffect(() => {
    setScreenWidth(window.innerWidth - ((window.innerWidth * 25) / 100))
  }, []);

  return (
    <div style={{
      width: '43%', marginLeft: 0, display: 'flex', flexDirection: 'column', height: 500,
      marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
    }}>


      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 10 }}>


        <div style={{ height: 60, }}>


          <h6 style={{ color: '#fff', textAlign: 'left' }}>Organizational SLA Overview</h6>
          <hr />
        </div>

        <div style={{ display: 'flex', flexDirection: 'row',
           justifyContent: 'center', alignItems: 'center', height: 370 }}>



          <Doughnut
            type="doughnut"
            data={data1}
            options={options1}
            plugins={plugins1}
            width={screenWidth * 0.3}
            height={screenWidth * 0.3}


          />


        </div>
      </div>
    </div>
  );
};

export default OrganizationalSLAOverview;
