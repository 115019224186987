import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

//import data from '../../../datanew/Australia/data/opsView.json';

const PrioritizedRisks = (props) => {

  const [onLoading, setOnLoading] = useState(false)

  const [screenWidth, setScreenWidth] = useState(1000);

  const data = props.data;

  useEffect(() => {
      setScreenWidth (window.innerWidth - ((window.innerWidth * 25)/100))
  }, []);

  const seriesData = [
    {
      name: "Risks",
      data: [1380, 1100, 990],
    },
  ];

  const options = {
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: true,
        barHeight: '80%',
        isFunnel: true,

      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val, opt) {
        return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + val;
      },
      dropShadow: {
        enabled: true,
      },
    },
    title: {
      text: '',
      align: 'middle',
    },
    xaxis: {
      categories: [
        'Track',
        'Attend',
        'Act',
      ],
    },
    legend: {
      show: false,
    },
  };


  return (
    <div style={{
      width: '67%', marginLeft: 0, display: 'flex', flexDirection: 'column', height: 450,
      marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
    }}>


     


      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 10 }}>


      <div style={{ height: 60, }}>

          <h6 style={{ color: '#fff', textAlign: 'left' }}>Prioritized Risks</h6>
          <hr />

      </div>


          <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
            alignItems: 'center', height: 350,
          }}>



<ReactApexChart
        options={options}
        series={seriesData}
        type="bar"
        height={350}
        width={screenWidth * 0.65}
      />



          </div>
        </div>
      </div>


  );
};

export default PrioritizedRisks;