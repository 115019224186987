import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';
import '../../style.css';
import riskLevelChartData from '../../data/strategic-view/riskLevelChart.json';


const ProjectStatusChart = (props) => {

  const [onLoading, setOnLoading] = useState(false)
  const [screenWidth, setScreenWidth] = useState(1000);

  const data = props.data;

  const onTrackPercentage = (data.charts.projectStatusChart.onTrack / data.charts.projectStatusChart.total)*100 ;
  const delayedPercentage = (data.charts.projectStatusChart.delayed / data.charts.projectStatusChart.total)*100 ;
  const budgetIssuesPercentage = (data.charts.projectStatusChart.budgetIssues / data.charts.projectStatusChart.total)*100 ;

  useEffect(() => {
    setScreenWidth(window.innerWidth - ((window.innerWidth * 25) / 100))
  }, []);

  const projectStatusesContainer = {
    backgroundColor: '#ccc',
    height: '10px',
    borderRadius: '5px',
    position: 'relative',
    width: '100%',
    marginTop: 10,
    marginBottom: 10
  };

  const projectStatusProgressStyle1 = {
    backgroundColor: '#5dbc53',
    height: '100%',
    borderRadius: '5px',
    width: `${onTrackPercentage}%`,
  };

  const projectStatusProgressStyle2 = {
    backgroundColor: '#00a3ae',
    height: '100%',
    borderRadius: '5px',
    width: `${delayedPercentage}%`,
  };

  const projectStatusProgressStyle3 = {
    backgroundColor: '#db483c',
    height: '100%',
    borderRadius: '5px',
    width: `${budgetIssuesPercentage}%`,
  };


  const projectStatusChartData = {
    series: data.charts.projectStatusChart.series ,
    options: {
      chart: {
        type: 'bar'
      },
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      colors: ['#5dbc53', '#00a3ae', '#db483c'],
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: [
          "Apr 2023", "May 2023", "Jun 2023", "Jul 2023", "Aug 2023", "Sep 2023",
          "Oct 2023", "Nov 2023", "Dec 2023", "Jan 2024", "Feb 2024", "Mar 2024", "Apr 2024"
        ]
      },
      yaxis: {
        min: 0,
        max: 50,
        tickAmount: 6
      }
    }
  };


  return (
    <div style={{
      width: '49%', marginLeft: 0,
      display: 'flex', flexDirection: 'column', height: 550,
      marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
    }}>

      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', marginTop: 10 }}>

        
      <div style={{ height: 100 }}>

          <h4 style={{ color: '#fff', textAlign: 'left' }}>Project Status Chart</h4>
          <h6 style={{ color: '#fff', textAlign: 'left' }}>Project Status</h6>
          <hr />
      </div>


      <div style={{
                    height: 420, display: 'flex', width:'100%',
                    flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center'
                }}>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width:'100%' }}>

            <div style={{ width: '28%' }}>
              <span className="whiteSpan" style={{ fontWeight: 'bold' }}>On Track</span>
              <div style={projectStatusesContainer}>
                <div style={projectStatusProgressStyle1}></div>
              </div>
            </div>

            <div style={{ width: '28%' }}>
              <span className="whiteSpan" style={{ fontWeight: 'bold' }}>Delayed</span>
              <div style={projectStatusesContainer}>
                <div style={projectStatusProgressStyle2}></div>
              </div>
            </div>

            <div style={{ width: '28%' }}>
              <span className="whiteSpan" style={{ fontWeight: 'bold' }}>Budget Issues</span>
              <div style={projectStatusesContainer}>
                <div style={projectStatusProgressStyle3}></div>
              </div>
            </div>

          </div>


          <Chart
            options={projectStatusChartData.options}
            series={projectStatusChartData.series}
            type="bar"
            width={screenWidth * 0.5}
            height={350}
          />


        </div>

        </div>

      </div>
  
  );
};

export default ProjectStatusChart;
