import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';

import TopISOComplianceFailuresByIPData from '../../data/tactical-view/TopISOComplianceFailuresByIP.json'; 


const TopISOComplianceFailuresByIP = (props) => {

    const data = props.data;

    const [onLoading, setOnLoading] = useState(false)
    const [screenWidth, setScreenWidth] = useState(1000);

    useEffect(() => {
        setScreenWidth (window.innerWidth - ((window.innerWidth * 25)/100))
    }, []);

    const chartData = {
        series: TopISOComplianceFailuresByIPData.map(item => item.ratio),
        options: {
          chart: {
            type: 'pie',
            toolbar: {
              show: false
            }
          },
          labels: TopISOComplianceFailuresByIPData.map(item => item.ip),
          legend: {
            position: 'right',
            offsetY: 0,
            height: 300,
            markers: {
              fillColors: ['#fff'] 
            },
            itemMargin: {
              vertical: 4 
            },
            labels: {
              colors: ['#fff'] 
            }
          },
          colors: TopISOComplianceFailuresByIPData.map(item => item.color)
        }
      };


    return (
        <div style={{
            width: '100%', marginLeft: 0, display: 'flex',
             flexDirection: 'column',
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>

           


            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>


                <div style={{ width: '100%' }}>

                    <h6 style={{ color: '#fff', textAlign: 'left' }}>ISO/IEC 27000 - Top ISO Compliance Failures by IP</h6>
                    <hr />


                    <div className="pie-chart-container">
                        
                    <Chart
                         options={chartData.options}
                         series={chartData.series}
                         type="pie"
                         height={screenWidth * 0.5}
                         width={screenWidth * 0.5}
                    />

                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopISOComplianceFailuresByIP;
