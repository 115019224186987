import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

import AppSecurityCardsData from '../../../data/ops-view/SecurityStackAppSecurity/AppSecurityCards.json';

const AppSecurityCards = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const data = props.data.charts;

    const [parentWidth, setParentWidth] = useState(0);

    useEffect(() => {
      const updateParentWidth = () => {
        const parentDiv = document.getElementById('parentDiv');
        if (parentDiv) {
          const width = parentDiv.offsetWidth;
          setParentWidth(width * 0.95); // Assigning 90% width
        }
      };
  
      updateParentWidth();
  
      window.addEventListener('resize', updateParentWidth);
  
      return () => {
        window.removeEventListener('resize', updateParentWidth);
      };
    }, []);
   

    return (
        <div style={{
            width: '100%', marginLeft: 0, display: 'flex', 
            flexDirection: 'column', 
            marginTop: '0%', 
             padding: 20, borderRadius: 15
        }}>



            {onLoading &&

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

                    <ShimmerCircularImage size={500} />
                    <ShimmerCircularImage size={500} />
                </div>
            }


            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>


                <div style={{ width: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', 
                    justifyContent: 'space-between',
                     alignItems: 'center', flexWrap:'wrap' }} >
                         

                         <div style={{width:'18%',
                                      display:'flex',
                                      flexDirection:'column',
                                      justifyContent:'space-between',
                                      backgroundColor:'#fff',
                                      padding:20,
                                      borderRadius:10, height:120,
                                       alignItems:'center'}}>

                                <span style={{color:'#000', fontSize:20, 
                                    textAlign:'center',
                                fontWeight:'bold'}}>{data.appSecurityOverview[0].value}</span>
                                <span style={{color:'#000', fontSize:14, 
                                textAlign:'center',}}>{data.appSecurityOverview[0].name}</span>

                         </div>

                         <div style={{width:'18%',
                                      display:'flex',
                                      flexDirection:'column',
                                      justifyContent:'space-between',
                                      backgroundColor:'#fff',
                                      padding:20,
                                      borderRadius:10, height:120,
                                       alignItems:'center'}}>

                                <span style={{color:'#000', fontSize:20, 
                                textAlign:'center',
                                fontWeight:'bold'}}>{data.appSecurityOverview[1].value}</span>
                                <span style={{color:'#000', fontSize:14, 
                                textAlign:'center',}}>{data.appSecurityOverview[1].name}</span>

                         </div>


                         <div style={{width:'18%',
                                      display:'flex',
                                      flexDirection:'column',
                                      justifyContent:'space-between',
                                      backgroundColor:'#fff',
                                      padding:20,
                                      borderRadius:10, height:120,
                                       alignItems:'center'}}>

                                <span style={{color:'#000', fontSize:20, 
                                textAlign:'center',
                                fontWeight:'bold'}}>{data.appSecurityOverview[2].value}</span>
                                <span style={{color:'#000', fontSize:14, 
                                textAlign:'center',}}>{data.appSecurityOverview[2].name}</span>

                         </div>


                         <div style={{width:'18%',
                                      display:'flex',
                                      flexDirection:'column',
                                      justifyContent:'space-between',
                                      backgroundColor:'#fff',
                                      padding:20,
                                      borderRadius:10, height:120,
                                       alignItems:'center'}}>

                                <span style={{color:'#000', fontSize:20, 
                                textAlign:'center',
                                fontWeight:'bold'}}>{data.appSecurityOverview[3].value}</span>
                                <span style={{color:'#000', fontSize:14,
                                 textAlign:'center',}}>{data.appSecurityOverview[3].name}</span>

                         </div>


                         <div style={{width:'18%',
                                      display:'flex',
                                      flexDirection:'column',
                                      justifyContent:'space-between',
                                      backgroundColor:'#fff',
                                      padding:20,
                                      borderRadius:10, height:120,
                                       alignItems:'center'}}>

                                <span style={{color:'#000', fontSize:20,
                                 textAlign:'center',
                                fontWeight:'bold'}}>{data.appSecurityOverview[4].value}</span>
                                <span style={{color:'#000', fontSize:14,
                                 textAlign:'center',}}>{data.appSecurityOverview[4].name}</span>

                         </div>


                    </div>
                </div>
            </div>


        </div>
    );
};

export default AppSecurityCards;