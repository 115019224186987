import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

import FindingsData from '../../../data/ops-view/SecurityStackCloudSecurity/Findings.json';


const Findings = (props) => {

  const [onLoading, setOnLoading] = useState(false)

  const data = props.data.charts;

  const [selectedCategory, setSelectedCategory] = useState(Object.keys(data.findings.categories[0])[0]);
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);

  const handleCategoryClick = (category, index) => {

    setSelectedCategory(category);
    setSelectedCategoryIndex(index)
  };



  const [parentWidth, setParentWidth] = useState(0);

  useEffect(() => {
    const updateParentWidth = () => {
      const parentDiv = document.getElementById('parentDiv');
      if (parentDiv) {
        const width = parentDiv.offsetWidth;
        setParentWidth(width * 0.95); // Assigning 90% width
      }
    };

    updateParentWidth();

    window.addEventListener('resize', updateParentWidth);

    return () => {
      window.removeEventListener('resize', updateParentWidth);
    };
  }, []);

  /** 0 */
  const categories = data.findings.categories;

  const category = Object.keys(data.findings.categories[0])[0];
  const pieData = categories[0][category];


  const pieChartData = Object.keys(pieData).map(key => ({
    label: key,
    value: pieData[key]
  }));


  const chartOptions = {
    chart: {
      type: 'pie',
    },
    labels: pieChartData.map(dataPoint => dataPoint.label),
    legend: {
      position: 'bottom',
    },
  };

  const chartSeries = pieChartData.map(dataPoint => dataPoint.value);
  /** 0 */


  /** 1 */
  const categories1 = data.findings.categories;

  const category1 = Object.keys(data.findings.categories[1])[0];
  const pieData1 = categories1[1][category1];


  const pieChartData1 = Object.keys(pieData1).map(key => ({
    label: key,
    value: pieData1[key]
  }));


  const chartOptions1 = {
    chart: {
      type: 'pie',
    },
    labels: pieChartData1.map(dataPoint => dataPoint.label),
    legend: {
      position: 'bottom',
    },
  };

  const chartSeries1 = pieChartData1.map(dataPoint => dataPoint.value);
  /** 1 */



  /** 2 */
  const categories2 = data.findings.categories;

  const category2 = Object.keys(data.findings.categories[2])[0];
  const pieData2 = categories2[2][category2];


  const pieChartData2 = Object.keys(pieData1).map(key => ({
    label: key,
    value: pieData2[key]
  }));


  const chartOptions2 = {
    chart: {
      type: 'pie',
    },
    labels: pieChartData2.map(dataPoint => dataPoint.label),
    legend: {
      position: 'bottom',
    },
  };

  const chartSeries2 = pieChartData2.map(dataPoint => dataPoint.value);
  /** 2 */


  /** 3 */
  const categories3 = data.findings.categories;

  const category3 = Object.keys(data.findings.categories[3])[0];
  const pieData3 = categories3[3][category3];


  const pieChartData3 = Object.keys(pieData3).map(key => ({
    label: key,
    value: pieData3[key]
  }));


  const chartOptions3 = {
    chart: {
      type: 'pie',
    },
    labels: pieChartData3.map(dataPoint => dataPoint.label),
    legend: {
      position: 'bottom',
    },
  };

  const chartSeries3 = pieChartData3.map(dataPoint => dataPoint.value);
  /** 3 */


  /** 4 */
  const categories4 = data.findings.categories;

  const category4 = Object.keys(data.findings.categories[4])[0];
  const pieData4 = categories4[4][category4];


  const pieChartData4 = Object.keys(pieData4).map(key => ({
    label: key,
    value: pieData4[key]
  }));


  const chartOptions4 = {
    chart: {
      type: 'pie',
    },
    labels: pieChartData4.map(dataPoint => dataPoint.label),
    legend: {
      position: 'bottom',
    },
  };

  const chartSeries4 = pieChartData4.map(dataPoint => dataPoint.value);
  /** 4 */


  /** 5 */
  const categories5 = data.findings.categories;

  const category5 = Object.keys(data.findings.categories[5])[0];
  const pieData5 = categories5[5][category5];


  const pieChartData5 = Object.keys(pieData5).map(key => ({
    label: key,
    value: pieData5[key]
  }));


  const chartOptions5 = {
    chart: {
      type: 'pie',
    },
    labels: pieChartData5.map(dataPoint => dataPoint.label),
    legend: {
      position: 'bottom',
    },
  };

  const chartSeries5 = pieChartData5.map(dataPoint => dataPoint.value);
  /** 5 */


  /** 6 */
  const categories6 = data.findings.categories;

  const category6 = Object.keys(data.findings.categories[6])[0];
  const pieData6 = categories6[6][category6];


  const pieChartData6 = Object.keys(pieData6).map(key => ({
    label: key,
    value: pieData6[key]
  }));


  const chartOptions6 = {
    chart: {
      type: 'pie',
    },
    labels: pieChartData6.map(dataPoint => dataPoint.label),
    legend: {
      position: 'bottom',
    },
  };

  const chartSeries6 = pieChartData6.map(dataPoint => dataPoint.value);
  /** 6 */


  /** 7 */
  const categories7 = data.findings.categories;

  const category7 = Object.keys(data.findings.categories[7])[0];
  const pieData7 = categories7[7][category7];


  const pieChartData7 = Object.keys(pieData7).map(key => ({
    label: key,
    value: pieData7[key]
  }));


  const chartOptions7 = {
    chart: {
      type: 'pie',
    },
    labels: pieChartData7.map(dataPoint => dataPoint.label),
    legend: {
      position: 'bottom',
    },
  };

  const chartSeries7 = pieChartData7.map(dataPoint => dataPoint.value);
  /** 7 */



  return (
    <div style={{
      width: '55%', marginLeft: 0, display: 'flex', flexDirection: 'column', height: 500,
      marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
    }}>



      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 10 }}>


      <div style={{ height: 60, }}>

          <h6 style={{ color: '#fff', textAlign: 'left' }}>Findings</h6>
          <hr />

      </div>


          <div style={{ display: 'flex', flexDirection: 'row', 
              justifyContent: 'space-between', alignItems: 'center', height:400 }}>


            <div className="category-container" style={{ width: '60%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>


              <button onClick={() => handleCategoryClick(Object.keys(data.findings.categories[0])[0], 0)} className="findingsTab"
                style={{  backgroundColor: selectedCategory === Object.keys(data.findings.categories[0])[0] ? '#a6c8ff' : '#fff',  color: '#000', margin: 10, textAlign: 'center',  fontWeight: 'bold', border: '0px solid white' }}>
                {Object.keys(data.findings.categories[0])[0]}</button>

              <button onClick={() => handleCategoryClick(Object.keys(data.findings.categories[1])[0], 1)} className="findingsTab"
                style={{  backgroundColor: selectedCategory === Object.keys(data.findings.categories[1])[0] ? '#a6c8ff' : '#fff',  color: '#000', margin: 10, textAlign: 'center', fontWeight: 'bold', border: '0px solid white' }}>
                {Object.keys(data.findings.categories[1])[0]}</button>

              <button onClick={() => handleCategoryClick(Object.keys(data.findings.categories[2])[0], 2)} className="findingsTab"
                style={{  backgroundColor: selectedCategory === Object.keys(data.findings.categories[2])[0] ? '#a6c8ff' : '#fff',  color: '#000', margin: 10, textAlign: 'center',  fontWeight: 'bold', border: '0px solid white' }}>
                {Object.keys(data.findings.categories[2])[0]}</button>

              <button onClick={() => handleCategoryClick(Object.keys(data.findings.categories[3])[0], 3)} className="findingsTab"
                style={{  backgroundColor: selectedCategory === Object.keys(data.findings.categories[3])[0] ? '#a6c8ff' : '#fff',  color: '#000', margin: 10, textAlign: 'center',  fontWeight: 'bold', border: '0px solid white' }}>
                {Object.keys(data.findings.categories[3])[0]}</button>

              <button onClick={() => handleCategoryClick(Object.keys(data.findings.categories[4])[0], 4)} className="findingsTab"
                style={{  backgroundColor: selectedCategory === Object.keys(data.findings.categories[4])[0] ? '#a6c8ff' : '#fff',  color: '#000', margin: 10, textAlign: 'center',  fontWeight: 'bold', border: '0px solid white' }}>
                {Object.keys(data.findings.categories[4])[0]}</button>

              <button onClick={() => handleCategoryClick(Object.keys(data.findings.categories[5])[0], 5)} className="findingsTab"
                style={{  backgroundColor: selectedCategory === Object.keys(data.findings.categories[5])[0] ? '#a6c8ff' : '#fff',  color: '#000', margin: 10, textAlign: 'center',  fontWeight: 'bold', border: '0px solid white' }}>
                {Object.keys(data.findings.categories[5])[0]}</button>

              <button onClick={() => handleCategoryClick(Object.keys(data.findings.categories[6])[0], 6)} className="findingsTab"
                style={{  backgroundColor: selectedCategory === Object.keys(data.findings.categories[6])[0] ? '#a6c8ff' : '#fff',  color: '#000', margin: 10, textAlign: 'center',  fontWeight: 'bold', border: '0px solid white' }}>
                {Object.keys(data.findings.categories[6])[0]}</button>

              <button onClick={() => handleCategoryClick(Object.keys(data.findings.categories[7])[0], 7)} className="findingsTab"
                style={{  backgroundColor: selectedCategory === Object.keys(data.findings.categories[7])[0] ? '#a6c8ff' : '#fff',  color: '#000', margin: 10, textAlign: 'center',  fontWeight: 'bold', border: '0px solid white' }}>
                {Object.keys(data.findings.categories[7])[0]}</button>



            </div>

            <div className="pie-chart-container">

              {selectedCategoryIndex == 0 &&
                <ReactApexChart options={chartOptions} series={chartSeries} type="pie" height={350} />
              }

              {selectedCategoryIndex == 1 &&
                <ReactApexChart options={chartOptions1} series={chartSeries1} type="pie" height={350} />
              }

              {selectedCategoryIndex == 2 &&
                <ReactApexChart options={chartOptions2} series={chartSeries2} type="pie" height={350} />
              }

              {selectedCategoryIndex == 3 &&
                <ReactApexChart options={chartOptions3} series={chartSeries3} type="pie" height={350} />
              }

              {selectedCategoryIndex == 4 &&
                <ReactApexChart options={chartOptions4} series={chartSeries4} type="pie" height={350} />
              }

              {selectedCategoryIndex == 5 &&
                <ReactApexChart options={chartOptions5} series={chartSeries5} type="pie" height={350} />
              }


              {selectedCategoryIndex == 6 &&
                <ReactApexChart options={chartOptions6} series={chartSeries6} type="pie" height={350} />
              }

              {selectedCategoryIndex == 7 &&
                <ReactApexChart options={chartOptions7} series={chartSeries7} type="pie" height={350} />
              }

            </div>


          </div>
        </div>
      </div>


   
  );
};

export default Findings;