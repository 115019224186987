import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';
import '../../style.css';
import { Shimmer, Breathing } from 'react-shimmer'
import { MdError } from "react-icons/md";
import { SiSpringsecurity } from "react-icons/si";
import { RiFileDamageFill } from "react-icons/ri";
import { AiFillInsurance } from "react-icons/ai";


const TangibleImpactCards = (props) => {

  const data = props.data;

  const [onLoading, setOnLoading] = useState(false)
  const [screenWidth, setScreenWidth] = useState(1000);

  useEffect(() => {
    setScreenWidth(window.innerWidth - ((window.innerWidth * 25) / 100))
  }, []);

  return (
    <div style={{
      width: '100%', marginLeft: 0,
      display: 'flex', flexDirection: 'column',
      marginTop: '2%', padding: 20, borderRadius: 15
    }}>



          {/* Tangible impact - Cards - START */}
          <div style={{
            display: 'flex', flexDirection: 'row',
            justifyContent: 'space-between', width: '100%', flexWrap: 'wrap', marginTop: 30
          }}>

          


            
      <div className="" style={{
              display: 'flex', flexDirection: 'column', backgroundColor: '#fff',
              borderRadius: 10, padding: 20, width: '17%', justifyContent:'space-between', alignItems:'center'
            }}>

               <MdError size={45} style={{color:'#ffaa05'}}/>

                <h3 style={{ color: '#000', fontWeight: 600, textAlign: 'center', marginTop:20 }}>
                  {data.charts.tangibleImpactCards[0].value}</h3>
                <span style={{ fontSize: 16, color: '#ffaa05', textAlign: 'center' }}>
                  {data.charts.tangibleImpactCards[0].name}</span>
              </div>

           



              <div className="" style={{
              display: 'flex', flexDirection: 'column', backgroundColor: '#fff',
              borderRadius: 10, padding: 20, width: '17%', justifyContent:'space-between', alignItems:'center'
            }}>



                <SiSpringsecurity size={45} style={{color:'#7366ff'}}/>

                <h3 style={{ color: '#000', fontWeight: 600, textAlign: 'center', marginTop:20 }}>
                  {data.charts.tangibleImpactCards[1].value} </h3>
                <span style={{ fontSize: 16, color: '#7366ff', textAlign: 'center' }}>
                  {data.charts.tangibleImpactCards[1].name}</span>
              </div>

          

         


              <div className="" style={{
              display: 'flex', flexDirection: 'column', backgroundColor: '#fff',
              borderRadius: 10, padding: 20, width: '17%', justifyContent:'space-between', alignItems:'center'
            }}>

              <RiFileDamageFill size={45} style={{color:'#1ecbe1'}}/>

                <h3 style={{ color: '#000', fontWeight: 600, textAlign: 'center', marginTop:20 }}>
                  {data.charts.tangibleImpactCards[2].value}</h3>

                <span style={{ fontSize: 16, color: '#1ecbe1', textAlign: 'center' }}>
                  {data.charts.tangibleImpactCards[2].name}</span>
              </div>

           


          
              <div className="" style={{
              display: 'flex', flexDirection: 'column', backgroundColor: '#fff',
              borderRadius: 10, padding: 20, width: '17%', justifyContent:'space-between', alignItems:'center'
            }}>

                <AiFillInsurance size={45} style={{color:'#f73164'}}/>

                <h3 style={{ color: '#000', fontWeight: 600, textAlign: 'center', marginTop:20 }}>
                  {data.charts.tangibleImpactCards[3].value}</h3>

                <span style={{ fontSize: 16, color: '#f73164', textAlign: 'center' }}>
                  {data.charts.tangibleImpactCards[3].name}</span>
              </div>

          





              <div className="" style={{
              display: 'flex', flexDirection: 'column', backgroundColor: '#fff',
              borderRadius: 10, padding: 20, width: '17%', justifyContent:'space-between'
            }}>
                
                  <h3 style={{ color: '#000', fontWeight: 600, textAlign: 'center' }}>{data.charts.tangibleImpactCards[4].name}</h3>
                

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ fontSize: 16, color: '#f73164', }}>Highest</span>
                    <span style={{ fontSize: 16, color: 'green', }}>{data.charts.tangibleImpactCards[4].highest}</span>
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ fontSize: 16, color: '#f73164', }}>Lowest</span>
                    <span style={{ fontSize: 16, color: 'green', }}>{data.charts.tangibleImpactCards[4].lowest}</span>
                  </div>
                </div>
              </div>

            

          </div>
          {/* END -Tangible impact - Cards */}

        </div>

     
   

  );
};

export default TangibleImpactCards;
