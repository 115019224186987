import React, { useState, useEffect, useRef } from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';

import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CRow,
  CFormLabel,
  CFormSelect,
  CTextarea
} from '@coreui/react'
import { useParams, useNavigate } from 'react-router-dom'
import { IoMdArrowRoundBack } from "react-icons/io";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';


const AddApplication = () => {

  const navigate = useNavigate()

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [collectionUrl, setCollectionUrl] = useState("")
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false)
  const [applicationId, setApplicationId] = useState('')
  const [applications, setApplications] = useState([])
  const [language, setLanguage] = useState('')

  const [users, setUsers] = useState([])
  const [businessUnits, setBusinessUnits] = useState([])
  const [assetGroups, setAssetGroups] = useState([])


  const [userType, setUserType] = useState('user')
  const [businessUnit, setBusinessUnit] = useState('')
  const [location, setLocation] = useState('')
  const [theStatus, setTheStatus] = useState('Active')


  const [validationFailed, setValidationFailed] = useState(false);
  const [errorText, setErrorText] = useState('');

  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  const toaster = useRef();

  const roles = ['Administrator', 'Manager', 'Application Manager', 'Tester', 'Report Viewer']
  const statuses = ['Active', 'Suspended'] 
  

  useEffect(() => {

    fetchBusinessUnits();

  }, []);
 


  const fetchBusinessUnits = async () => {

    setLoading(true);


    const token = localStorage.getItem('ASIToken');
    const response = await axios.get(`/api/v1/organizations/getBusinessUnits`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    setBusinessUnits(response.data.businessUnits);

    if (response.data.businessUnits.length > 0) {
      setBusinessUnit(response.data.businessUnits[0]._id)
    } else {
      setBusinessUnit('');
    }


    setLoading(false);
  };


  

  const addUser = () => {

    if (firstName === '') {

      setValidationFailed(true);
      setErrorText('First name is required.');

    } else if (lastName === '') {

      setValidationFailed(true);
      setErrorText('Last name is required.');

    }if (email === '') {

      setValidationFailed(true);
      setErrorText('Email is required.');

    }if (phoneNumber === '') {

      setValidationFailed(true);
      setErrorText('Phone number is required.');

    } else {


      setLoading(true)

      const bearerToken = localStorage.getItem('ASIToken');

      // Create a FormData object
      const formData = new FormData();
      formData.append('firstName', firstName);
      formData.append('lastName', lastName);
      formData.append('email', email);
      formData.append('phoneNumber', phoneNumber);
      formData.append('businessUnit', businessUnit);
      formData.append('userType', userType);
      formData.append('location', location);    
      formData.append('status', theStatus);    

     

      const requestData = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phoneNumber: phoneNumber,
        businessUnit: businessUnit,
        userType: userType,
        location: location,  
        status:theStatus     
    };


      // Make the API call
      fetch(global.backendUrl + '/api/v1/organizations/addUser', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${bearerToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      })
        .then(response => response.json())
        .then(data => {

          // Handle the API response

          if (data.hasOwnProperty('error')) {
            setValidationFailed(true);
            setErrorText(data.error);
            setLoading(false);
          }
          else if (data.hasOwnProperty('err')) {
            setLoading(false);
            setValidationFailed(true);
            setErrorText("Something went wrong. Please try again");

          } else {

            setSubmissionSuccess(true);
            setLoading(false);

            toast('User added', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });


            navigate('/users')
          }

        })
        .catch(error => {
          // Handle any errors
          console.error(error);
        });      

    }

  }



  const goBack = (e) => {

    e.preventDefault();
    navigate('/users')
  }


  return (
    <div style={{ display: 'flex', overflow: "scroll", position: 'relative', overflowY: 'hidden', overflowX: 'hidden', }}>

      <div style={{ width: '60%' }}>
        <div>
          <div style={{ marginBottom: '0rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

            <h2>Add User</h2>
            <CButton
              onClick={goBack}
              style={{
                width: 300,
                marginBottom: '2%',
                marginRight: 20,
                borderWidth: 0,
                fontSize: 20,
                borderColor: '#fff',
                borderWidth: 1,
                color: '#fff',
                background: 'transparent'
              }}
              color="primary"
              className="px-3"
            >
              <IoMdArrowRoundBack size={25} style={{ color: '#fff', marginRight: 10 }} />
              Back to Users
            </CButton>
          </div>


          <div style={{ width: '100%', backgroundColor: '#252B3B', padding: 15 }}>


            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>First Name</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>

              <CFormInput
                placeholder="First Name"
                autoComplete="firstName"
                className="white-input"
                onChange={(e) => setFirstName(e.target.value)}
                style={{ width: '100%' }}
              />


            </CInputGroup>

            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Last Name</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>

              <CFormInput
                placeholder="Last Name"
                autoComplete="lastName"
                className="white-input"
                onChange={(e) => setLastName(e.target.value)}
                style={{ width: '100%' }}
              />


            </CInputGroup>


            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Email</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>

              <CFormInput
                placeholder="Email"
                autoComplete="email"
                className="white-input"
                onChange={(e) => setEmail(e.target.value)}
                style={{ width: '100%' }}
              />


            </CInputGroup>


            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Phone Number</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>

              <CFormInput
                placeholder="Phone Number"
                autoComplete="phoneNumber"
                className="white-input"
                onChange={(e) => setPhoneNumber(e.target.value)}
                style={{ width: '100%' }}
              />


            </CInputGroup>        



            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Business Unit</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormSelect
                id="businessUnit"
                className="white-input"
                onChange={(e) => setBusinessUnit(e.target.value)}
                style={{ width: '100%' }}
              >
                {businessUnits.map(businessUnit => (
                  <option key={businessUnit._id} value={businessUnit._id}>
                    {businessUnit.name}
                  </option>
                ))}
              </CFormSelect>
            </CInputGroup>


            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Location</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>

              <CFormInput
                placeholder="Location"
                autoComplete="location"
                className="white-input"
                onChange={(e) => setLocation(e.target.value)}
                style={{ width: '100%' }}
              />


            </CInputGroup>

          


            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Status</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormSelect
                id="status"
                className="white-input"
                onChange={(e) => setTheStatus(e.target.value)}
                style={{ width: '100%' }}
              >
                {statuses.map(status => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </CFormSelect>
            </CInputGroup>



            <CButton
              style={{
                width: '100%',
                marginTop: '3%',
                marginBottom: '2%',
                borderWidth: 0,
                fontSize: 20,
                background: '#89181b'
              }}
              color="primary"
              className="px-3"
              onClick={addUser}
              disabled={loading}
            >


              {loading ?
                <CircularProgress color="primary" size={24} style={{ marginTop: 10, color: '#fff' }} />
                :
                'Save User'
              }


            </CButton>


          </div>


        </div>
      </div>

    </div>
  )
}

export default AddApplication



