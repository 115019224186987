import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';

import ComplianceFailuresData from '../../data/tactical-view/ComplianceFailures.json'; 

const ComplianceFailures = () => {

    const [onLoading, setOnLoading] = useState(false)

    const [screenWidth, setScreenWidth] = useState(1000);

  useEffect(() => {
      setScreenWidth (window.innerWidth - ((window.innerWidth * 25)/100))
  }, []);

    const chartData = ComplianceFailuresData;

    return (
      <div style={{
          width: '100%', marginLeft: 0, display: 'flex', flexDirection: 'column', 
          marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
      }}>



          {onLoading &&

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

                  <ShimmerCircularImage size={500} />
                  <ShimmerCircularImage size={500} />
              </div>
          }



          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>


              <div style={{ width: '100%' }}>

                  <h6 style={{ color: '#fff', textAlign: 'left' }}>ISO/IEC 27000 - Compliance Failures (Last 25 days)</h6>
                  <hr />


                  <div id="chart" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems:'center',  }}>
                      

      {chartData && (
        <Chart
        options={{
          xaxis: {
            categories: chartData.xAxisCategories,
          },
          yaxis: {
            min: 0,
            max: 2200,
            tickAmount: 12,
            labels: {
              formatter: function(value) {
                return value.toFixed(0);
              }
            }
          },
          stroke: {
            width: 2, // Set line thickness to 2px
          },
          markers: {
            size: 5, // Set dot size to 5px
          },
          colors: ['#ffffff'], // Set legend color to white
        }}
        series={chartData.series}
        type="line"
        height={350}
        width={screenWidth}
      />
      )}

                  </div>



              </div>


          </div>


      </div>
  );
};

export default ComplianceFailures;
