import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

import AWSFailuresByControlCriticalityData from '../../../data/ops-view/SecurityStackCloudSecurity/AWSFailuresByControlCriticality.json';


const AWSFailuresByControlCriticality = (props) => {

  const [onLoading, setOnLoading] = useState(false)

  const data = props.data.charts;

  const [screenWidth, setScreenWidth] = useState(0);

  useEffect(() => {

    setScreenWidth (window.innerWidth - ((window.innerWidth * 25)/100));

}, []);


  const { highFailures, mediumFailures, lowFailures } = data.awsFailuresByControlCriticality;
  const totalFailures = highFailures + mediumFailures + lowFailures;
  
  const chartData = [highFailures, mediumFailures, lowFailures];


  const chartOptions = {
    labels: ['HIGH', 'MEDIUM', 'LOW'],
    colors: ['#e11b03', '#e36b1b', '#00e396'],
    plotOptions: {
      pie: {
        donut: {
          size: '70%',
        },
      },
    },
  };

  return (
    <div style={{
      width: '32%', marginLeft: 0, display: 'flex', flexDirection: 'column',
      marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
    }}>    



      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>


        <div style={{ width: '100%' }}>

          <h6 style={{ color: '#fff', textAlign: 'left' }}>AWS Failures by Control Criticality</h6>
          <hr />


          <div className="customSection" style={{
            display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center',
            alignItems: 'center', 
          }}>


            <div style={{ display: 'flex', flexDirection: 'column', borderRadius: 5, border: '1px solid white', padding: 15,
          backgroundColor:'#fff', alignItems:'center', justifyContent:'space-between', width:150, height:100 }}>

              <span style={{ fontSize: 16, textAlign: 'center',color:'#000' }}>Total Failures</span>
              <span style={{ fontSize: 18, textAlign: 'center',color:'#000', fontWeight:'bold' }}>{totalFailures}</span>

            </div>

            <div style={{ width: '100%' }}>
              <ReactApexChart
                options={chartOptions}
                series={chartData}
                type="donut"
                width={screenWidth * 0.25}
              />

            </div>            
          </div>
        </div>
      </div>
    </div>
  );
};

export default AWSFailuresByControlCriticality;