import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

//import data from '../../../datanew/Australia/data/opsView.json';

const CategoryBreakdown = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const [screenWidth, setScreenWidth] = useState(1000);

    const data = props.data; 

  useEffect(() => {
      setScreenWidth (window.innerWidth - ((window.innerWidth * 25)/100))
  }, []);



  const options = {
    chart: {
      type: 'bar',
    },
    xaxis: {
      categories: data.charts.categoriesBreakdown.categories,
    },
  };

  const series = [
    {
      name: data.charts.categoriesBreakdown.series[0].name,
      data: data.charts.categoriesBreakdown.series[0].data,
    },
  ];
   

    return (
        <div style={{
            width: '32%', marginLeft: 0, display: 'flex', flexDirection: 'column', 
            height: 450,
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>



            {onLoading &&

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

                    <ShimmerCircularImage size={500} />
                    <ShimmerCircularImage size={500} />
                </div>
            }


            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>


                <div style={{ width: '100%' }}>

                    <h6 style={{ color: '#fff', textAlign: 'left' }}>
                    Category Breakdown</h6>
                    <hr />


                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} id="parentDiv">
                         
                    <ReactApexChart options={options} series={series} 
                    type="bar" height={320} width={screenWidth * 0.3}/>

                    </div>
                </div>
            </div>


        </div>
    );
};

export default CategoryBreakdown;