import React, { useEffect, useState, useRef } from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilMenu } from '@coreui/icons'

import { Link } from "react-router-dom";
import Modal from 'react-modal';

import { AppBreadcrumb } from './index'
import { AppHeaderDropdown } from './header/index'
import { sideBarHandler } from 'src/store/sideBar/actions'
import lightLogo from '../assets/images/Intrucept_logo_hybrid.png'
import axios from 'axios'

import { AiFillDashboard } from "react-icons/ai";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { AiFillRobot } from "react-icons/ai";
import { GrCloudComputer } from "react-icons/gr";
import { TbApiApp } from "react-icons/tb";
import { GrAlert } from "react-icons/gr";
import { BsCardText } from "react-icons/bs";
import { GrSettingsOption } from "react-icons/gr";
import { useLocation } from 'react-router-dom'
import { HiOutlineDesktopComputer } from "react-icons/hi";
import { FiAlertCircle } from "react-icons/fi";
import { FiSettings } from "react-icons/fi";
import { AiOutlineSearch } from "react-icons/ai";
import { BsFillBellFill } from "react-icons/bs";

const AppHeader = () => {

  const dispatch = useDispatch()
  const sidebarShow = useSelector((state) => state.sidbarReducer.sidebarShow)
  const [name, setName] = useState('');
  const [role, setRole] = useState('');
  const [isActiveRoute, setIsActiveRoute] = useState(false);
  const [currentRoute, setCurrentRoute] = useState('');
  const [notiPanelVisible, setNotiPanelVisible] = useState(false);

  const [user, setUser] = useState(null);

  const [clickedOutside, setClickedOutside] = useState(false);
  const [last10Alerts, setLast10Alerts] = useState([]);

  const myRef = useRef();

  const location = useLocation();

  useEffect(() => {

    setCurrentRoute('/');
    getUserDetails();

  }, [])  


  const getUserDetails = () => {

    setUser(JSON.parse(localStorage.getItem('ASIUser')));
  }

  const getLast10Alerts = () => {

    
  }  

  const toggleNotiPanel = () => {
    setNotiPanelVisible(!notiPanelVisible);
  }

  const handleClickOutside = e => {

    if (myRef.current && !myRef.current.contains(e.target)) {
      setNotiPanelVisible(false);
    }
  };

  const handleClickInside = () => setClickedOutside(false);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });


  return (
    <CHeader position="sticky" className="mb-4" style={{backgroundColor:'#fff', alignItems:'center', height:70}}>


      <CContainer fluid className="header-primary" style={{backgroundColor:'#fff', display:'flex', 
          alignSelf: 'center',  justifyContent:'space-between'}}>


        <img src={lightLogo} style={{ width: 170, float:'left'  }} alt="" />
       

        <CHeaderNav className="ms-5" style={{ width: 300, display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>

          <CNavItem >
            <CNavLink href="#" style={{ textDecoration: 'none', display: 'flex', flexDirection: 'column' }}>
              <span className="" style={{color:'#000'}}>Welcome, Nitin</span>
              <span style={{color:'#444', fontSize:13}}>Administrator</span>
            </CNavLink>
          </CNavItem>
         

          <AppHeaderDropdown style={{ marginLeft: 50 }} />
        </CHeaderNav>

      </CContainer>     

      

    </CHeader>
  )
}

export default AppHeader
