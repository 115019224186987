import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';

import ProjectsCardsData from '../../data/tactical-view/ProjectsCards.json'; 
import { FaArrowAltCircleUp } from "react-icons/fa";
import { FaArrowAltCircleDown } from "react-icons/fa";
import { AiFillProject } from "react-icons/ai";
import { FaTasks } from "react-icons/fa";


const ProjectsCards = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const data = props.data;

    return (
        <div style={{
            width: '100%', marginLeft: 0, display: 'flex', flexDirection: 'column',
            marginTop: '0%', padding: 20, borderRadius: 15
        }}>           


            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>


                <div style={{ width: '100%' }}>                  


                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems:'center' }}>
                        

                          <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center',
                           backgroundColor:'#fff', padding:20, borderRadius:10, width:'25%'}}>

                              <AiFillProject style={{color:'#1ecbe1'}} size={70}/>
                              
                              <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
                                  
                                  <span style={{fontSize:25, color:'#000', fontWeight:'bold', marginLeft:20}}> {data.charts.projectsCards.projectsValue1} / {data.charts.projectsCards.projectsValue2}</span>
                                  <span style={{fontSize:20, color:'#000', fontWeight:'bold', marginLeft:20}}>Projects</span>
                                  <span style={{marginLeft:20, marginTop:15}}>
                                     {data.charts.projectsCards.projectsTendency == 'increase'?
                                    <FaArrowAltCircleUp style={{color:'green'}} size={20}/>
                                    :
                                    <FaArrowAltCircleDown style={{color:'red'}} size={20}/>
                                    }
                                    &nbsp;
                                  <span style={{fontSize:15, color:'#000', fontWeight:'normal', }}>{data.charts.projectsCards.projectsTendencyValuePercent}% {data.charts.projectsCards.projectsTendency} from last month</span></span>


                              </div>

                          </div>


                          <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center',
                           backgroundColor:'#fff', padding:20, borderRadius:10, marginLeft:50, width:'25%'}}>

                              <FaTasks style={{color:'#7366ff'}} size={70}/>
                              
                              <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
                                  
                                  <span style={{fontSize:25, color:'#000', fontWeight:'bold', marginLeft:20}}> {data.charts.projectsCards.tasksValue1} / {data.charts.projectsCards.tasksValue2}</span>
                                  <span style={{fontSize:20, color:'#000', fontWeight:'bold', marginLeft:20}}>Tasks</span>
                                  <span style={{marginLeft:20, marginTop:15}}>
                                     {data.charts.projectsCards.tasksTendency == 'increase'?
                                    <FaArrowAltCircleUp style={{color:'green'}} size={20}/>
                                    :
                                    <FaArrowAltCircleDown style={{color:'red'}} size={20}/>
                                    }
                                    &nbsp;
                                  <span style={{fontSize:15, color:'#000', fontWeight:'normal', }}>{data.charts.projectsCards.tasksTendencyValuePercent}% {data.charts.projectsCards.tasksTendency} from last month</span></span>


                              </div>

                          </div>
                    



                    </div>



                </div>


            </div>


        </div>
    );
};

export default ProjectsCards;
