import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

import MessagesBlockedScoresTimelineData from '../../../data/ops-view/SecurityStackEmailSecurity/MessagesBlockedScoresTimeline.json';


const MessagesBlockedScoresTimeline = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const data = props.data.charts;

    const [parentWidth, setParentWidth] = useState(0);

    useEffect(() => {
      const updateParentWidth = () => {
        const parentDiv = document.getElementById('parentDivMessagesBlockedScoresTimeline');
        if (parentDiv) {
          const width = parentDiv.offsetWidth;
          setParentWidth(width * 0.95); // Assigning 90% width
        }
      };
  
      updateParentWidth();
  
      window.addEventListener('resize', updateParentWidth);
  
      return () => {
        window.removeEventListener('resize', updateParentWidth);
      };
    }, []);


    const categories = data.messagesBlockedScoresTimeline.map(item => item.date);
  const series = Object.keys(data.messagesBlockedScoresTimeline[0].values).map(seriesName => ({
    name: seriesName,
    data: data.messagesBlockedScoresTimeline.map(item => item.values[seriesName])
  }));

  const options = {
    chart: {
      type: 'line',
      height: 350,
      stacked: false,
      
    },
    grid: {
      borderColor: '#e0e0e0', // Lighter color for grid lines
      strokeDashArray: 4 // Dotted grid lines
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: categories
    },
   
  };

   
console.log('parentWidth:',parentWidth)
    return (
        <div style={{
            width: '100%', marginLeft: 0, display: 'flex', flexDirection: 'column', 
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>



            {onLoading &&

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

                    <ShimmerCircularImage size={500} />
                    <ShimmerCircularImage size={500} />
                </div>
            }


            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10, width:'100%' }}>


                <div style={{ width: '100%' }}>

                    <h6 style={{ color: '#fff', textAlign: 'left' }}>Messages Blocked Scores Timeline</h6>
                    <hr />


                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width:'100%'  }} id="parentDivMessagesBlockedScoresTimeline">
                         
                    <ReactApexChart options={options} series={series} type="line" height={350} width={parentWidth}/>

                    
                    </div>
                </div>
            </div>


        </div>
    );
};

export default MessagesBlockedScoresTimeline;