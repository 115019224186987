import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

import SystemsHealthData from '../../../data/ops-view/SecurityStackServerSecurity/SystemsHealth.json';


const SystemsHealth = (props) => {

  const [onLoading, setOnLoading] = useState(false)

  const data = props.data.charts.systemsHealth;

  const [parentWidth, setParentWidth] = useState(0);
  const [screenWidth, setScreenWidth] = useState(1000);

  useEffect(() => {
      setScreenWidth (window.innerWidth - ((window.innerWidth * 25)/100))
  }, []);


  const names = data.map(item => item.type);
  const values = data.map(item => item.value);


  // Configuration object for the pie chart
  const chartOptions = {
      labels: names,
      series: values,
      options: {
          chart: {
              type: 'donut',
              height: 350,
          },
          legend: {
              position: 'right',
          },
          responsive: [
              {
                  breakpoint: 480,
                  options: {
                      chart: {
                          width: 200,
                      },
                      legend: {
                          position: 'bottom',
                      },
                  },
              },
          ],
      },
  };


  useEffect(() => {
    const updateParentWidth = () => {
      const parentDiv = document.getElementById('parentDiv');
      if (parentDiv) {
        const width = parentDiv.offsetWidth;
        setParentWidth(width * 0.95); // Assigning 90% width
      }
    };

    updateParentWidth();

    window.addEventListener('resize', updateParentWidth);

    return () => {
      window.removeEventListener('resize', updateParentWidth);
    };
  }, []);


  return (
    <div style={{
      width: '37%', marginLeft: 0, display: 'flex', flexDirection: 'column', 
      marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
    }}>


      

      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 10 }}>


      <div style={{ height: 60, }}>

          <h6 style={{ color: '#fff', textAlign: 'left' }}>Systems Health</h6>
          <hr />

      </div>


          <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
            
          }}>

            
                       <ReactApexChart
                            options={{
                                ...chartOptions.options,
                                labels: names,
                            }}
                            series={chartOptions.series}
                            type="donut"
                            width={screenWidth * 0.35}
                            height={screenWidth * 0.35}
                        />





          </div>
        </div>
      </div>


   
  );
};

export default SystemsHealth;