import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

import SystemLoginsData from '../../../data/ops-view/SecurityStackServerSecurity/SystemLogins.json';
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';


const SystemLogins = (props) => {

  console.log('selectedRegion:', props.selectedRegion)

  const [onLoading, setOnLoading] = useState(false)

  const data = SystemLoginsData;

  const [parentWidth, setParentWidth] = useState(0);
  const [screenWidth, setScreenWidth] = useState(1000);

  useEffect(() => {
      setScreenWidth (window.innerWidth - ((window.innerWidth * 25)/100))
  }, []);


  const dotColors = ['red', 'red', 'red', 'red'];

  const dotData = data.systemLogins.map(site => {
    const dotColor = dotColors[0];
    return {
      name: site.numOfLogins,
      coordinates: [[site.longitude, site.latitude]],
      dotColor
    };
  });


  useEffect(() => {
    const updateParentWidth = () => {
      const parentDiv = document.getElementById('parentDiv');
      if (parentDiv) {
        const width = parentDiv.offsetWidth;
        setParentWidth(width * 0.95); // Assigning 90% width
      }
    };

    updateParentWidth();

    window.addEventListener('resize', updateParentWidth);

    return () => {
      window.removeEventListener('resize', updateParentWidth);
    };
  }, []);


  return (
    <div style={{
      width: '59%', marginLeft: 0, display: 'flex', flexDirection: 'column', 
      height: 550,
      marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
    }}>


     

      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 10 }}>
         

          <div style={{ height: 60, }}>

          <h6 style={{ color: '#fff', textAlign: 'left' }}>System Logins</h6>
          <hr />

</div>



          <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
            alignItems: 'center', height:420
          }}>


            <div style={{width: '100%', 
                background:'#abd3df', borderRadius:10, display:'flex', flexDirection:'row', justifyContent:'center'  }}>
              <ComposableMap style={{ height: 450, }}>
                <Geographies geography="/features.json" fill="#f2efe8"
        stroke="#FFFFFF">
                  {({ geographies }) =>
                    geographies.map((geo) => <Geography key={geo.rsmKey} geography={geo} />)
                  }
                </Geographies>
                {dotData.map((dot, index) => (
                  dot.coordinates.map((coordinate, idx) => (
                    <Marker key={`${index}-${idx}`} coordinates={coordinate}>
                      <circle
                        cx={0}
                        cy={0}
                        r={6}
                        fill={dotColors[0]}
                        stroke="#fff"
                        strokeWidth={2}
                      />
                      <text
            textAnchor="middle"
            y={25}
            style={{ fontFamily: "system-ui", fill: "red", fontSize:15 }}
          >
            {dot.name}
          </text>
                    </Marker>
                  ))
                ))}
              </ComposableMap>
            </div>




          </div>
        </div>
      </div>


    
  );
};

export default SystemLogins;