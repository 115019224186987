import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';


const RecentMentions = (props) => {

  const data = props.data;

    const [onLoading, setOnLoading] = useState(false)

    const total = data.charts.recentMentions.negative +  data.charts.recentMentions.neutral + data.charts.recentMentions.positive;



    useEffect(() => {
        const animateBars = () => {
          const bars = document.querySelectorAll('.bar1 span');

          const percentagePositive = (data.charts.recentMentions.positive / total) * 100;
          const percentageNeutral = (data.charts.recentMentions.neutral / total) * 100;
          const percentageNegative = (data.charts.recentMentions.negative / total) * 100;

          console.log('percentagePositive:',percentagePositive)
          console.log('percentageNeutral:',percentageNeutral)
          console.log('percentageNegative:',percentageNegative)
    

          if(bars){
          bars.forEach(bar => bar.style.display = 'none');
    
          

          animate('#bar-five1', `${percentagePositive}%`);
          animate('#bar-four1', `${percentageNeutral}%`);
          animate('#bar-three1', `${percentageNegative}%`);
    
          setTimeout(() => {
            bars.forEach(bar => bar.style.display = 'inline');
          }, 1000);
        }
        };
    
        animateBars();
      }, []);
    
      // Animation function
      function animate(selector, width) {
        const element = document.querySelector(selector);
        if(element){
        let currentWidth = 0;
        const step = 1;
        const duration = 1000 / (width / step);
        const timer = setInterval(() => {
          currentWidth += step;
          element.style.width = currentWidth + '%';
          if (currentWidth >= parseInt(width)) {
            clearInterval(timer);
          }
        }, duration);
      }
      }


    return (
        <div style={{
            width: '49%', marginLeft: 0, display: 'flex',
             flexDirection: 'column', height: 450,
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>



            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 10 }}>

            <div style={{ height: 60,  }}>

                    <h4 style={{ color: '#fff', textAlign: 'left' }}>Recent Mentions</h4>
                    <p>Mentions of your business online including website </p>
                    <hr />

                    </div>


                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems:'center', height:370 }}>

                       
                <div className="reviews-container" style={{marginTop:-10}}>
  <div className="inner">
    <div className="rating">
      <span className="rating-num">{data.charts.recentMentions.thisWeek}</span>
      <div className="rating-stars">
        <span><i className="active icon-star"></i></span>
        <span><i className="active icon-star"></i></span>
        <span><i className="active icon-star"></i></span>
        <span><i className="active icon-star"></i></span>
        <span><i className="icon-star"></i></span>
      </div>
      <div className="rating-users">
        <i className="icon-user"></i> This Week
      </div>
    </div>
    
    <div className="histo">
      <div className="five histo-rate">
        <span className="histo-star">
          <i className="active icon-star"></i> Positive
        </span>
        <span className="bar-block">
          <span id="bar-five1" className="bar1">
            <span>{data.charts.recentMentions.positive}</span>&nbsp;
          </span> 
        </span>
      </div>
      
      <div className="four histo-rate">
        <span className="histo-star">
          <i className="active icon-star"></i> Neutral
        </span>
        <span className="bar-block">
          <span id="bar-four1" className="bar1">
            <span>{data.charts.recentMentions.neutral}</span>&nbsp;
          </span> 
        </span>
      </div> 
      
      <div className="three histo-rate">
        <span className="histo-star">
          <i className="active icon-star"></i> Negative
        </span>
        <span className="bar-block">
          <span id="bar-three1" className="bar1">
            <span>{data.charts.recentMentions.negative}</span>&nbsp;
          </span> 
        </span>
      </div>
     
      
     
    </div>
  </div>
</div>


                        
                    </div>



                </div>


            </div>


       
    );
};

export default RecentMentions;
