import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';
import '../../style.css';


const WhatSensitiveDataTypesAreStored = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const data = props.data;

    const g1Percentage = 50;
    const g2Percentage = 30;
    const g3Percentage = 80;

    const containerStyle = {
        backgroundColor: '#6eb5f9',
        height: '10px',
        borderRadius: '5px',
        position: 'relative',
        width: '100%',
        marginTop: 10,
        marginBottom: 10
    };

    const progressStyle1 = {
        backgroundColor: '#2a63a8',
        height: '100%',
        borderRadius: '5px',
        width: `${g1Percentage}%`,
    };

    const progressStyle2 = {
        backgroundColor: '#2a63a8',
        height: '100%',
        borderRadius: '5px',
        width: `${g2Percentage}%`,
    };

    const progressStyle3 = {
        backgroundColor: '#2a63a8',
        height: '100%',
        borderRadius: '5px',
        width: `${g3Percentage}%`,
    };


    return (
        <div style={{
            width: '32%', marginLeft: 0, display: 'flex',
            flexDirection: 'column', height: 520,
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', marginTop: 10 }}>


                <div style={{ height: 100 }}>

                        <h4 style={{ color: '#fff', textAlign: 'left' }}>
                            What Sensitive Data Types Are Stored?</h4>                        
                        <hr />    

               </div>         


              

                        
                <div style={{display:'flex',
                          flexDirection:'row', flexWrap:'wrap', justifyContent:'space-between'}}>


                  <div style={{width:'30%'}}> 

                  <div className="circle"
                    style={{
                      width: 90, height: 90, marginTop:10, marginBottom:10, backgroundColor: '#efeedf', 
                      
                      display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'
                    }}>
                    <div style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold', color:'#000' }}>{data.charts.whatSensitiveDataTypesAreStored[0].name}</div>
                    <div style={{ textAlign: 'center', fontSize: 14, color:'#000' }}>{data.charts.whatSensitiveDataTypesAreStored[0].value}</div>
                  </div>

                  </div>      


                  <div style={{width:'30%'}}> 
                  <div className="circle"
                     style={{
                      width: 90, height: 90,marginTop:10, marginBottom:10, backgroundColor: '#efeedf',                
                      display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'
                    }}>
                    <div style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold',color:'#000' }}>{data.charts.whatSensitiveDataTypesAreStored[1].name}</div>
                    <div style={{ textAlign: 'center', fontSize: 14, color:'#000' }}>{data.charts.whatSensitiveDataTypesAreStored[1].value}</div>
                  </div>

                  </div>


                  <div style={{width:'30%'}}> 
                  <div className="circle"
                     style={{
                      width: 90, height: 90,marginTop:10, marginBottom:10, backgroundColor: '#efeedf', 
                      
                      display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'
                    }}>
                    <div style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold',color:'#000' }}>{data.charts.whatSensitiveDataTypesAreStored[2].name}</div>
                    <div style={{ textAlign: 'center', fontSize: 14,color:'#000' }}>{data.charts.whatSensitiveDataTypesAreStored[2].value}</div>
                  </div>
                  </div>


                  <div style={{width:'30%'}}> 
                  <div className="circle"
                     style={{
                      width: 90, height: 90,marginTop:10, marginBottom:10, backgroundColor: '#efeedf', 
                      
                      display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'
                    }}>
                    <div style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold',color:'#000' }}>{data.charts.whatSensitiveDataTypesAreStored[3].name}</div>
                    <div style={{ textAlign: 'center', fontSize: 14, color:'#000'}}>{data.charts.whatSensitiveDataTypesAreStored[3].value}</div>
                  </div>
                  </div>



                  <div style={{width:'30%'}}> 
                  <div className="circle"
                     style={{
                      width: 90, height: 90,marginTop:10, marginBottom:10, backgroundColor: '#efeedf', 
                      
                      display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'
                    }}>
                    <div style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold',color:'#000' }}>{data.charts.whatSensitiveDataTypesAreStored[4].name}</div>
                    <div style={{ textAlign: 'center', fontSize: 14,color:'#000' }}>{data.charts.whatSensitiveDataTypesAreStored[4].value}</div>
                  </div>
                  </div>


                  <div style={{width:'30%'}}> 
                  <div className="circle"
                     style={{
                      width: 90, height: 90, marginTop:10, marginBottom:10,backgroundColor: '#efeedf', 
                      
                      display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'
                    }}>
                    <div style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold',color:'#000' }}>{data.charts.whatSensitiveDataTypesAreStored[5].name}</div>
                    <div style={{ textAlign: 'center', fontSize: 14,color:'#000' }}>{data.charts.whatSensitiveDataTypesAreStored[5].value}</div>
                  </div>
                  </div>


                  <div style={{width:'30%'}}> 
                  <div className="circle"
                     style={{
                      width: 90, height: 90, marginTop:10, marginBottom:10,backgroundColor: '#efeedf', 
                      
                      display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'
                    }}>
                    <div style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold',color:'#000' }}>{data.charts.whatSensitiveDataTypesAreStored[6].name}</div>
                    <div style={{ textAlign: 'center', fontSize: 14,color:'#000' }}>{data.charts.whatSensitiveDataTypesAreStored[6].value}</div>
                  </div>
                  </div>


                  <div style={{width:'30%'}}> 
                  <div className="circle"
                     style={{
                      width: 90, height: 90, marginTop:10, marginBottom:10, backgroundColor: '#efeedf', 
                      
                      display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'
                    }}>
                    <div style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold',color:'#000' }}>{data.charts.whatSensitiveDataTypesAreStored[7].name}</div>
                    <div style={{ textAlign: 'center', fontSize: 14,color:'#000' }}>{data.charts.whatSensitiveDataTypesAreStored[7].value}</div>
                  </div>
                  </div>


                  <div style={{width:'30%'}}> 
                  <div className="circle"
                     style={{
                      width: 90, height: 90, marginTop:10, marginBottom:10, backgroundColor: '#efeedf', 
                      
                      display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'
                    }}>
                    <div style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold',color:'#000' }}>{data.charts.whatSensitiveDataTypesAreStored[8].name}</div>
                    <div style={{ textAlign: 'center', fontSize: 14,color:'#000' }}>{data.charts.whatSensitiveDataTypesAreStored[8].value}</div>
                  </div>
                  </div>

                </div>      

                </div>             

                        
                    </div>
                
            
    );
};

export default WhatSensitiveDataTypesAreStored;
