import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';

import NISTCISFData from '../../data/tactical-view/NISTCISF.json'; 

const NISTCISF = (props) => {

    const [onLoading, setOnLoading] = useState(false)
 
    const data = props.data;

    const [screenWidth, setScreenWidth] = useState(1000);

  useEffect(() => {
      setScreenWidth (window.innerWidth - ((window.innerWidth * 25)/100))
  }, []);

    return (
      <div style={{
          width: '38%', marginLeft: 0, display: 'flex', flexDirection: 'column', height: 650,
          marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
      }}>


          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', marginTop: 10 }}>


          <div style={{ height: 60, }}>

                  <h6 style={{ color: '#fff', textAlign: 'left' }}>NIST CISF</h6>
                  <hr />

          </div>


                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', 
                  alignItems:'center',  height:520 }}>
                      

                  <Chart
          options={{
            chart: {
              toolbar: {
                show: false
              }
            },
            xaxis: {
              categories: data.charts.nistCISF.headers
            },
            yaxis: {
              tickAmount: 5,
              labels: {
                formatter: function (value) {
                  return value.toFixed(1);
                }
              }
            },
            fill: {
              opacity: 0.3
            },            
            markers: {
              size: 4
            },
            colors: ['#00bcd4', 'red', 'green', 'yellow', 'magenta'],
            legend: {
              position: 'bottom'
            }
          }}
          series={data.charts.nistCISF.data.map((item) => ({
            name: item.category,
            data: item.values
          }))}
          type="radar"
          height={400}
          width={screenWidth * 0.4}
        />
     

                  </div>
              </div>

          </div>


  );
};

export default NISTCISF;
