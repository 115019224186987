import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

//import data from '../../../datanew/Australia/data/opsView.json';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

const PhysicalVsVirtualItems = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const [screenWidth, setScreenWidth] = useState(1000);

    const data = props.data;

  useEffect(() => {
      setScreenWidth (window.innerWidth - ((window.innerWidth * 25)/100))
  }, []);

  const options = {
    chart: {
      type: 'donut',
    },
    labels: data.charts.physicalVsVirtualItems.map(item => item.name),
    legend: {
      position: 'bottom'
    }
  };

  const series = data.charts.physicalVsVirtualItems.map(item => item.value);
   
  let total = 0;
for (let i = 0; i < data.charts.physicalVsVirtualItems.length; i++) {
    total += data.charts.physicalVsVirtualItems[i].value;
}

  const data1 = {
    labels: data.charts.physicalVsVirtualItems.map(item => item.name),
    datasets: [
      {
        data: data.charts.physicalVsVirtualItems.map(item => item.value),
        backgroundColor: ['#5dbc53', '#d76358'],
        hoverBackgroundColor: ['#5dbc53', '#d76358']
      }
    ],
    text: ""
  };
  
  const options1 = {
    plugins: {
      legend: {
        display: false,
        position:'bottom',
        labels: {
            color: 'white' 
        },
        pointStyle: 'circle',
        usePointStyle: true
      }
    },
    cutout: 80
  };
  
  const plugins1 = [{
    id : 'pluginId',
    beforeDraw: function(chart) {
     var width = chart.width,
         height = chart.height,
         ctx = chart.ctx;
         ctx.restore();
         var fontSize = 1;
         ctx.font = fontSize + "em sans-serif";
         ctx.textBaseline = "top";
         ctx.textColor = "white";
         var text = `${total} Total` ,
         textX = Math.round((width - ctx.measureText(text).width) / 2),
         textY = height / 2;
         ctx.fillStyle = '#fff';
         ctx.fillText(text, textX, textY);
         ctx.save();
    } 
   }]



    return (
        <div style={{
            width: '49%', marginLeft: 0, display: 'flex', 
            flexDirection: 'column', height: 450,
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>
       


            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 10 }}>


            <div style={{ height: 60, }}>

                    <h6 style={{ color: '#fff', textAlign: 'left' }}>
                    Physical Vs Virtual Items</h6>
                    <hr />

            </div>


                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height:350 }}>
                         

                    <div style={{width:'30%'}}> 
                    <Doughnut 
          type="doughnut" 
          data={data1} 
          options={options1} 
          plugins={plugins1} 
          width={screenWidth * 0.3}
                height={screenWidth * 0.3} 
         />         

         </div>    





                    </div>
                </div>
            </div>


    );
};

export default PhysicalVsVirtualItems;