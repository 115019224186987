import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

import EmailSecurityCardsData from '../../../data/ops-view/SecurityStackEmailSecurity/EmailSecurityCards.json';


import { AiOutlineDeliveredProcedure } from "react-icons/ai";
import { MdBlock } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import { BsArrowReturnRight } from "react-icons/bs";
import { BsArrowRight } from "react-icons/bs";


const EmailSecurityCards = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const data = props.data.charts;

    const [parentWidth, setParentWidth] = useState(0);


    useEffect(() => {
        const updateParentWidth = () => {
            const parentDiv = document.getElementById('parentDiv');
            if (parentDiv) {
                const width = parentDiv.offsetWidth;
                setParentWidth(width * 0.95); // Assigning 90% width
            }
        };

        updateParentWidth();

        window.addEventListener('resize', updateParentWidth);

        return () => {
            window.removeEventListener('resize', updateParentWidth);
        };
    }, []);


    return (
        <div style={{
            width: '100%', marginLeft: 0, display: 'flex', flexDirection: 'column',
            marginTop: '0%',  borderRadius: 15
        }}>


           

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>


                <div style={{ width: '100%' }}>



                    <div style={{
                        display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap',
                        alignItems: 'center',
                    }}>


                        <div style={{
                            display: 'flex', flexDirection: 'column',
                            justifyContent: 'space-between', backgroundColor: '#fff', borderRadius: 5, padding: 20, width: '30%', height: 150, alignItems:'center'
                        }}>

                            <AiOutlineDeliveredProcedure size={40} style={{color:'#1e88e5'}}/>

                            <span style={{ color: '#000', fontSize: 25, textAlign: 'center', fontWeight: 'bold' }}>{data.emailSecurityOverview[0].value}</span>
                            <span style={{ color: '#000', fontSize: 18, textAlign: 'center',fontWeight: 'normal' }}>{data.emailSecurityOverview[0].name}</span>
                        </div>

                        <div style={{
                            display: 'flex', flexDirection: 'column',
                            justifyContent: 'space-between', backgroundColor: '#fff', borderRadius: 5, padding: 20, width: '30%', height: 150, alignItems:'center'
                        }}>

<MdBlock size={40} style={{color:'#388e3c'}}/>

                            <span style={{ color: '#000', fontSize: 25, textAlign: 'center', fontWeight: 'bold' }}>{data.emailSecurityOverview[1].value}</span>
                            <span style={{ color: '#000', fontSize: 18, textAlign: 'center', fontWeight: 'normal' }}>{data.emailSecurityOverview[1].name}</span>
                        </div>


                        <div style={{
                            display: 'flex', flexDirection: 'column',
                            justifyContent: 'space-between', backgroundColor: '#fff', borderRadius: 5, padding: 20, width: '30%', height: 150, alignItems:'center'
                        }}>


<FaCheckCircle size={40} style={{color:'#9c27b0'}}/>


                            <span style={{ color: '#000', fontSize: 25, textAlign: 'center', fontWeight: 'bold' }}>{data.emailSecurityOverview[2].value}</span>
                            <span style={{ color: '#000', fontSize: 18, textAlign: 'center',fontWeight: 'normal' }}>{data.emailSecurityOverview[2].name}</span>
                        </div>

                        <div style={{
                            display: 'flex', flexDirection: 'column',
                            justifyContent: 'space-between', backgroundColor: '#fff', borderRadius: 5, padding: 20, width: '30%', height: 150, alignItems:'center'
                            ,marginTop:20,
                        }}>

<MdBlock size={40} style={{color:'#607d8b'}}/>

                            <span style={{ color: '#000', fontSize: 25, textAlign: 'center', fontWeight: 'bold' }}>{data.emailSecurityOverview[3].value}</span>
                            <span style={{ color: '#000', fontSize: 18, textAlign: 'center',fontWeight: 'normal' }}>{data.emailSecurityOverview[3].name}</span>
                        </div>

                        <div style={{
                            display: 'flex', flexDirection: 'column',
                            justifyContent: 'space-between', backgroundColor: '#fff', borderRadius: 5, padding: 20, width: '30%', height: 150, alignItems:'center'
                            , marginTop:20,
                        }}>

<BsArrowReturnRight size={40} style={{color:'#009688'}}/>

                            <span style={{ color: '#000', fontSize: 25, textAlign: 'center', fontWeight: 'bold' }}>{data.emailSecurityOverview[4].value}</span>
                            <span style={{ color: '#000', fontSize: 18, textAlign: 'center',fontWeight: 'normal' }}>{data.emailSecurityOverview[4].name}</span>
                        </div>


                        <div style={{
                            display: 'flex', flexDirection: 'column',
                            justifyContent: 'space-between', backgroundColor: '#fff', borderRadius: 5, padding: 20, width: '30%', height: 150, alignItems:'center',
                             marginTop:20,
                        }}>

<BsArrowRight size={40} style={{color:'#ff9800'}}/>

                            <span style={{ color: '#000', fontSize: 25, textAlign: 'center', fontWeight: 'bold' }}>{data.emailSecurityOverview[5].value}</span>
                            <span style={{ color: '#000', fontSize: 18, textAlign: 'center',fontWeight: 'normal' }}>{data.emailSecurityOverview[5].name}</span>
                        </div>



                    </div>
                </div>
            </div>


        </div>
    );
};

export default EmailSecurityCards;