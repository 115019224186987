import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';

import RegulationsAndComplianceStatusChartData from '../../data/tactical-view/RegulationsAndComplianceStatusChart.json';
import WheelChart from "./WheelChart";

import { Graph } from 'react-d3-graph';


const RegulationsAndComplianceStatusChart = (props) => {


  const data = props.data;
  const { selectedRegion, selectedCity, selectedOffice } = props;
  console.log(selectedRegion, selectedCity, selectedCity);
  var wheelHtml = 'wheel.html';
  var smallerWheelHtml = 'wheel450.html';


  if (selectedRegion != 'select' && selectedCity === 'select' && selectedOffice === 'select') {

    switch (selectedRegion) {
      case 'Australia':
        wheelHtml = 'wheelAustralia.html';
        smallerWheelHtml = 'wheelAustraila450.html';
        break;
      case 'Europe':
        wheelHtml = 'wheelEurope.html';
        smallerWheelHtml = 'wheelEurope450.html';
        break;
      case 'India':
        wheelHtml = 'wheelIndia.html';
        smallerWheelHtml = 'wheelIndia450.html';
        break;
      case 'Singapore':
        wheelHtml = 'wheelSingapore.html';
        smallerWheelHtml = 'wheelSingapore450.html';
        break;
      case 'USA':
        wheelHtml = 'wheelUSA.html';
        smallerWheelHtml = 'wheelUSA450.html';
        break;
      default:
        wheelHtml = '';
        smallerWheelHtml = '';
    }
  }

  // Update HTML files based on selected region and selected city 
  if (selectedRegion != 'select' && selectedCity != 'select' && selectedOffice == 'select') {

    switch (selectedCity) {
      case 'Sydney':
        wheelHtml = 'wheelSydney.html';
        smallerWheelHtml = 'wheelSydney450.html';
        break;
      case 'Berlin':
        wheelHtml = 'wheelBerlin.html';
        smallerWheelHtml = 'wheelBerlin450.html';
        break;
      case 'Bangalore':
        wheelHtml = 'wheelBangalore.html';
        smallerWheelHtml = 'wheeLBangalore450.html';
        break;
      case 'Singapore City':
        wheelHtml = 'wheelSingaporeCity.html';
        smallerWheelHtml = 'wheelSingaporeCity450.html';
        break;
      case 'Los Angeles':
        wheelHtml = 'wheelLosAngeles.html';
        smallerWheelHtml = 'wheelLosAngeles450.html';
        break;
      default:
        wheelHtml = '';
        smallerWheelHtml = '';
        break;
    }
  }

  // Update HTML files based on selected city and selected office 
  if (selectedRegion != 'select' && selectedCity != 'select' && selectedOffice != 'select') {
    switch (selectedOffice) {
      case 'CBD':
        wheelHtml = 'wheelCBD.html';
        smallerWheelHtml = 'wheelCBD450.html';
        break;
      case 'Central Berlin':
        wheelHtml = 'wheelCentralBerlin.html';
        smallerWheelHtml = 'wheelCentralBerlin450.html';
        break;
      case 'Indiranagar':
        wheelHtml = 'wheelIndiranagar.html';
        smallerWheelHtml = 'wheelIndiranagar450.html';
        break;
      case 'Marina Bay':
        wheelHtml = 'wheelMarinaBay.html';
        smallerWheelHtml = 'wheelMarinaBay450.html';
        break;
      case 'Long Beach':
        wheelHtml = 'wheelLongBeach.html';
        smallerWheelHtml = 'wheelLongBeach450.html';
        break;
      default:
        wheelHtml = '';
        smallerWheelHtml = '';
        break;
    }
  }


  console.log('wheelHtml:',wheelHtml)
  console.log('smallerWheelHtml:',smallerWheelHtml)

  const chartData = {
    series: [{
      name: "Trend",
      data: [30, 40, 45, 50, 49, 60, 70]
    }],
    options: {
      chart: {
        height: 200,
        type: 'line',
        toolbar: {
          show: false
        },
        xaxis: {
          show: false
        }
      },
      markers: {
        size: 4,
        colors: '#000',
        strokeColors: '#000',
        strokeWidth: 2,
        hover: {
          size: 7,
        }
      },
      colors: ['#000'],
      dataLabels: {
        enabled: false
      },
      tooltip: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        colors: ['#000'],
      },

      yaxis: {
        show: false,
        labels: {
          style: {
            colors: '#000',
          }
        }
      },
      grid: {
        borderColor: '#666',
        strokeDashArray: 4,
        showLines: false,
        yaxis: {
          lines: {
            show: false
          }
        },
        xaxis: {
          lines: {
            show: false
          }
        }
      }
    }
  };

  return (
    <div style={{
      width: '100%', marginLeft: 0, display: 'flex', flexDirection: 'column',
      marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
    }}>


      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 10 }}>

        <div style={{ height: 60, }}>

          <h6 style={{ color: '#fff', textAlign: 'left' }}>Regulations and Compliance Status Chart</h6>
          <hr />

        </div>


        <div style={{
          display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
          alignItems: 'center'
        }}>


          <div style={{ width: '45%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>


            {data.charts.regulationsAndComplianceStatusChart.map((item, index) => (
              <div key={index} style={{
                width: '22%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
                backgroundColor: 'white', paddingBottom: 10, marginBottom: 20, margin: 5
              }}>

                <span style={{ backgroundColor: '#eff3f6', padding: 3, color: '#83c9da', textAlign: 'center', fontSize: 11, fontWeight: 'bold' }}>
                  {item.title}
                </span>

                <span style={{ color: '#000', fontWeight: 'bold', fontSize: 18, textAlign: 'center' }}>
                  {item.percentage}
                </span>

                <span style={{ color: '#000', fontWeight: 'normal', fontSize: 10, textAlign: 'center' }}>
                  {item.status}
                </span>

                <div style={{ marginTop: -20, display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%', }}>
                  <Chart
                    options={chartData.options}
                    series={item.series}
                    type="line"
                    height={100}
                    width={100}
                    style={{ marginLeft: 0 }}
                  />
                </div>

                <span style={{
                  color: '#000', fontWeight: 'normal', fontSize: 8, textAlign: 'center', marginTop: -30,
                  backgroundColor: '#fff', zIndex: 1000
                }}>
                  Compliance Trend
                </span>

              </div>
            ))}


          </div>

          <div className="biggerWheel" style={{
              width: '55%', height: 700, marginLeft: '5%', borderWidth: 0,
              flexDirection: 'row', justifyContent: 'center', 
            }}>

              
              <object type="text/html" data={global.baseURL + "/"+wheelHtml} width="100%" height="100%"
              style={{ alignSelf: 'center', borderWidth: 0, marginLeft:'5vw' }}>
            </object>
          
          
            </div>
              
            <div className="smallerWheel" style={{
              width: '55%', height: 700, marginLeft: '5%', borderWidth: 0,
              flexDirection: 'row', justifyContent: 'center'
            }}>
              <object type="text/html" data={global.baseURL + "/" + smallerWheelHtml} width="100%" height="100%"
                style={{ alignSelf: 'center', borderWidth: 0, }}>
              </object>
            </div>

        </div>
      </div>
    </div>
  );
};

export default RegulationsAndComplianceStatusChart;
