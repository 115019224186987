import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

import CloudProvidersOverviewData from '../../../data/ops-view/SecurityStackCloudSecurity/CloudProvidersOverview.json';

import awsLogo from '../../../../../assets/images/aws-logo.png'
import azureLogo from '../../../../../assets/images/azure-logo.png'
import gcpLogo from '../../../../../assets/images/gcp-logo.png'
import { MdError } from "react-icons/md";
import '../../../style.css'; 


const CloudProvidersOverview = (props) => {

  const [onLoading, setOnLoading] = useState(false)

  const data = props.data.charts.cloudProvidersOverview;

  const [parentWidth, setParentWidth] = useState(0);

  useEffect(() => {
    const updateParentWidth = () => {
      const parentDiv = document.getElementById('parentDiv');
      if (parentDiv) {
        const width = parentDiv.offsetWidth;
        setParentWidth(width * 0.95); // Assigning 90% width
      }
    };

    updateParentWidth();

    window.addEventListener('resize', updateParentWidth);

    return () => {
      window.removeEventListener('resize', updateParentWidth);
    };
  }, []);


  return (
    <div style={{
      width: '65%', marginLeft: 0, display: 'flex', flexDirection: 'column',
       height: 400, 
      marginTop: '2%', padding: 20, borderRadius: 15
    }}>


      {onLoading &&

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

          <ShimmerCircularImage size={500} />
          <ShimmerCircularImage size={500} />
        </div>
      }



      <div style={{ display: 'flex', flexDirection: 'row', 
        justifyContent: 'space-between', marginTop: 10 }}>


        <div style={{ width: '100%',  }}>

          <div style={{ display: 'flex', flexDirection: 'row', 
          justifyContent: 'space-between', alignItems: 'center' }}>


            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', backgroundColor:'#fff',
              padding:20, borderRadius:15,  width:'30%' }}>

              <img src={awsLogo} height="80" />

              <div style={{ display: 'flex', flexDirection: 'column', 
              justifyContent: 'space-between',alignItems:'center'  }}>

                <div style={{ display: 'flex', flexDirection: 'column',alignItems:'center', margin:20  }}>

                  <span style={{color:'#000',fontSize:20, fontWeight:'bold'}}>{data.aws.organizations}</span>
                  <span style={{color:'rgb(30, 136, 229)',fontSize:20}}>Organizations</span>

                </div>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems:'center', margin:20  }}>

                  <span style={{color:'#000',fontSize:20, fontWeight:'bold'}}>{data.aws.accounts}</span>
                  <span style={{color:'rgb(156, 39, 176)',fontSize:20}}>Accounts</span>

                </div>

              </div>

              <div style={{ display: 'flex', flexDirection: 'row',
               justifyContent: 'space-between', alignItems:'center' }}>

                <div style={{ display: 'flex', flexDirection: 'row', margin:10, alignItems:'center'  }}>

                  <MdError size={15} style={{color:'red'}}/>
                  <span style={{color:'#000', fontSize:14, marginLeft:3}}>{data.aws.errors}</span>

                </div>

                <div style={{ display: 'flex', flexDirection: 'row', margin:10, alignItems:'center'  }}>

                  <MdError size={15} style={{color:'#FFBF00'}}/>
                  <span style={{color:'#000',fontSize:14, marginLeft:3}}>{data.aws.warnings}</span>

                </div>


              </div>


            </div>



            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', backgroundColor:'#fff',
              padding:20, borderRadius:15,  width:'30%' }}>

              <img src={azureLogo} height="80" />

              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between',alignItems:'center'  }}>

                <div style={{ display: 'flex', flexDirection: 'column',alignItems:'center', margin:20  }}>

                  <span style={{color:'#000',fontSize:20, fontWeight:'bold'}}>{data.azure.organizations}</span>
                  <span style={{color:'rgb(30, 136, 229)',fontSize:20}}>Organizations</span>

                </div>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems:'center', margin:20  }}>

                  <span style={{color:'#000',fontSize:20, fontWeight:'bold'}}>{data.azure.accounts}</span>
                  <span style={{color:'rgb(156, 39, 176)',fontSize:20}}>Accounts</span>

                </div>

              </div>

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems:'center' }}>

                <div style={{ display: 'flex', flexDirection: 'row', margin:10, alignItems:'center'  }}>

                  <MdError size={15} style={{color:'red'}}/>
                  <span style={{color:'#000', fontSize:14, marginLeft:3}}>{data.azure.errors}</span>

                </div>

                <div style={{ display: 'flex', flexDirection: 'row', margin:10, alignItems:'center'  }}>

                  <MdError size={15} style={{color:'#FFBF00'}}/>
                  <span style={{color:'#000',fontSize:14, marginLeft:3}}>{data.azure.warnings}</span>

                </div>


              </div>


            </div>




            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', backgroundColor:'#fff',
              padding:20, borderRadius:15,  width:'30%' }}>

              <img src={gcpLogo} height="80" />

              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between',alignItems:'center'  }}>

                <div style={{ display: 'flex', flexDirection: 'column',alignItems:'center', margin:20  }}>

                  <span style={{color:'#000',fontSize:20, fontWeight:'bold'}}>{data.gcp.organizations}</span>
                  <span style={{color:'rgb(30, 136, 229)',fontSize:20}}>Organizations</span>

                </div>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems:'center', margin:20  }}>

                  <span style={{color:'#000',fontSize:20, fontWeight:'bold'}}>{data.gcp.accounts}</span>
                  <span style={{color:'rgb(156, 39, 176)',fontSize:20}}>Accounts</span>

                </div>

              </div>

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems:'center' }}>

                <div style={{ display: 'flex', flexDirection: 'row', margin:10, alignItems:'center'  }}>

                  <MdError size={15} style={{color:'red'}}/>
                  <span style={{color:'#000', fontSize:14, marginLeft:3}}>{data.gcp.errors}</span>

                </div>

                <div style={{ display: 'flex', flexDirection: 'row', margin:10, alignItems:'center'  }}>

                  <MdError size={15} style={{color:'#FFBF00'}}/>
                  <span style={{color:'#000',fontSize:14, marginLeft:3}}>{data.gcp.warnings}</span>

                </div>


              </div>


            </div>




          </div>
        </div>
      </div>


    </div>
  );
};

export default CloudProvidersOverview;