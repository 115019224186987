import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';
import social from '../../../../assets/images/social.png'
import crm from '../../../../assets/images/crm.png'
import news from '../../../../assets/images/news.png'


const TopReviewSources = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const data = props.data;

    return (
        <div style={{
            width: '49%', marginLeft: 0, display: 'flex', 
            flexDirection: 'column', height: 400,
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>          



            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 10 }}>


            <div style={{ height: 60,  }}>
                    <h4 style={{ color: '#fff', textAlign: 'left' }}>Top Review Sources</h4>
                    <hr />
            </div>


                    <div style={{ display: 'flex', flexDirection: 'row',
                     justifyContent: 'space-between', alignItems:'center', 
                        marginTop:20 , marginLeft:50, marginRight:50, height:270,}}>

                       
                         <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems:'center'}} >

                             <img src={social} style={{width:60, height:60}}/>
                             <span className="whiteSpan" style={{marginTop:20, fontWeight:'bold'}}>Social Media</span>
                             <span className="whiteSpan" style={{marginTop:10, fontSize:14}}>{data.charts.topReviewSources.SocialMedia} Reviews</span>


                         </div>

                         <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems:'center'}} >

                             <img src={crm} style={{width:60, height:60}}/>
                             <span className="whiteSpan" style={{marginTop:20, fontWeight:'bold'}}>CRM Tool</span>
                             <span className="whiteSpan" style={{marginTop:10, fontSize:14}}>{data.charts.topReviewSources.CRMTool} Reviews</span>


                         </div>

                         <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems:'center'}} >

                             <img src={news} style={{width:60, height:60}}/>
                             <span className="whiteSpan" style={{marginTop:20, fontWeight:'bold'}}>News</span>
                             <span className="whiteSpan" style={{marginTop:10,  fontSize:14}}>{data.charts.topReviewSources.News} Reviews</span>


                         </div>


                        
                    </div>



                </div>


            </div>


        
    );
};

export default TopReviewSources;
