import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

//import data from '../../../datanew/Australia/data/opsView.json';

const IncidentsStatus = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const [screenWidth, setScreenWidth] = useState(1000);

    const data = props.data;

  useEffect(() => {
      setScreenWidth (window.innerWidth - ((window.innerWidth * 25)/100))
  }, []);

  const theData = {
    categories: data.charts.incidentsStatus.categories,
    series: [
      { name: data.charts.incidentsStatus.categories[0], data: data.charts.incidentsStatus.data[0] },
      { name: data.charts.incidentsStatus.categories[1], data: data.charts.incidentsStatus.data[1] },
      { name: data.charts.incidentsStatus.categories[2], data: data.charts.incidentsStatus.data[2] },
      { name: data.charts.incidentsStatus.categories[3], data: data.charts.incidentsStatus.data[3] }
    ]
  };

  const options = {
    chart: {
      type: 'heatmap',
      height: 350
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.5,
        colorScale: {
          ranges: [{
            from: 0,
            to: 1,
            name: 'Low',
            color: '#FF5733'
          },
          {
            from: 1,
            to: 2,
            name: 'Medium',
            color: '#FFC300'
          },
          {
            from: 2,
            to: 3,
            name: 'High',
            color: '#DAF7A6'
          },
          {
            from: 3,
            to: 4,
            name: 'Very High',
            color: '#06D6A0'
          }]
        }
      }
    },
    dataLabels: {
        enabled: true, // Enable data labels
        style: {
          colors: ['#000'] // Label text color
        },
        formatter: function (val) {
          return val;
        }
      },
    xaxis: {
      categories: theData.categories
    },
    title: {
      text: '',
      align: 'center'
    }
  };

   

    return (
        <div style={{
            width: '49%', marginLeft: 0, 
            display: 'flex', flexDirection: 'column', height: 500,
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 10 }}>


            <div style={{ height: 60, }}>


                    <h6 style={{ color: '#fff', textAlign: 'left' }}>
                    Incidents Status</h6>
                    <hr />

              </div>


                    <div style={{ display: 'flex', flexDirection: 'row', 
                        justifyContent: 'space-between', alignItems: 'center', height:410 }} >
                         
                    <ReactApexChart
        options={options}
        series={theData.series}
        type="heatmap"
        height={400}
        width={screenWidth * 0.45}
      />


                    </div>
                </div>
            </div>


    );
};

export default IncidentsStatus;