import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";



const ControlImplementationAverages = () => {

    const [onLoading, setOnLoading] = useState(false)
    const [screenWidth, setScreenWidth] = useState(1000);

    useEffect(() => {
        setScreenWidth (window.innerWidth - ((window.innerWidth * 25)/100))
    }, []);

    const [parentWidth, setParentWidth] = useState(0);

    useEffect(() => {
      const updateParentWidth = () => {
        const parentDiv = document.getElementById('parentDiv');
        if (parentDiv) {
          const width = parentDiv.offsetWidth;
          setParentWidth(width * 0.95); // Assigning 90% width
        }
      };
  
      updateParentWidth();
  
      window.addEventListener('resize', updateParentWidth);
  
      return () => {
        window.removeEventListener('resize', updateParentWidth);
      };
    }, []);

    const options = {
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: ['CIS 01', 'CIS 02', 'CIS 03', 'CIS 04', 'CIS 05', 'CIS 06', 'CIS 07', 'CIS 08', 'CIS 09', 'CIS 10', 'CIS 11', 'CIS 12', 'CIS 13', 'CIS 14', 'CIS 15', 'CIS 16', 'CIS 17', 'CIS 18'],
        },
        yaxis: {
          min: 0,
          max: 100,
          tickAmount: 5
        },
        series: [{
          name: 'Series 1',
          data: [40, 60, 80, 70, 50, 30, 20, 45, 65, 85, 75, 55, 35, 25, 15, 70, 90, 95]
        }],
        legend: {
          offsetY: -10,
        }
      };
   

    return (
        <div style={{
            width: '100%', marginLeft: 0, display: 'flex', flexDirection: 'column', 
            height: 450,
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>



            {onLoading &&

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

                    <ShimmerCircularImage size={500} />
                    <ShimmerCircularImage size={500} />
                </div>
            }


            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>


                <div style={{ width: '100%' }}>

                    <h6 style={{ color: '#fff', textAlign: 'left' }}>
                        Control Implementation Averages</h6>
                    <hr />


                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} id="parentDiv">
                         

                    <ReactApexChart options={options} 
                    series={options.series} type="bar" height={350} width={screenWidth } />


                    </div>
                </div>
            </div>


        </div>
    );
};

export default ControlImplementationAverages;