import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

import FindingsOverviewData from '../../../data/ops-view/SecurityStackAppSecurity/FindingsOverview.json';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const FindingsOverview = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const data = props.data.charts;

    const data1 = {
      labels: [data.findingsOverview[0].findingType, 
      data.findingsOverview[1].findingType, 
      data.findingsOverview[2].findingType, 
      data.findingsOverview[3].findingType,
      data.findingsOverview[4].findingType,],
      datasets: [
        {
          data: [data.findingsOverview[0].count, 
          data.findingsOverview[1].count,
          data.findingsOverview[2].count, 
          data.findingsOverview[3].count,
          data.findingsOverview[4].count],
          backgroundColor: ['#5dbc53', '#d76358'],
          hoverBackgroundColor: ['#5dbc53', '#d76358']
        }
      ],
      text: ""
    };
    
    const options1 = {
      plugins: {
        legend: {
          display: false
        }
      },
      cutout: 90
    };
    
    const plugins1 = [{
      id : 'pluginId',
      beforeDraw: function(chart) {
       var width = chart.width,
           height = chart.height,
           ctx = chart.ctx;
           ctx.restore();
           var fontSize = 1;
           ctx.font = fontSize + "em sans-serif";
           ctx.textBaseline = "top";
           ctx.textColor = "white";
           var text = `${(data.findingsOverview[0].count +
            data.findingsOverview[1].count + 
            data.findingsOverview[2].count +
            data.findingsOverview[2].count +
            data.findingsOverview[3].count)} Findings` ,
           textX = Math.round((width - ctx.measureText(text).width) / 2),
           textY = height / 2;
           ctx.fillStyle = '#fff';
           ctx.fillText(text, textX, textY);
           ctx.save();
      } 
     }]

    const [parentWidth, setParentWidth] = useState(0);
    const [screenWidth, setScreenWidth] = useState(1000);

  useEffect(() => {
      setScreenWidth (window.innerWidth - ((window.innerWidth * 25)/100))
  }, []);

    useEffect(() => {
      const updateParentWidth = () => {
        const parentDiv = document.getElementById('parentDiv');
        if (parentDiv) {
          const width = parentDiv.offsetWidth;
          setParentWidth(width * 0.95); // Assigning 90% width
        }
      };
  
      updateParentWidth();
  
      window.addEventListener('resize', updateParentWidth);
  
      return () => {
        window.removeEventListener('resize', updateParentWidth);
      };
    }, []);
   

    return (
        <div style={{
            width: '49%', marginLeft: 0, display: 'flex',
             flexDirection: 'column', 
            marginTop: '2%', 
            background: 'linear-gradient(135deg, #161c2a, #1a2130)', 
            padding: 20, borderRadius: 15, height:500
        }}>


            <div style={{ display: 'flex', flexDirection: 'column', 
            justifyContent: 'space-between', marginTop: 10 }}>

<div style={{ height: 60, }}>


                    <h6 style={{ color: '#fff', textAlign: 'left' }}>Findings Overview</h6>
                    <hr />

  </div>

                    <div style={{ display: 'flex', flexDirection: 'row',
                     justifyContent: 'space-between', alignItems: 'center', height:390}}>

                       <div style={{width:'40%'}}> 
                                
                    <Doughnut 
          type="doughnut" 
          data={data1} 
          options={options1} 
          plugins={plugins1} 
          width={screenWidth * 0.3}
                height={screenWidth * 0.3} 
         />                    

</div>


<div style={{width:'40%'}}> 

<table style={{width:'100%'}}>

  <thead>
    <th style={{padding:5,color:'#fff'}}>Finding Type</th>
    <th style={{padding:5,color:'#fff'}}>Count</th>
  </thead>

  <tbody>
     <tr>

      <td>{data.findingsOverview[0].findingType}</td>
      <td>{data.findingsOverview[0].count}</td>
        
     </tr>
     <tr>

      <td>{data.findingsOverview[1].findingType}</td>
      <td>{data.findingsOverview[1].count}</td>
        
     </tr>

     <tr>

      <td>{data.findingsOverview[2].findingType}</td>
      <td>{data.findingsOverview[2].count}</td>
        
     </tr>

     <tr>

      <td>{data.findingsOverview[3].findingType}</td>
      <td>{data.findingsOverview[3].count}</td>
        
     </tr>

     <tr>

      <td>{data.findingsOverview[4].findingType}</td>
      <td>{data.findingsOverview[4].count}</td>
        
     </tr>
  </tbody>
    
</table>

</div>




                    


                    </div>
                </div>
            </div>


    );
};

export default FindingsOverview;