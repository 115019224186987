import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';

import CyberSecurityProtectionLevelData from '../../data/tactical-view/CyberSecurityProtectionLevel.json'; 

const CyberSecurityProtectionLevel = (props) => {

 
    const data = props.data;

    const [screenWidth, setScreenWidth] = useState(1000);

  useEffect(() => {
      setScreenWidth (window.innerWidth - ((window.innerWidth * 25)/100))
  }, []);

    return (
      <div style={{
          width: '60%', marginLeft: 0, display: 'flex', flexDirection: 'column', height: 650,
          marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
      }}>

          


          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', marginTop: 10 }}>


          <div style={{ height: 60, }}>
                  <h6 style={{ color: '#fff', textAlign: 'left' }}>Cyber Security Protection Level</h6>
                  <hr />
          </div>


                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems:'center', height:520  }}>
                      


                  <Chart
          options={{
            chart: {
              type: 'bar'
            },
            xaxis: {
              categories: data.charts.cyberSecurityProtectionLevel.categories,
              labels: {
                style: {
                  fontSize: '14px'
                }
              }
            },
            yaxis: {
              min: 0,
              max: 10,
              tickAmount: 5,
              labels: {
                style: {
                  fontSize: '14px'
                }
              }
            },
            colors: data.charts.cyberSecurityProtectionLevel.colors,
            legend: {
              position: 'top',
              horizontalAlign: 'center',
              fontSize: '14px'
            },
            plotOptions: {
              bar: {
                horizontal: false
              }
            },
            dataLabels: {
              enabled: false
            }
          }}
          series={data.charts.cyberSecurityProtectionLevel.series}
          type="bar"
          width={screenWidth * 0.4}
        />
     

                  </div>
              </div>

          </div>


     
  );
};

export default CyberSecurityProtectionLevel;
