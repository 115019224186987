import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

//import data from '../../../datanew/Australia/data/opsView.json';

const NewIncidentForecast = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const [screenWidth, setScreenWidth] = useState(1000);

    const data = props.data;

  useEffect(() => {
      setScreenWidth (window.innerWidth - ((window.innerWidth * 25)/100))
  }, []);

  
  const options = {
    chart: {
      type: 'line',
      height: 350
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      width: 2
    },
    xaxis: {
      categories: data.charts.newIncidentsForecast.categories
    },
    yaxis: {
      title: {
        text: 'New Incidents'
      }
    },
    legend: {
      position: 'top'
    },
    title: {
      text: '',
      align: 'center'
    }
  };
   

    return (
        <div style={{
            width: '49%', marginLeft: 0, display: 'flex',
             flexDirection: 'column', height: 500,
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>




            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 10 }}>


            <div style={{ height: 60, }}>

                    <h6 style={{ color: '#fff', textAlign: 'left' }}>
                    New Incident Forecast</h6>
                    <hr />
          </div>



                    <div style={{ display: 'flex', flexDirection: 'row',
                     justifyContent: 'center', alignItems: 'center', height:390 }}>
                         
                    <ReactApexChart
        options={options}
        series={data.charts.newIncidentsForecast.series}
        type="line"
        height={350}
        width={screenWidth * 0.45}
      />


                    </div>
                </div>
            </div>


        
    );
};

export default NewIncidentForecast;