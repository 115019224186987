import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";


const ItemsByStatus = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const [screenWidth, setScreenWidth] = useState(1000);

    const data = props.data;

  useEffect(() => {
      setScreenWidth (window.innerWidth - ((window.innerWidth * 25)/100))
  }, []);


  const options = {
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    xaxis: {
      categories: data.charts.itemsByStatus.categories,
    },
    legend: {
      position: 'top',
    },
    fill: {
      opacity: 1
    }
  };

  const series = [
    {
      name: 'Item Count',
      data: data.charts.itemsByStatus.series[0].data
    }
  ];
   

    return (
        <div style={{
            width: '49%', marginLeft: 0, display: 'flex', flexDirection: 'column', 
            height: 450,
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>          


            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 10 }}>


            <div style={{ height: 60, }}>

                    <h6 style={{ color: '#fff', textAlign: 'left' }}>
                    Items By Status</h6>
                    <hr />

            </div>


            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height:350 }} >
                         
                    <ReactApexChart options={options} series={series} 
                       type="bar" height={350} width={screenWidth * 0.5}/>


                    </div>
                </div>
            </div>


        
    );
};

export default ItemsByStatus;