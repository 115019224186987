import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

import CloudSecurityStrategyData from '../../data/tactical-view/CloudSecurityStrategy.json';


const CloudSecurityStrategy = (props) => {

  const [onLoading, setOnLoading] = useState(false)

  const data = props.data.charts.cloudSecurityStrategyQuarterlyRoadmap;

  const [parentWidth, setParentWidth] = useState(0);

  useEffect(() => {
    const updateParentWidth = () => {
      const parentDiv = document.getElementById('parentDiv');
      if (parentDiv) {
        const width = parentDiv.offsetWidth;
        setParentWidth(width * 0.95); // Assigning 90% width
      }
    };

    updateParentWidth();

    window.addEventListener('resize', updateParentWidth);

    return () => {
      window.removeEventListener('resize', updateParentWidth);
    };
  }, []);


  const convertedData = convertDateStringsToDates(data);

  const series = convertedData;

  function convertDateStringsToDates(data) {
    return data.map(entry => ({
      name: entry.name,
      data: entry.data.map(item => ({
        x: item.x,
        y: item.y.map(dateString => new Date(dateString).getTime()),
        label: {
          text: item.label.text
        }
      }))
    }));
  }


  const options = {
    chart: {
      height: 450,
      type: 'rangeBar',
      width: parentWidth
    },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true,
        barHeight: '100%',
        dataLabels: {
          position: 'center',
          hideOverflowingLabels: false,
        }
      },

    },
    xaxis: {
      type: 'datetime'
    },
    stroke: {
      width: 1
    },
    fill: {
      type: 'solid',
      opacity: 0.6
    },
    legend: {
      show: true
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        if (val.label && val.label.text) {
          return val.label.text;
        } else {
          return '';
        }
      },
      style: {
        colors: ['#f3f4f5', '#fff']
      }
    },

  };

  return (
    <div style={{
      width: '100%', marginLeft: 0, display: 'flex', flexDirection: 'column',
      marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
    }}>



      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 0 }}>

        <div style={{ height: 60, }}>


          <h6 style={{ color: '#fff', textAlign: 'left' }}>Cloud Security Strategy Quartely Roadmap for Data Protection</h6>
          <hr />

        </div>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} id="parentDiv">

          <ReactApexChart options={options}
            series={series} type="rangeBar"
            height={450} width={parentWidth} />
        </div>
      </div>



    </div>
  );
};

export default CloudSecurityStrategy;
