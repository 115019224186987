import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';

import ProjectsCardsData from '../../data/tactical-view/ProjectsCards.json'; 
import { FaArrowAltCircleUp } from "react-icons/fa";
import { FaArrowAltCircleDown } from "react-icons/fa";
import { AiFillProject } from "react-icons/ai";

import ProjectSummaryData from '../../data/tactical-view/ProjectSummary.json'; 
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';



const ProjectsSummary = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const data = props.data;   

    function getStatusColor(status) {
        return data.projects.find(project => project.status === status)?.statusColor || '#000';
    }
      
    function getProgressColor(status) {
        return data.projects.find(project => project.status === status)?.progressColor || '#000';
    }

    return (
        <div style={{
            width: '49%', marginLeft: 0, display: 'flex', flexDirection: 'column', height: 500,
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>


            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', marginTop: 10 }}>


            <div style={{ height: 60,  }}>


                     <h6 style={{ color: '#fff', textAlign: 'left' }}>Project Summary</h6>
                


                     <hr />
</div>



<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', 
              alignItems: 'center', width:'100%', height:410 }}>


                     <table className="projectSummary" style={{width:'100%'}}>
      <thead style={{ backgroundColor: '#f5f5f5', fontWeight: 'bold' }}>
        <tr>
          <th style={{padding:10, color:'#fff'}}>Project Name</th>
          <th style={{padding:10, color:'#fff'}}>Project Manager</th>
          <th style={{padding:10, color:'#fff'}}>Due Date</th>
          <th style={{padding:10, color:'#fff'}}>Status</th>
          <th style={{padding:10, color:'#fff'}}>Progress</th>
        </tr>
      </thead>
      <tbody>
        {data.charts.projectsSummary.map((project, index) => (
          <tr key={index}>
            <td>{project.projectName}</td>
            <td>{project.projectManager}</td>
            <td>{project.dueDate}</td>
            <td>
              <span
                style={{
                  backgroundColor: project.statusColor,
                  color: '#fff',
                  padding: '3px 8px',
                  borderRadius: '12px'
                }}
              >
                {project.status}
              </span>
            </td>
            <td>
                <div style={{ width: 50, height: 50 }}>

                    <CircularProgressbar 
                        value={project.progress} 
                        text={`${project.progress}%`}
                        styles={{
                           
                            path: {
                              stroke: project.progressColor,
                              strokeLinecap: 'butt',
                              transition: 'stroke-dashoffset 0.5s ease 0s',
                              transform: 'rotate(0.25turn)',
                              transformOrigin: 'center center',
                            },
                            // Customize the circle behind the path, i.e. the "total progress"
                            trail: {
                              stroke: '#d6d6d6',
                              strokeLinecap: 'butt',
                              transform: 'rotate(0.25turn)',
                              transformOrigin: 'center center',
                            },
                            // Customize the text
                            text: {
                              fill: '#fff',
                              fontSize: '22px',
                              fontWeight:'bold'
                            },
                            // Customize background - only used when the `background` prop is true
                            background: {
                              fill: '#3e98c7',
                            },
                          }} 
                        />
                </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>

    </div>



                </div>


            </div>


    );
};

export default ProjectsSummary;
