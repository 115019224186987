import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';

import TotalScoresData from '../../data/tactical-view/TotalScores.json'; 

const TotalScores = (props) => {

    const [onLoading, setOnLoading] = useState(false)
 
    const data = props.data;

    return (
      <div style={{
          width: '100%', marginLeft: 0, display: 'flex', flexDirection: 'column',
          marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
      }}>



          {onLoading &&

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

                  <ShimmerCircularImage size={500} />
                  <ShimmerCircularImage size={500} />
              </div>
          }



          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>


              <div style={{ width: '100%' }}>

                  <h6 style={{ color: '#fff', textAlign: 'left' }}>Total Scores</h6>
                  <hr />


                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems:'center',  }}>
                      

                  <table className="complianceChecks">
      <thead>
        <tr>
          <th></th>
          {data.charts.totalScores.headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.charts.totalScores.data.map((row, index) => (
          <tr key={index}>
            <td>{row.category}</td>
            {row.values.map((value, idx) => (
              <td key={idx}>
                
                  {value}
                
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
     

                  </div>
              </div>

          </div>


      </div>
  );
};

export default TotalScores;
