import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';
import '../../style.css';
import riskLevelChartData from '../../data/strategic-view/riskLevelChart.json';


const RiskLevel = (props) => {

    const [onLoading, setOnLoading] = useState(false)
    const [screenWidth, setScreenWidth] = useState(1000);

    const data = props.data;

    useEffect(() => {
        setScreenWidth(window.innerWidth - ((window.innerWidth * 25) / 100))
    }, []);

    return (
        <div style={{
            width: '49%', marginLeft: 0,
            display: 'flex', flexDirection: 'column', height: 550,
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', marginTop: 10 }}>



                <div style={{ height: 100 }}>


                    <h4 style={{ color: '#fff', textAlign: 'left' }}>Risk Level</h4>
                    <h6 style={{ color: '#fff', textAlign: 'left' }}>Historical Finding Severity</h6>
                    <hr />

                </div>


                <div style={{
                    height: 420, display: 'flex',
                    flexDirection: 'row', alignItems: 'center', justifyContent: 'center'
                }}>


                    <Chart
                        options={data.charts.riskLevel.options}
                        series={data.charts.riskLevel.data.series}
                        type="donut"
                        width={screenWidth * 0.3}
                    />


                </div>



            </div>
        </div>

    );
};

export default RiskLevel;
