import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

//import data from '../../../datanew/Australia/data/opsView.json';
import { IoTriangle } from "react-icons/io5";

const IncidentCards = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const [screenWidth, setScreenWidth] = useState(1000);

    const data = props.data;

  useEffect(() => {
      setScreenWidth (window.innerWidth - ((window.innerWidth * 25)/100))
  }, []);

   

    return (
        <div style={{
            width: '67%', marginLeft: 0, display: 'flex', flexDirection: 'column', height: 300,
            marginTop: '2%',  padding: 20, borderRadius: 15
        }}>



            {onLoading &&

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

                    <ShimmerCircularImage size={500} />
                    <ShimmerCircularImage size={500} />
                </div>
            }


            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>


                <div style={{ width: '100%' }}>                   

                    <div style={{ display: 'flex', flexDirection: 'row', 
                    justifyContent: 'space-between', alignItems: 'center' }} id="parentDiv">
                         

                         <div style={{
                            display:'flex',
                            flexDirection:'column',
                            justifyContent:'space-between',
                            alignItems:'center',
                            backgroundColor:'#fff',
                            padding:20,
                            borderRadius:10,
                            width:'32%',
                            height:200
                         }}>


                            <div style={{display:'flex', flexDirection:'row',
                             alignItems:'center'}}>
                                <IoTriangle size={20} 
                                style={{color:data.charts.incidentCards[0].newIncidentsTrend == 'increase'?'green':'red'}}/>
                                <span style={{color:data.charts.incidentCards[0].newIncidentsTrend == 'increase'?'green':'red',
                                 marginLeft:10, fontWeight:'bold'}}>
                                    {data.charts.incidentCards[0].newIncidentsTrendPercent}%
                                </span>
                            </div>

                            <span style={{color:'#000', fontWeight:'bold'}}>New Incidents</span>

                            <div style={{display:'flex', flexDirection:'row', 
                            justifyContent:'center'}}>

                                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>

                                        <span style={{color:'#000', fontSize:12, fontWeight:'bold',
                                        textAlign:'right'}}>
                                            {data.charts.incidentCards[0].newIncidentsCurrentMonth}</span>
                                        <span style={{color:'#000', fontSize:12, fontWeight:'bold',
                                        textAlign:'right'}}>Current Month</span>
                                    </div>

                                    <div style={{height:'100%', 
                                    marginLeft:10, marginRight:10,
                                     width:2, backgroundColor:'#555'}}>
                                        

                                    </div>

                                    <div style={{display:'flex', 
                                    flexDirection:'column',
                                     justifyContent:'center'}}>

                                        <span style={{color:'#000', fontSize:12, fontWeight:'bold'}}>
                                        {data.charts.incidentCards[0].newIncidentsCurrentMonth}</span>
                                        <span style={{color:'#000', fontSize:12,fontWeight:'bold'}}>
                                            Last Month</span>

                                    </div>
                            </div>    

                         </div>

                         
                         <div style={{
                            display:'flex',
                            flexDirection:'column',
                            justifyContent:'space-between',
                            alignItems:'center',
                            backgroundColor:'#fff',
                            padding:20,
                            borderRadius:10,
                            width:'32%',
                            height:200
                         }}>


                            <div style={{display:'flex', flexDirection:'row',
                             alignItems:'center'}}>
                                <IoTriangle size={20} 
                                style={{color:data.charts.incidentCards[1].closedIncidentsTrend == 'increase'?'green':'red'}}/>
                                <span style={{color:data.charts.incidentCards[1].closedIncidentsTrend == 'increase'?'green':'red',
                                 marginLeft:10, fontWeight:'bold'}}>
                                    {data.charts.incidentCards[1].closedIncidentsTrendPercent}%
                                </span>
                            </div>

                            <span style={{color:'#000', fontWeight:'bold'}}>Closed Incidents</span>

                            <div style={{display:'flex', flexDirection:'row', 
                            justifyContent:'center'}}>

                                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>

                                        <span style={{color:'#000', fontSize:12, fontWeight:'bold',
                                        textAlign:'right'}}>
                                            {data.charts.incidentCards[1].closedIncidentsCurrentMonth}</span>
                                        <span style={{color:'#000', fontSize:12, fontWeight:'bold',
                                        textAlign:'right'}}>Current Month</span>
                                    </div>

                                    <div style={{height:'100%', 
                                    marginLeft:10, marginRight:10,
                                     width:2, backgroundColor:'#555'}}>
                                        

                                    </div>

                                    <div style={{display:'flex', 
                                    flexDirection:'column',
                                     justifyContent:'center'}}>

                                        <span style={{color:'#000', fontSize:12, fontWeight:'bold'}}>
                                        {data.charts.incidentCards[1].closedIncidentsCurrentMonth}</span>
                                        <span style={{color:'#000', fontSize:12,fontWeight:'bold'}}>
                                            Last Month</span>

                                    </div>
                            </div>    

                         </div>


                         
                         <div style={{
                            display:'flex',
                            flexDirection:'column',
                            justifyContent:'space-between',
                            alignItems:'center',
                            backgroundColor:'#fff',
                            padding:20,
                            borderRadius:10,
                            width:'32%',
                            height:200
                         }}>


                            <div style={{display:'flex', flexDirection:'row',
                             alignItems:'center'}}>
                                <IoTriangle size={20} 
                                style={{color:data.charts.incidentCards[2].resolvedIncidentsTrend == 'increase'?'green':'red'}}/>
                                <span style={{color:data.charts.incidentCards[2].resolvedIncidentsTrend == 'increase'?'green':'red',
                                 marginLeft:10, fontWeight:'bold'}}>
                                    {data.charts.incidentCards[2].resolvedIncidentsTrendPercent}%
                                </span>
                            </div>

                            <span style={{color:'#000', fontWeight:'bold'}}>Resolved Incidents</span>

                            <div style={{display:'flex', flexDirection:'row', 
                            justifyContent:'center'}}>

                                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>

                                        <span style={{color:'#000', fontSize:12, fontWeight:'bold',
                                        textAlign:'right'}}>
                                            {data.charts.incidentCards[2].resolvedIncidentsCurrentMonth}</span>
                                        <span style={{color:'#000', fontSize:12, fontWeight:'bold',
                                        textAlign:'right'}}>Current Month</span>
                                    </div>

                                    <div style={{height:'100%', 
                                    marginLeft:10, marginRight:10,
                                     width:2, backgroundColor:'#555'}}>
                                        

                                    </div>

                                    <div style={{display:'flex', 
                                    flexDirection:'column',
                                     justifyContent:'center'}}>

                                        <span style={{color:'#000', fontSize:12, fontWeight:'bold'}}>
                                        {data.charts.incidentCards[2].resolvedIncidentsCurrentMonth}</span>
                                        <span style={{color:'#000', fontSize:12,fontWeight:'bold'}}>
                                            Last Month</span>

                                    </div>
                            </div>    

                         </div>


                    </div>
                </div>
            </div>


        </div>
    );
};

export default IncidentCards;