import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';

import ComplianceAndAuditsData from '../../data/tactical-view/ComplianceAndAudits.json';
import { FaCheckCircle } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";

const ComplianceAndAudits = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const data = props.data.charts.complianceAndAudits;

    const getColor = (count) => {
        if (count < 5) return 'green';
        if (count >= 5 && count <= 10) return 'orange';
        return 'red';
    };

    return (
        <div style={{
            width: '49%', marginLeft: 0, display: 'flex',
            flexDirection: 'column', height: 520,
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>            


            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>


                <div style={{ width: '100%' }}>

                    <div style={{
                        display: 'flex', flexDirection: 'row',
                        justifyContent: 'space-between', alignItems:'center'
                    }}>
                        <h6 style={{ color: '#fff', textAlign: 'left' }}>
                            Compliance and Audits
                        </h6>

                        <span style={{
                            padding: 10, backgroundColor: '#fff',
                            color: '#000', fontWeight: 'bold', borderRadius: 5
                        }}>{data.status}</span>
                    </div>

                    <hr />


                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>


                        <table style={{ width: '100%' }}>
                            <tbody>
                               
                                    <tr style={{ margin: 5, borderBottom:'1px solid #555' }}>
                                        <td style={{ width: '1%', margin: 5, borderRight: `3px solid ${getColor(data.upcomingAudits)}` }}></td>
                                        <td style={{ width: '48%', padding: '5px', margin: 5, }}>Upcoming Audits</td>
                                        <td style={{ width: '48%', padding: '5px', margin: 5, textAlign: 'center' }}>
                                            <div style={{
                                                display: 'inline-block',
                                                padding: '5px 10px',
                                                borderRadius: '10px',
                                                borderWidth: '2px',
                                                borderStyle: 'solid',
                                                borderColor: getColor(data.upcomingAudits)
                                            }}>
                                                {data.upcomingAudits}
                                            </div>
                                        </td>
                                    </tr>

                                    <tr style={{ margin: 5, borderBottom:'1px solid #555' }}>
                                        <td style={{ width: '1%', margin: 5, borderRight: `3px solid ${getColor(data.completedAudits)}` }}></td>
                                        <td style={{ width: '48%', padding: '5px', margin: 5, }}>Completed Audits</td>
                                        <td style={{ width: '48%', padding: '5px', margin: 5, textAlign: 'center' }}>
                                            <div style={{
                                                display: 'inline-block',
                                                padding: '5px 10px',
                                                borderRadius: '10px',
                                                borderWidth: '2px',
                                                borderStyle: 'solid',
                                                borderColor: getColor(data.completedAudits)
                                            }}>
                                                {data.completedAudits}
                                            </div>
                                        </td>
                                    </tr>



                                    <tr style={{ margin: 5, borderBottom:'1px solid #555' }}>
                                        <td style={{ width: '1%', margin: 5, borderRight: `3px solid ${getColor(data.auditComplianceInPercent)}` }}></td>
                                        <td style={{ width: '48%', padding: '5px', margin: 5, }}>Audit Compliance (in %)</td>
                                        <td style={{ width: '48%', padding: '5px', margin: 5, textAlign: 'center' }}>
                                            <div style={{
                                                display: 'inline-block',
                                                padding: '5px 10px',
                                                borderRadius: '10px',
                                                borderWidth: '2px',
                                                borderStyle: 'solid',
                                                borderColor: getColor(data.auditComplianceInPercent)
                                            }}>
                                                {data.auditComplianceInPercent}
                                            </div>
                                        </td>
                                    </tr>



                                    <tr style={{ margin: 5, borderBottom:'1px solid #555' }}>
                                        <td style={{ width: '1%', margin: 5, borderRight: `3px solid ${getColor(data.complianceInvestigationPercent)}` }}></td>
                                        <td style={{ width: '48%', padding: '5px', margin: 5, }}>Compliance Investigation (in %)</td>
                                        <td style={{ width: '48%', padding: '5px', margin: 5, textAlign: 'center' }}>
                                            <div style={{
                                                display: 'inline-block',
                                                padding: '5px 10px',
                                                borderRadius: '10px',
                                                borderWidth: '2px',
                                                borderStyle: 'solid',
                                                borderColor: getColor(data.complianceInvestigationPercent)
                                            }}>
                                                {data.complianceInvestigationPercent}
                                            </div>
                                        </td>
                                    </tr>
                               
                            </tbody>
                        </table>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>


<table style={{ width: '100%', marginTop:20 }}>

  <tr>
    <td style={{background:'#007bff', color:'#fff', textAlign:'center', fontWeight:'bold'}}>
        Compliance Investigation</td>
    <td style={{background:'#90caf9', color:'#000', textAlign:'center', fontWeight:'bold'}}>
        Training Compliance</td>
  </tr>

  <tr>
    <td style={{textAlign:'center'}}>
      <FaCheckCircle size={40} style={{color:'#FFC107'}}/>
    </td>
    <td style={{textAlign:'center'}}><IoMdCloseCircle size={40} style={{color:'red'}}/></td>
  </tr>

  <tr>
    <td style={{textAlign:'center'}}>{data.complianceInvestigation}</td>
    <td style={{textAlign:'center'}}>{data.trainingCompliance}</td>
  </tr>

</table>




</div>

                </div>
            </div>
        </div>
    );
};

export default ComplianceAndAudits;
