import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';

import PendingCasesData from '../../data/tactical-view/PendingCases.json'; 


const PendingCases= (props) => {

    const [onLoading, setOnLoading] = useState(false)  

    const data = props.data.charts.pendingCasesWithinSLA;

    return (
      <div style={{
          width: '49%', marginLeft: 0, display: 'flex', flexDirection: 'column', height: 420,
          marginTop: '2%',background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
      }}>




          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 10 }}>


          <div style={{ height: 60, }}>

                  <h6 style={{ color: '#fff', textAlign: 'left' }}>Pending Cases</h6>
                  <hr />

                  </div>


                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems:'center', height:310 }}>                     



                    <div style={{
                            display: 'flex', flexDirection: 'row',
                            justifyContent: 'flex-end', backgroundColor: '#fff', borderRadius: 5, width: '30%', padding: 10, 
                                paddingLeft: 20, paddingRight: 20, marginBottom:20, height:300
                            }}>

                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', }}>
                                <span style={{ color: '#000', fontSize: 25,fontWeight:'bold'  }}>{data.pendingCases}</span>
                                <span style={{ color: '#000', fontSize: 15, fontWeight:'bold'  }}>Pending Cases Within SLA</span>
                            </div>

                    </div>

                    <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-between', width: '70%',height:300 }}>


                        <div style={{
                            display: 'flex', flexDirection: 'column',
                            justifyContent: 'space-between', backgroundColor: '#fff', borderRadius: 5, width: '80%', padding: 10, 
                                paddingLeft: 20, paddingRight: 20, marginBottom:20, height:'40%'
                            }}>

                                <span style={{ color: '#000', fontSize: 25,fontWeight:'bold'  }}>{data.complaintCasesWithinSLAPercent}%</span>
                                <span style={{ color: '#000', fontSize: 15, fontWeight:'bold'  }}>{data.complaintCasesWithinSLACount} Pending</span>
                                <span style={{ color: '#000', fontSize: 15, fontWeight:'bold'  }}>Compliants Cases Within SLA</span>


                       </div>

                       <div style={{
                            display: 'flex', flexDirection: 'column',
                            justifyContent: 'space-between', backgroundColor: '#fff', borderRadius: 5, width: '80%', padding: 10, 
                                paddingLeft: 20, paddingRight: 20, marginBottom:20, height:'40%'
                            }}>

                                <span style={{ color: '#000', fontSize: 25,fontWeight:'bold'  }}>{data.nonComplaintCasesWithinSLAPercent}%</span>
                                <span style={{ color: '#000', fontSize: 15, fontWeight:'bold'  }}>{data.nonComplaintCasesWithinSLACount} Pending</span>
                                <span style={{ color: '#000', fontSize: 15, fontWeight:'bold'  }}>Non Compliants Cases Within SLA</span>


                       </div>

                       </div>
                  </div>
              </div>
          </div>


     
  );
};

export default PendingCases;
