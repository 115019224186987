import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

//import data from '../../../datanew/Australia/data/opsView.json';

const ResolvedUnresolvedIncidents = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const [screenWidth, setScreenWidth] = useState(1000);

    const data = props.data;

  useEffect(() => {
      setScreenWidth (window.innerWidth - ((window.innerWidth * 25)/100))
  }, []);


  const options = {
    chart: {
      type: 'line',
      height: 350,
      stacked: false
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: [2, 2],
      curve: 'smooth'
    },
    colors: ['#FF5733', '#FFC300'],
    xaxis: {
      type: 'category',
      categories: data.charts.resolvedUnresolvedIncidents.categories
    },
    yaxis: {
      title: {
        text: 'Incidents'
      },
      min: 0
    },
    legend: {
      position: 'top'
    },
    title: {
      text: '',
      align: 'center'
    }
  };
   

    return (
        <div style={{
            width: '49%', marginLeft: 0, display: 'flex', flexDirection: 'column',
             height: 500,
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>



            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 10 }}>


            <div style={{ height: 60, }}>

                    <h6 style={{ color: '#fff', textAlign: 'left' }}>
                    Resolved/Unresolved Incidents</h6>
                    <hr />


                    </div>


                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', height:400 }} >
                         
                    <ReactApexChart
        options={options}
        series={data.charts.resolvedUnresolvedIncidents.series}
        type="line"
        height={350}
        width={screenWidth * 0.5}
      />


                    </div>
                </div>
            </div>


    );
};

export default ResolvedUnresolvedIncidents;