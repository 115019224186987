import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

const AzureCISCompliance = (props) => {

  const data = props.data.charts;

  const data1 = {
    labels: ['Fail', 'Pass'],
    datasets: [
      {
        data: [data.azureCISCompliance.fail, data.azureCISCompliance.pass],
        backgroundColor: ['#5dbc53', '#d76358'],
        hoverBackgroundColor: ['#5dbc53', '#d76358']
      }
    ],
    text: ""
  };

  const options1 = {
    plugins: {
      legend: {
        display: false
      }
    },
    cutout: 60
  };

  const plugins1 = [{
    id: 'pluginId',
    beforeDraw: function (chart) {
      var width = chart.width,
        height = chart.height,
        ctx = chart.ctx;
      ctx.restore();
      var fontSize = (height / 160).toFixed(2);
      ctx.font = fontSize + "em sans-serif";
      ctx.textBaseline = "top";
      ctx.textColor = "white";
      var text = `${data.azureCISCompliance.pass}%`,
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 2;
      ctx.fillStyle = '#fff';
      ctx.fillText(text, textX, textY);
      ctx.save();
    }
  }]




  return (
    <div style={{
      width: '32%', marginLeft: 0, display: 'flex', flexDirection: 'column', height: 430,
      marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
    }}>



      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 10 }}>


        <div style={{ height: 60, }}>

          <h6 style={{ color: '#fff', textAlign: 'left' }}>Azure CIS Compliance</h6>
          <hr />

        </div>


        <div style={{
          display: 'flex', flexDirection: 'row',
          justifyContent: 'center', alignItems: 'center', height:300
        }}>


<div className="donut-chart" style={{
            position: 'relative', width: '50%',
            display: 'flex', flexDirection: 'column', alignItems: 'center'
          }}>



          <Doughnut
            type="doughnut"
            data={data1}
            options={options1}
            plugins={plugins1}

          />

          </div>



        </div>
      </div>
    </div>


  );
};

export default AzureCISCompliance;