import React, { useState, useEffect, useRef } from "react";

import axios from 'axios';

import {
  CCol,
  CRow,
  CContainer,
} from '@coreui/react'

import { Link } from "react-router-dom";

import './style.css';

import { HiOutlineDesktopComputer } from "react-icons/hi";
import { TbApi } from "react-icons/tb";
import { AiOutlineIssuesClose } from "react-icons/ai";
import { FiAlertCircle } from "react-icons/fi";
import { MdOutlinePersonalInjury } from "react-icons/md";
import { AiOutlineCloudServer } from "react-icons/ai";

import { BsCollection } from "react-icons/bs";
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

import { Shimmer, Breathing } from 'react-shimmer'
import { FaUsers } from "react-icons/fa";
import { MdApps } from "react-icons/md";
import { FaObjectGroup } from "react-icons/fa";
import { AiOutlineSecurityScan } from "react-icons/ai";
import { MdErrorOutline } from "react-icons/md";
import { SiHelpdesk } from "react-icons/si";
import { IoIosBusiness } from "react-icons/io";
import GaugeChart from 'react-gauge-chart'

import riskLevelChartData from './data/strategic-view/riskLevelChart.json';
import projectStatusTrendChart from './data/strategic-view/projectStatusTrendChart.json';

import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import Chart from 'react-apexcharts';


import RegulationsAndComplianceStatusChart from "./components/tactical/RegulationsAndComplianceStatusChart";
import TopISOComplianceFailuresByIP from "./components/tactical/TopISOComplianceFailuresByIP";
import ComplianceChecks from "./components/tactical/ComplianceChecks";
import ComplianceSummary from "./components/tactical/ComplianceSummary";
import ComplianceFailures from "./components/tactical/ComplianceFailures";
import ComplianceSummaryIndustryCompliance from "./components/tactical/ComplianceSummaryIndustryCompliance";
import ComplianceSummaryIndicators from "./components/tactical/ComplianceSummaryIndicators";

import TotalScores from "./components/tactical/TotalScores";
import CyberSecurityProtectionLevel from "./components/tactical/CyberSecurityProtectionLevel";
import NISTCISF from "./components/tactical/NISTCISF";
import RiskRegister from "./components/tactical/RiskRegister";

import ProjectsCards from "./components/tactical/ProjectsCards";
import ProjectSummary from "./components/tactical/ProjectSummary";
import OverallProgress from "./components/tactical/OverallProgress";

import OperationsStatusSLACards from "./components/tactical/OperationsStatusSLACards";
import SLAViolationsThisMonth from "./components/tactical/SLAViolationsThisMonth";
import FRTThisMonth from "./components/tactical/FRTThisMonth";
import OperationsStatusSLACardsSecondSection from "./components/tactical/OperationsStatusSLACardsSecondSection";
import PendingCases from "./components/tactical/PendingCases";
import ReceivedCases from "./components/tactical/ReceivedCases";
import OperationsStatusSLACardsThirdSection from "./components/tactical/OperationsStatusSLACardsThirdSection";
import RemediationSLAOverview from "./components/tactical/RemediationSLAOverview";
import OrganizationalSLAOverview from "./components/tactical/OrganizationalSLAOverview";

import CloudSecurityStrategy from "./components/tactical/CloudSecurityStrategy";
import Controls from "./components/tactical/Controls";
import ProjectStatus from "./components/tactical/ProjectStatus";
import OperationalHealth from "./components/tactical/OperationalHealth";
import ComplianceAndAudits from "./components/tactical/ComplianceAndAudits";

import australiaData from './datanew/Australia/data/tacticalView.json';
import sydneyData from './datanew/Australia/Sydney/data/tacticalView.json';
import sydneyCBDData from './datanew/Australia/Sydney/CBD/data/tacticalView.json';

import europeData from './datanew/Europe/data/tacticalView.json';
import berlinData from './datanew/Europe/Berlin/data/tacticalView.json';
import centralBerlinData from './datanew/Europe/Berlin/Central Berlin/data/tacticalView.json';

import indiaData from './datanew/India/data/tacticalView.json';
import bangaloreData from './datanew/India/Bangalore/data/tacticalView.json';
import indiranagarData from './datanew/India/Bangalore/Indiranagar/data/tacticalView.json';

import singaporeData from './datanew/Singapore/data/tacticalView.json';
import singaporeCityData from './datanew/Singapore/Singapore City/data/tacticalView.json';
import marinaBayData from './datanew/Singapore/Singapore City/Marina Bay/data/tacticalView.json';

import usaData from './datanew/USA/data/tacticalView.json';
import losAngelesData from './datanew/USA/Los Angeles/data/tacticalView.json';
import longBeachData from './datanew/USA/Los Angeles/Long Beach/data/tacticalView.json';


const TacticalView = () => {

  const random = () => Math.round(Math.random() * 100)
  

  const [selectedRegion, setSelectedRegion] = useState('Australia');
  const [selectedCity, setSelectedCity] = useState('select');
  const [selectedOffice, setSelectedOffice] = useState('select');  

  const handleRegionChange = (event) => {
    setSelectedRegion(event.target.value);
    setSelectedCity('select');
    setSelectedOffice('select');
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
    setSelectedOffice('select');
  };

  const handleOfficeChange = (event) => {
    setSelectedOffice(event.target.value);
  };


  const regions = [
    {
      "name":"Australia",
      "cities":[
        {
          "name":"Sydney",
          "offices":[
            "CBD",            
          ]          
        },      

      ]
    },
    {
      "name":"Europe",
      "cities":[
        
        {
          "name":"Berlin",
          "offices":[
            "Central Berlin",                      
          ]          
        },        
      ]
    },
    {
      "name":"India",
      "cities":[
        
        {
          "name":"Bangalore",
          "offices":[            
            "Indiranagar",                   
          ]          
        },        
      ]
    },
    {
      "name":"Singapore",
      "cities":[
        {
          "name":"Singapore City",
          "offices":[            
            "Marina Bay",                                
          ]          
        },        
      ]
    },
    {
      "name":"USA",
      "cities":[        
        {
          "name":"Los Angeles",
          "offices":[            
            "Long Beach",
          ]          
        },      
        
      ]
    }
  ]


  const ref = useRef(null);

  let data = australiaData;


  if(selectedRegion == 'Australia' && selectedCity == 'select' && selectedOffice == 'select'){
    data = australiaData;
  }

  if(selectedRegion == 'Australia' && selectedCity == 'Sydney' && selectedOffice == 'select'){
    data = sydneyData;
  }

  if(selectedRegion == 'Australia' && selectedCity == 'Sydney' && selectedOffice == 'CBD'){
    data = sydneyCBDData;
  }


  if(selectedRegion == 'Europe' && selectedCity == 'select' && selectedOffice == 'select'){
    data = europeData;
  }

  if(selectedRegion == 'Europe' && selectedCity == 'Berlin' && selectedOffice == 'select'){
    data = berlinData;
  }

  if(selectedRegion == 'Europe' && selectedCity == 'Berlin' && selectedOffice == 'Central Berlin'){
    data = centralBerlinData;
  }


  if(selectedRegion == 'India' && selectedCity == 'select' && selectedOffice == 'select'){
    data = indiaData;
  }

  if(selectedRegion == 'India' && selectedCity == 'Bangalore' && selectedOffice == 'select'){
    data = bangaloreData;
  }

  if(selectedRegion == 'India' && selectedCity == 'Bangalore' && selectedOffice == 'Indiranagar'){
    data = indiranagarData;
  }

  if(selectedRegion == 'Singapore' && selectedCity == 'select' && selectedOffice == 'select'){
    data = singaporeData;
  }

  if(selectedRegion == 'Singapore' && selectedCity == 'Singapore City' && selectedOffice == 'select'){
    data = singaporeCityData;
  }

  if(selectedRegion == 'Singapore' && selectedCity == 'Singapore City' && selectedOffice == 'Marina Bay'){
    data = marinaBayData;
  }

  if(selectedRegion == 'USA' && selectedCity == 'select' && selectedOffice == 'select'){
    data = usaData;
  }

  if(selectedRegion == 'USA' && selectedCity == 'Los Angeles' && selectedOffice == 'select'){
    data = losAngelesData;
  }

  if(selectedRegion == 'USA' && selectedCity == 'Los Angeles' && selectedOffice == 'Long Beach'){
    data = longBeachData;
  }


  return (
    <>

      <CContainer style={{ marginBottom: 50 }}>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}>

          <h4>Tactical View</h4>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

          <select
        value={selectedRegion}
        onChange={handleRegionChange}
        style={{ marginLeft: 10, marginRight: 10, fontSize: 13, padding: 5 }}
      >
        <option value="select" disabled>Select Region</option>
        {regions.map(region => (
          <option key={region.name} value={region.name}>{region.name}</option>
        ))}
      </select>

      <select
        value={selectedCity}
        onChange={handleCityChange}
        style={{ marginLeft: 10, marginRight: 10, fontSize: 13, padding: 5 }}
      >
        <option value="select" disabled>Select City</option>
        {selectedRegion !== 'select' && regions.find(region => region.name === selectedRegion).cities.map(city => (
          <option key={city.name} value={city.name}>{city.name}</option>
        ))}
      </select>

      <select
        value={selectedOffice}
        onChange={handleOfficeChange}
        style={{ marginLeft: 10, marginRight: 10, fontSize: 13, padding: 5 }}
      >
        <option value="select" disabled>Select Office</option>
        {selectedCity !== 'select' && selectedRegion !== 'select' &&
          regions.find(region => region.name === selectedRegion)
                 .cities.find(city => city.name === selectedCity).offices.map((office, index) => (
          <option key={index} value={office}>{office}</option>
        ))}
      </select>
          </div>

        </div>



        {/* Tangible impact - Charts Row - START */}
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', flexWrap: 'wrap', marginTop: 0 }}>

          <RegulationsAndComplianceStatusChart 
            data={data} selectedRegion={selectedRegion} selectedCity={selectedCity} selectedOffice={selectedOffice}/>


        </div>
        {/* END - Row 1 */}


        <div style={{
          width: '100%', backgroundColor: '#89181b', paddingTop: 20, paddingBottom: 20, paddingLeft: 20, paddingRight: 20, marginTop: 40,
          marginBottom: 20, display: 'none', flexDirection: 'row', justifyContent: 'space-between', 
        }}>

          <span style={{ color: 'white', fontSize: 16, fontWeight: 'bold' }}>
            ISO/IEC 27000 : Compliance Management
          </span>

          <select >
            <option>Switch Dashboard</option>
          </select>

        </div>


        {/* Intangible Impact - Charts Row - START */}
        <div style={{ display: 'none', flexDirection: 'row', justifyContent: 'space-between', width: '100%', flexWrap: 'wrap', marginTop: 20 }}>


          <TopISOComplianceFailuresByIP 
            data={data}/>

          <ComplianceChecks 
            data={data}/>

          <ComplianceSummary 
            data={data}/>

          <ComplianceFailures 
            data={data}/>

          <ComplianceSummaryIndustryCompliance 
            data={data}/>

          <ComplianceSummaryIndicators 
            data={data}/>

        </div>


        <div style={{
          width: '100%', backgroundColor: '#89181b', paddingTop: 20, paddingBottom: 20, paddingLeft: 20, paddingRight: 20, marginTop: 40,
          marginBottom: 20, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'
        }}>

          <span style={{ color: 'white', fontSize: 16, fontWeight: 'bold' }}>
            Operations Status - Projects
          </span>


        </div>


        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', flexWrap: 'wrap', marginTop: 20 }}>


          <ProjectsCards 
            data={data}/>

          <ProjectSummary 
            data={data}/>

          <OverallProgress 
            data={data}/>

        </div>



        <div style={{
          width: '100%', backgroundColor: '#89181b', paddingTop: 20, paddingBottom: 20, paddingLeft: 20, paddingRight: 20, marginTop: 40,
          marginBottom: 20, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'
        }}>

          <span style={{ color: 'white', fontSize: 16, fontWeight: 'bold' }}>
            Operations Status - Risk and Cyber Protection
          </span>


        </div>


        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', flexWrap: 'wrap', marginTop: 20 }}>


          <TotalScores 
          data={data}/>

          <CyberSecurityProtectionLevel 
          data={data}/>

          <NISTCISF 
          data={data}/>

          <RiskRegister 
          data={data}/>


        </div>



        <div style={{
          width: '100%', backgroundColor: '#89181b', paddingTop: 20, paddingBottom: 20, paddingLeft: 20, paddingRight: 20, marginTop: 40,
          marginBottom: 20, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'
        }}>

          <span style={{ color: 'white', fontSize: 16, fontWeight: 'bold' }}>
            Operations Status - SLA
          </span>


        </div>

        {/*Operations Status - SLA - START */}

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', flexWrap: 'wrap', marginTop: 20 }}>


          <OperationsStatusSLACards 
            data={data}/>

          <SLAViolationsThisMonth 
            data={data}/>

          <FRTThisMonth 
            data={data}/>

          <OperationsStatusSLACardsSecondSection 
            data={data}/>

          <PendingCases 
            data={data}/>

          <ReceivedCases 
            data={data}/>

        

          <RemediationSLAOverview 
            data={data}/>

          <OrganizationalSLAOverview 
            data={data}/>



        </div>
        {/*END - Operations Status - SLA  */}



        <div style={{
          width: '100%', backgroundColor: '#89181b', paddingTop: 20, paddingBottom: 20, paddingLeft: 20, paddingRight: 20, marginTop: 40,
          marginBottom: 20, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'
        }}>

          <span style={{ color: 'white', fontSize: 16, fontWeight: 'bold' }}>
            Operations Status - Strategy
          </span>

        </div>



        {/*Operations Status - Strategy - START */}

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', flexWrap: 'wrap', marginTop: 20 }}>


          <CloudSecurityStrategy 
            data={data}/>

          <Controls 
            data={data}/>

          <ProjectStatus 
            data={data}/>

          <OperationalHealth 
            data={data}/>

          <ComplianceAndAudits 
          data={data}/>

        </div>
        {/*END - Operations Status - Strategy  */}


      </CContainer>
    </>
  )
}

export default TacticalView