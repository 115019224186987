import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

import SitesByEventsData from '../../../data/ops-view/SecurityStackNetworkSecurity/SitesByEvents.json';
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';

const SitesByEvents = (props) => {

  const [onLoading, setOnLoading] = useState(false)

  const data = props.data.charts;

  const [parentWidth, setParentWidth] = useState(0);

  useEffect(() => {

    const updateParentWidth = () => {
      const parentDiv = document.getElementById('parentDiv');
      if (parentDiv) {
        const width = parentDiv.offsetWidth;
        setParentWidth(width * 0.95); 
      }
    };

    updateParentWidth();

    window.addEventListener('resize', updateParentWidth);

    return () => {
      window.removeEventListener('resize', updateParentWidth);
    };
  }, []);

  const dotColors = ['#000', 'red', 'red', 'red'];

  const dotData = data.sitesByEvents.map(site => {
    const dotColor = dotColors[0];
    return {
      name: site.siteName,
      coordinates: [[site.longitude, site.latitude]],
      events: site.events,
      dotColor
    };
  });


  return (
    <div style={{
      width: '63%', marginLeft: 0, display: 'flex', flexDirection: 'column',
      height: 500,
      marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
    }}>     


      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 10 }}>


      <div style={{ height: 60, }}>

          <h6 style={{ color: '#fff', textAlign: 'left' }}>Sites by Events</h6>
          <hr />

          </div>


          <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
            alignItems: 'center', height: 400
          }}>


<div style={{ width: '100%', height: '100%', background:'#abd3df', borderRadius:10, display:'flex', flexDirection:'row', justifyContent:'center' }}>
              <ComposableMap style={{ height: 450, }}>
                <Geographies geography="/features.json" fill="#f2efe8"
        stroke="#FFFFFF">
                  {({ geographies }) =>
                    geographies.map((geo) => <Geography key={geo.rsmKey} geography={geo} />)
                  }
                </Geographies>
                {dotData.map((dot, index) => (
                  dot.coordinates.map((coordinate, idx) => (
                    <Marker key={`${index}-${idx}`} coordinates={coordinate}>
                      <circle
                        cx={0}
                        cy={0}
                        r={6}
                        fill={dotColors[0]}
                        stroke="#000"
                        strokeWidth={2}
                      />
                      <text
                        textAnchor="middle"
                        y={25}
                        style={{ fontFamily: "system-ui", fill: "#000", fontSize:15 }}
                      >
                        {dot.name}({dot.events})
                      </text>
                    </Marker>
                  ))
                ))}
              </ComposableMap>
            </div>

          </div>
        </div>
      </div>


  );
};

export default SitesByEvents;