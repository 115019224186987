import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

//import data from '../../datanew/Australia/Adelaide/data/opsView.json';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


const SecurityStackOverview = (props) => {


  const data = props.data;

  const [onLoading, setOnLoading] = useState(false)

  const [parentWidth, setParentWidth] = useState(0);

  useEffect(() => {
    const updateParentWidth = () => {
      const parentDiv = document.getElementById('parentDiv');
      if (parentDiv) {
        const width = parentDiv.offsetWidth;
        setParentWidth(width * 0.95); 
      }
    };

    updateParentWidth();

    window.addEventListener('resize', updateParentWidth);

    return () => {
      window.removeEventListener('resize', updateParentWidth);
    };
  }, []);

  const colors = ['#1E88E5', '#388E3C', '#9C27B0', '#607D8B', '#009688', '#FF9800'];



  return (
    <div style={{
      width: '100%', marginLeft: 0, display: 'flex', flexDirection: 'column',
      marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
    }}>



      {onLoading &&

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

          <ShimmerCircularImage size={500} />
          <ShimmerCircularImage size={500} />
        </div>
      }



      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>


        <div style={{ width: '100%' }}>

          <h6 style={{ color: '#fff', textAlign: 'left' }}>Security Stack Overview</h6>
          <hr />


          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>

            {data && data.charts.securityStackOverview.slice(0, 6).map((item, index) => (
              <div key={index} style={{
                display: 'flex', flexDirection: 'column',
                backgroundColor: '#fff', alignItems: 'center', padding: 15,
                borderRadius: 15, margin: 10, width: '14%'
              }}>
                <div style={{ width: 70, height: 70 }}>
                  <CircularProgressbar
                    value={item.percentage}
                    text={`${item.percentage}%`}
                    styles={buildStyles({
                      rotation: 0.25,
                      pathColor: colors[index],
                      textColor: colors[index],
                      trailColor: '#d6d6d6',
                      textSize: '22px',
                      pathTransitionDuration: 0.5,
                    })}
                  />
                </div>
                <span style={{ color: '#000', fontSize: 17, fontWeight: 'bold', textAlign: 'center', marginTop: 20 }}>{item.name}</span>
              </div>
            ))}


          </div>
        </div>
      </div>


    </div>
  );
};

export default SecurityStackOverview;