import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

//import data from '../../../datanew/Australia/data/opsView.json';

const NewIncidentsByCategory = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const [screenWidth, setScreenWidth] = useState(1000);

    const data = props.data;

  useEffect(() => {
      setScreenWidth (window.innerWidth - ((window.innerWidth * 25)/100))
  }, []);

 

  const series = data.charts.newIncidentsByCategory.map(item => item.value);
  const labels = data.charts.newIncidentsByCategory.map(item => item.name);

  const theData = {
    series: series,
    labels: labels
  };


  const options = {
    chart: {
      type: 'pie',
      height: 350
    },
    labels: theData.labels,
    legend: {
      position: 'bottom'
    },
    title: {
      text: '',
      align: 'center'
    },
    colors: ['#FF5733', '#FFC300', '#DAF7A6', '#C70039', '#900C3F'],     
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  };
   

    return (
        <div style={{
            width: '49%', marginLeft: 0, display: 'flex', 
            flexDirection: 'column', height: 500,
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>

         


            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 10 }}>


            <div style={{ height: 60, }}>
                    <h6 style={{ color: '#fff', textAlign: 'left' }}>
                    New Incidents By Category</h6>
                    <hr />

            </div>


                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height:390 }} >
                         
                    <ReactApexChart
        options={options}
        series={theData.series}
        type="donut"
        height={350}
        width={screenWidth * 0.45}
      />

                    </div>
                </div>
            </div>


    );
};

export default NewIncidentsByCategory;