import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";


const FactorsInfluencingRiskPrioritization = (props) => {

  const [onLoading, setOnLoading] = useState(false)

  const data = props.data.charts;

  const [parentWidth, setParentWidth] = useState(0);
  const [screenWidth, setScreenWidth] = useState(1000);

  useEffect(() => {
      setScreenWidth (window.innerWidth - ((window.innerWidth * 25)/100))
  }, []);

  useEffect(() => {
    const updateParentWidth = () => {
      const parentDiv = document.getElementById('parentDiv');
      if (parentDiv) {
        const width = parentDiv.offsetWidth;
        setParentWidth(width * 0.95); // Assigning 90% width
      }
    };

    updateParentWidth();

    window.addEventListener('resize', updateParentWidth);

    return () => {
      window.removeEventListener('resize', updateParentWidth);
    };
  }, []);


  const chartData = {
    series: data.factorsInfluencingRiskPrioritization.map(item => item.value),
    options: {
        chart: {
            height: 350,
            type: 'radialBar',
        },
        plotOptions: {
            radialBar: {
                dataLabels: {
                    name: {
                        fontSize: '22px',
                        color:'#fff'
                    },
                    value: {
                        fontSize: '16px',
                        color:'#fff'
                    },
                    total: {
                        show: true,
                        label: 'Total',
                        color:'#fff',
                        formatter: function () {
                            return data.factorsInfluencingRiskPrioritization.reduce((acc, curr) => acc + curr.value, 0);
                        }
                    }
                }
            },
            
        },
        labels: data.factorsInfluencingRiskPrioritization.map(item => item.name),
        legend: {
          show: true,
          fontSize: '14px',
          position: 'bottom'
        }
    },
};


  return (
    <div style={{
      width: '30%', marginLeft: 0, display: 'flex', flexDirection: 'column', height: 450,
      marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
    }}>
     

      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', marginTop: 10 }}>


      <div style={{ height: 60, }}>


          <h6 style={{ color: '#fff', textAlign: 'left' }}>Factors Influencing Risk Prioritization</h6>
          <hr />

      </div>

          <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',height: 320,
            alignItems: 'center',
          }}>

          
            <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="radialBar"
                height={350}
                width={screenWidth * 0.3}
            />



          </div>
        </div>
      </div>


  );
};

export default FactorsInfluencingRiskPrioritization;