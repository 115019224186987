import React, { useState, useEffect, useRef } from "react";

import {
  CCol,
  CRow,
  CContainer,
} from '@coreui/react'


import './style.css';
import { FaCheckCircle } from "react-icons/fa";
import { FaNotEqual } from "react-icons/fa";
import { MdClose } from "react-icons/md";

import Modal from 'react-modal';

const RiskRegister = () => {
  
  const customStyles = {
    content: {
      top: '10%',
      left: '20%',
      width: '70%',
      right: 'auto',
      bottom: 'auto',
      height: '80%',
      backgroundColor: '#000',
      borderRadius: 15,
      borderColor: '252b2b'
    },
  };

  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  const closeModal = async () => {

    setModalIsOpen(false);
  };

  const handleButtonClick = (user) => {
    setModalIsOpen(true);
  };
  
  return (
    <>

      <CContainer style={{ marginBottom: 50 }}>

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', }}>

          <h4>Risk Register</h4>

          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

          <div style={{width:'100%', marginTop:30, display:'flex', flexDirection:'row', justifyContent:'space-between', 
          backgroundColor:'white', alignItems:'center'}}>

                  <span style={{padding:10, color:'black', textAlign:'left', fontSize:20, fontWeight:'bold'}}>Risk & Controls</span>

                  <button onClick={handleButtonClick}
                    style={{color:'#fff', backgroundColor:'#89181b', borderWidth:0, height:40, marginRight:10
                , borderRadius:5}}>Risk Ratings</button>
          </div>

                <table style={{width:'100%', marginTop:0, overflowX:'scroll'}}>
                   

                    <thead>
                        <th style={{padding:10, borderWidth:1, background:'#e8e8e8', color:'#000',textAlign:'center',}}>Risk Summary</th>
                        <th style={{padding:10, borderWidth:1, background:'#e8e8e8',color:'#000',textAlign:'center',
  width:'5%'}}>Probability (1 - 7 Rating -sec Threat Assessment Key)</th>
                        <th style={{padding:10, borderWidth:1, background:'#e8e8e8',color:'#000',textAlign:'center',
   width:'5%'}}>Impact (1 - 4 Rating -sec Threat Assessment Key)</th>
                        <th style={{padding:10, borderWidth:1, background:'#e8e8e8',color:'#000',textAlign:'center',
   width:'5%'}}>Inherent Risk (Probability x Impact)</th>
                        <th style={{padding:10, borderWidth:1, background:'#e8e8e8',color:'#000',textAlign:'center',
   width:'5%', textAlign:'center'}}>Risk Rating</th>
                        <th style={{padding:10, borderWidth:1,background:'#e8e8e8',color:'#000', textAlign:'center', minWidth:600}}>Mitigating Controls</th>
                        <th style={{padding:10, borderWidth:1, background:'#e8e8e8',color:'#000',textAlign:'center',
   width:'5%'}}>% of Risk Managed by Controls</th>
                        <th style={{padding:10, borderWidth:1, background:'#e8e8e8',color:'#000',textAlign:'center',
   width:'5%'}}>Controlled Risk</th>
                        <th style={{padding:10, borderWidth:1, background:'#e8e8e8',color:'#000',textAlign:'center',
   width:'5%'}}>Residual Risk</th>
                        <th style={{padding:10, borderWidth:1, background:'#e8e8e8',color:'#000',textAlign:'center',
   width:'5%', background:'#e8e8e8',}}>Proper Controls in Place</th>
                        <th style={{padding:10, borderWidth:1,backgroundColor:'#e8e8e8',color:'#000',textAlign:'center',}}>Conclusion / Possible Additional Testing</th>
                      
                    </thead>

                    <tbody>

                      {/* Row 1 */}

                      <tr>
                        <td style={{padding:10, borderWidth:1,}}>
                            Possible Data Breach due to Phishing Attack
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%'}}>
                          7
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%'}}>
                          4
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%'}}>
                          28
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%', backgroundColor:'#c00000',textAlign:'center', fontWeight:'bold'}}>
                          Critical
                        </td>
                        <td style={{padding:10, borderWidth:1, minWidth:600}}>
                        Comprehensive Employee Training Programs to educate employees on cybersecurity best practices and phishing awareness with regular updates on new threats.
Apply advanced email filtering systems to implement and update robust spam and malware filters using AI to detect and block phishing emails.
Conduct periodic fake phishing campaigns to test and improve employees' ability to identify phishing attempts.

                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%'}}>
                          80%
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%'}}>
                          20
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%'}}>
                          8
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%',textAlign:'center'}}>
                          <FaCheckCircle size={25} style={{color:'#5dbc53',textAlign:'center'}}/>
                        </td>
                        <td style={{padding:10, borderWidth:1}}>
                           Possible Response: Increase the frequency of phishing simulations and update training materials
                        </td>

                        </tr>


{/* Row 2 */}

                        <tr>
                        <td style={{padding:10, borderWidth:1}}>
                        Exploitation of Software Vulnerabilities

                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%'}}>
                          6
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%'}}>
                          3
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%'}}>
                          18
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%', backgroundColor:'#ff0000',textAlign:'center', fontWeight:'bold'}}>
                          High
                        </td>
                        <td style={{padding:10, borderWidth:1}}>
                        Regularly apply software patches to address security vulnerabilities.
Continuous scan systems to identify and address potential security weaknesses.
Promptly apply critical updates to mitigate emerging threats.


                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%'}}>
                          85%
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%'}}>
                          15.5
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%'}}>
                          2.5
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%',textAlign:'center'}}>
                          <FaCheckCircle size={25} style={{color:'#5dbc53',textAlign:'center'}}/>
                        </td>
                        <td style={{padding:10, borderWidth:1}}>
                        Possible Response: Enhance the patch management process and conduct more frequent vulnerability scans
                        </td>

                        </tr>


{/* Row 3 */}

                        <tr>
                        <td style={{padding:10, borderWidth:1,}}>
                        Denial of Service (DoS) Attack
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%'}}>
                          5
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%'}}>
                          3
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%'}}>
                          15
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%',  backgroundColor:'#ff0000',textAlign:'center', fontWeight:'bold'}}>
                          High
                        </td>
                        <td style={{padding:10, borderWidth:1}}>
                        Implement systems to continuously monitor network activity for anomalies and potential security breaches.
Utilize filtering mechanisms to restrict and control incoming and outgoing network traffic, enhancing security.
Implement rate limiting measures to mitigate the impact of potential attacks, such as Dos & DDoS.
Develop and document clear procedures for responding to security incidents, ensuring a swift and effective response to mitigate damage.


                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%'}}>
                          85%
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%'}}>
                          12
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%'}}>
                          3
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%',textAlign:'center'}}>
                          <FaCheckCircle size={25} style={{color:'#5dbc53',textAlign:'center'}}/>
                        </td>
                        <td style={{padding:10, borderWidth:1}}>
                        Possible Response: Enhance network monitoring capabilities, conduct DoS attack simulations, and refine incident response plans

                        </td>

                        </tr>


{/* Row 4 */}


                        <tr>
                        <td style={{padding:10, borderWidth:1}}>
                        Unauthorized Access attempted due to Weak Passwords

                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%'}}>
                          3
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%'}}>
                          2
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%'}}>
                          6
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%', backgroundColor:'#ffc000',textAlign:'center', fontWeight:'bold', color:'#000'}}>
                          Medium
                        </td>
                        <td style={{padding:10, borderWidth:1}}>
                        Implement MFA across all systems to add an extra layer of security beyond passwords.
Conduct periodic reviews of user access rights to ensure appropriate access levels and revoke unnecessary permissions.
Enforce strong password requirements



                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%'}}>
                          75%
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%'}}>
                          7.5
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%'}}>
                          0.5
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%',textAlign:'center'}}>
                          <FaCheckCircle size={25} style={{color:'#5dbc53',textAlign:'center'}}/>
                        </td>
                        <td style={{padding:10, borderWidth:1}}>
                        Possible Response: Implement stricter access controls, conduct periodic access reviews, and enhance user education programs


                        </td>

                        </tr>


{/* Row 5 */}




                        <tr>
                        <td style={{padding:10, borderWidth:1}}>
                        Physical Security Breach due to Tailgating

                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%'}}>
                          3
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%'}}>
                          2
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%'}}>
                          6
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%',  backgroundColor:'#ffc000',textAlign:'center', fontWeight:'bold',color:'#000'}}>
                          Medium
                        </td>
                        <td style={{padding:10, borderWidth:1}}>
                        Trained security personnel to monitor premises and respond to any security incidents.
Use surveillance cameras to monitor physical areas and deter any unauthorized access.
Implement access control systems such as keycard or biometric scanners to regulate entry to sensitive areas.
Provide training to employees on security protocols and procedures to promote a culture of security awareness and vigilance.


                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%'}}>
                          90%
                        </td>
                        <td style={{padding:10, borderWidth:1, width:'5%'}}>
                          5.6
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%'}}>
                          0.4
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%',textAlign:'center'}}>
                          <FaCheckCircle size={25} style={{color:'#5dbc53',textAlign:'center'}}/>
                        </td>
                        <td style={{padding:10, borderWidth:1}}>
                        Possible Response: Implement stricter access controls, conduct periodic access reviews, and enhance user education programs

                        </td>

                        </tr>


{/* Row 6 */}

                        <tr>
                        <td style={{padding:10, borderWidth:1}}>
                        Compliance Violation due to Lack of Awareness

                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%'}}>
                          3
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%'}}>
                          1
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%'}}>
                          4
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%', backgroundColor:'#92d050', textAlign:'center', fontWeight:'bold',color:'#000'}}>
                          Low
                        </td>
                        <td style={{padding:10, borderWidth:1}}>
                        Conduct ongoing training sessions to educate employees on regulatory requirements and organizational policies.
Perform scheduled audits to assess compliance with regulations, identify areas of improvement, and address any non-compliance issues.
Always review and update compliance policies and procedures to reflect changes in regulations or business practices.
Utilize automated tools to monitor compliance in real-time, detect anomalies, and generate reports for regulatory purposes.


                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%'}}>
                          90%
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%'}}>
                          3
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%'}}>
                          1
                        </td>
                        <td style={{padding:10, borderWidth:1,width:'5%', textAlign:'center'}}>
                          <FaCheckCircle size={25} style={{color:'#5dbc53'}}/>
                        </td>
                        <td style={{padding:10, borderWidth:1}}>
                        Possible Response: Increase compliance training frequency, update policies as needed, and utilize automated compliance monitoring tools

                        </td>

                        </tr>

                    </tbody>
                </table>     
          </div>

        </div>            


        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Remediations"
        >

          <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
          <h4>Risk Ratings</h4>
            <MdClose size={30} style={{color:'#fff'}} onClick={closeModal}/>
          </div>         

          <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', marginTop:30}}>


               <div style={{width:'30%'}}>
                   <table>

                    <thead>
                        <th style={{color:'#fff', padding:10 , textAlign:'center'}}>Possibility of Failure</th>
                        <th style={{color:'#fff', padding:10, textAlign:'center'}}>Possible Failures Rates</th>
                        <th style={{color:'#fff', padding:10, textAlign:'center'}}>Ranking</th>
                    </thead>

                    <tbody>
                        <tr style={{background:'transparent'}}>
                            <td style={{textAlign:'center',borderRightColor:'#fff', borderWidth:1}}>Very high - almost inevitable</td>
                            <td style={{textAlign:'center', borderRightColor:'#fff', borderWidth:1}}>1 in 24hrs / 1 day</td>
                            <td style={{textAlign:'center', borderRightColor:'#fff', borderWidth:1}}>7</td>
                        </tr>
                        <tr style={{background:'#fff'}}>
                            <td style={{color:'#000', textAlign:'center',borderColor:'#252b3b', borderWidth:1}}>High - repeated failures</td>
                            <td style={{color:'#000', textAlign:'center', borderColor:'#252b3b', borderWidth:1}}>1 in 7 days</td>
                            <td style={{color:'#000', textAlign:'center', borderColor:'#252b3b', borderWidth:1}}>6</td>
                        </tr>
                        <tr style={{background:'transparent'}}>
                            <td style={{textAlign:'center', borderRightColor:'#fff', borderWidth:1}}>Moderate - occasional failures</td>
                            <td style={{textAlign:'center', borderRightColor:'#fff', borderWidth:1}}>1 in 30 days / 1 month</td>
                            <td style={{textAlign:'center', borderRightColor:'#fff', borderWidth:1}}>5</td>
                        </tr>
                        <tr>
                            <td style={{textAlign:'center', borderRightColor:'#fff', borderWidth:1}}></td>
                            <td style={{textAlign:'center',borderRightColor:'#fff', borderWidth:1}}>1 in 6 months</td>
                            <td style={{textAlign:'center', borderRightColor:'#fff', borderWidth:1}}>4</td>
                        </tr>
                        <tr style={{background:'#fff'}}>
                            <td style={{color:'#000', textAlign:'center', borderColor:'#252b3b', borderWidth:1}}>Low - rarely few failures</td>
                            <td style={{color:'#000', textAlign:'center',borderColor:'#252b3b', borderWidth:1}}>1 in 1 year</td>
                            <td style={{color:'#000', textAlign:'center', borderColor:'#252b3b', borderWidth:1}}>3</td>
                        </tr>
                        <tr style={{background:'#fff'}}>
                            <td style={{color:'#000', textAlign:'center', borderColor:'#252b3b', borderWidth:1}}></td>
                            <td style={{color:'#000', textAlign:'center', borderColor:'#252b3b', borderWidth:1}}>1 in 2 years</td>
                            <td style={{color:'#000', textAlign:'center',borderColor:'#252b3b', borderWidth:1}}>2</td>
                        </tr>
                        <tr style={{background:'transparent'}}>
                            <td style={{textAlign:'center',borderRightColor:'#fff', borderWidth:1}}>Remote: failure is unlikely</td>
                            <td style={{textAlign:'center', borderRightColor:'#fff', borderWidth:1}}>1 in 5 years</td>
                            <td style={{textAlign:'center', borderRightColor:'#fff', borderWidth:1}}>1</td>
                        </tr>
                    </tbody>

                   </table>
               </div>


               <div  style={{width:'30%'}}>
                   <table>

                    <thead>
                      <th colspan="5" style={{color:'#fff', padding:10, textAlign:'center'}}>Risk Rating = Probability * Impact</th>
                    </thead>

                    <tbody>
                        <tr>
                          <td style={{backgroundColor:'#fff', color:'#000', borderColor:'#252b3b', borderWidth:1}}>7</td>
                          <td style={{backgroundColor:'#ffc000', color:'#000', borderColor:'#252b3b', borderWidth:1}}>7</td>
                          <td style={{backgroundColor:'#ff0000', color:'#000',borderColor:'#252b3b', borderWidth:1}}>14</td>
                          <td style={{backgroundColor:'#c00000', color:'#000', borderColor:'#252b3b', borderWidth:1}}>21</td>
                          <td style={{backgroundColor:'#c00000', color:'#000',borderColor:'#252b3b', borderWidth:1}}>28</td>
                        </tr>

                        <tr>
                          <td style={{backgroundColor:'#fff', color:'#000', borderColor:'#252b3b', borderWidth:1}}>6</td>
                          <td style={{backgroundColor:'#ffc000', color:'#000',borderColor:'#252b3b', borderWidth:1}}>6</td>
                          <td style={{backgroundColor:'#ff0000', color:'#000',borderColor:'#252b3b', borderWidth:1}}>12</td>
                          <td style={{backgroundColor:'#ff0000', color:'#000',borderColor:'#252b3b', borderWidth:1}}>18</td>
                          <td style={{backgroundColor:'#c00000', color:'#000',borderColor:'#252b3b', borderWidth:1}}>24</td>
                        </tr>


                        <tr>
                          <td style={{backgroundColor:'#fff', color:'#000',borderColor:'#252b3b', borderWidth:1}}>5</td>
                          <td style={{backgroundColor:'#92d050', color:'#000',borderColor:'#252b3b', borderWidth:1}}>5</td>
                          <td style={{backgroundColor:'#ffc000', color:'#000', borderColor:'#252b3b', borderWidth:1}}>10</td>
                          <td style={{backgroundColor:'#ff0000', color:'#000',borderColor:'#252b3b', borderWidth:1}}>15</td>
                          <td style={{backgroundColor:'#c00000', color:'#000', borderColor:'#252b3b', borderWidth:1}}>20</td>
                        </tr>


                        <tr>
                          <td style={{backgroundColor:'#fff', color:'#000', borderColor:'#252b3b', borderWidth:1}}>4</td>
                          <td style={{backgroundColor:'#92d050', color:'#000', borderColor:'#252b3b', borderWidth:1}}>4</td>
                          <td style={{backgroundColor:'#ffc000', color:'#000', borderColor:'#252b3b', borderWidth:1}}>8</td>
                          <td style={{backgroundColor:'#ff0000', color:'#000',borderColor:'#252b3b', borderWidth:1}}>12</td>
                          <td style={{backgroundColor:'#ff0000', color:'#000', borderColor:'#252b3b', borderWidth:1}}>16</td>
                        </tr>                      


                        <tr>
                          <td style={{backgroundColor:'#fff', color:'#000',borderColor:'#252b3b', borderWidth:1}}>3</td>
                          <td style={{backgroundColor:'#92d050', color:'#000',borderColor:'#252b3b', borderWidth:1}}>3</td>
                          <td style={{backgroundColor:'#ffc000', color:'#000', borderColor:'#252b3b', borderWidth:1}}>6</td>
                          <td style={{backgroundColor:'#ffc000', color:'#000',borderColor:'#252b3b', borderWidth:1}}>9</td>
                          <td style={{backgroundColor:'#ff0000', color:'#000', borderColor:'#252b3b', borderWidth:1}}>12</td>
                        </tr>


                        <tr>
                          <td style={{backgroundColor:'#fff', color:'#000', borderColor:'#252b3b', borderWidth:1}}>2</td>
                          <td style={{backgroundColor:'#92d050', color:'#000', borderColor:'#252b3b', borderWidth:1}}>2</td>
                          <td style={{backgroundColor:'#92d050', color:'#000', borderColor:'#252b3b', borderWidth:1}}>4</td>
                          <td style={{backgroundColor:'#ffc000', color:'#000', borderColor:'#252b3b', borderWidth:1}}>6</td>
                          <td style={{backgroundColor:'#ffc000', color:'#000',borderColor:'#252b3b', borderWidth:1}}>8</td>
                        </tr>


                        <tr>
                          <td style={{backgroundColor:'#fff', color:'#000', borderColor:'#252b3b', borderWidth:1}}>1</td>
                          <td style={{backgroundColor:'#92d050', color:'#000', borderColor:'#252b3b', borderWidth:1}}>1</td>
                          <td style={{backgroundColor:'#92d050', color:'#000',borderColor:'#252b3b', borderWidth:1}}>2</td>
                          <td style={{backgroundColor:'#92d050', color:'#000',borderColor:'#252b3b', borderWidth:1}}>3</td>
                          <td style={{backgroundColor:'#92d050', color:'#000',borderColor:'#252b3b', borderWidth:1}}>4</td>
                        </tr>

                        <tr>
                          <td style={{backgroundColor:'#fff', color:'#000', borderColor:'#252b3b', borderWidth:1}}></td>
                          <td style={{backgroundColor:'#fff', color:'#000', borderColor:'#252b3b', borderWidth:1}}>1</td>
                          <td style={{backgroundColor:'#fff', color:'#000', borderColor:'#252b3b', borderWidth:1}}>2</td>
                          <td style={{backgroundColor:'#fff', color:'#000', borderColor:'#252b3b', borderWidth:1}}>3</td>
                          <td style={{backgroundColor:'#fff', color:'#000', borderColor:'#252b3b', borderWidth:1}}>4</td>
                        </tr>
                    </tbody>
                    
                   </table>
               </div>



               <div style={{display:'flex', flexDirection:'column', width:'30%'}}>
               <table>

<thead>
  <th style={{color:'#fff', padding:10, textAlign:'center'}}>Risk Rating</th>
  <th style={{color:'#fff', padding:10, textAlign:'center'}}>Count</th>
</thead>

<tbody>
    <tr>
      <td style={{backgroundColor:'#fff', color:'#000', borderColor:'#252b3b', borderWidth:1}}>Low</td>
      <td style={{backgroundColor:'#92d050', color:'#000', borderColor:'#252b3b', borderWidth:1}}>1</td>
      
    </tr>

    <tr>
      <td style={{backgroundColor:'#fff', color:'#000', borderColor:'#252b3b', borderWidth:1}}>Medium</td>
      <td style={{backgroundColor:'#ffc000', color:'#000', borderColor:'#252b3b', borderWidth:1}}>2</td>
      
    </tr>

    <tr>
      <td style={{backgroundColor:'#fff', color:'#000', borderColor:'#252b3b', borderWidth:1}}>High</td>
      <td style={{backgroundColor:'#ff0000', color:'#000', borderColor:'#252b3b', borderWidth:1}}>2</td>
      
    </tr>

    <tr>
      <td style={{backgroundColor:'#fff', color:'#000', borderColor:'#252b3b', borderWidth:1}}>Critical</td>
      <td style={{backgroundColor:'#c00000', color:'#000', borderColor:'#252b3b', borderWidth:1}}>1</td>
      
    </tr>

            


   
</tbody>

</table>

<table style={{marginTop:20}}>

<thead>
  <th colspan="2" style={{color:'#fff', padding:10, textAlign:'center'}}>Impact Rating</th>
</thead>

<tbody>
    <tr>
      <td style={{backgroundColor:'#fff', color:'#000', borderColor:'#252b3b', borderWidth:1}}>Crisis</td>
      <td style={{backgroundColor:'transparent', color:'#fff', borderColor:'#fff', borderWidth:1}}>4</td>
      
    </tr>

    <tr>
      <td style={{backgroundColor:'#fff', color:'#000', borderColor:'#252b3b', borderWidth:1}}>Significant</td>
      <td style={{backgroundColor:'transparent', color:'#fff', borderColor:'#fff', borderWidth:1}}>3</td>
      
    </tr>

    <tr>
      <td style={{backgroundColor:'#fff', color:'#000', borderColor:'#252b3b', borderWidth:1}}>Nuisance</td>
      <td style={{backgroundColor:'transparent', color:'#fff', borderColor:'#fff', borderWidth:1}}>2</td>
      
    </tr>

    <tr>
      <td style={{backgroundColor:'#fff', color:'#000', borderColor:'#252b3b', borderWidth:1}}>Non-threatening</td>
      <td style={{backgroundColor:'transparent', color:'#fff', borderColor:'#fff', borderWidth:1}}>1</td>
      
    </tr>

            


   
</tbody>

</table>

               </div>





          </div>

          <table style={{marginTop:20}}>

<thead>
  <th colspan="2" style={{color:'#fff', padding:10, textAlign:'center'}}>Controls in Place</th>
</thead>

<tbody>
    <tr>
      <td style={{backgroundColor:'#fff', color:'#000', borderColor:'#252b3b', borderWidth:1, textAlign:'center'}}>
        <FaCheckCircle size={25} style={{color:'#5dbc53',textAlign:'center'}}/>
      </td>
      <td style={{backgroundColor:'transparent', color:'#fff', borderColor:'#fff', borderWidth:1}}>
      Control in place, effective
      </td>
      
    </tr>

    <tr>
      <td style={{backgroundColor:'#fff', color:'#000', borderColor:'#252b3b', borderWidth:1, textAlign:'center'}}>
          <FaNotEqual size={25} style={{color:'blue',textAlign:'center'}}/>
      </td>
      <td style={{backgroundColor:'transparent', color:'#fff', borderColor:'#fff', borderWidth:1}}>Control in place, partially effective
</td>
      
    </tr>

    <tr>
      <td style={{backgroundColor:'#fff', color:'#000', borderColor:'#252b3b', borderWidth:1, textAlign:'center'}}>
      <MdClose size={25} style={{color:'red',textAlign:'center'}}/>
      </td>
      <td style={{backgroundColor:'transparent', color:'#fff', borderColor:'#fff', borderWidth:1}}>
      No Control in place
      </td>
      
    </tr>

   

            


   
</tbody>
</table>
          

        </Modal>




      </CContainer>
    </>
  )
}

export default RiskRegister