import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

//import data from '../../../datanew/Australia/data/opsView.json';

const ItemsByClass = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const [screenWidth, setScreenWidth] = useState(1000);

    const data = props.data;

  useEffect(() => {
      setScreenWidth (window.innerWidth - ((window.innerWidth * 25)/100))
  }, []);

  const options = {
    chart: {
      type: 'pie',
      toolbar: {
        show: false
      }
    },
    labels: data.charts.itemsByClass.map(item => item.name),
    legend: {
      show: true,
      position: 'bottom'
    }
  };

  const series = data.charts.itemsByClass.map(item => item.value);
   

    return (
        <div style={{
            width: '49%', marginLeft: 0, display: 'flex', 
            flexDirection: 'column', height: 470,
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>


            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 10 }}>

            <div style={{ height: 60, }}>


                    <h6 style={{ color: '#fff', textAlign: 'left' }}>
                        Items by Class</h6>
                    <hr />

            </div>


                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height:330 }}>
                         
                    <ReactApexChart
      options={options}
      series={series}
      type="pie"
      width={screenWidth * 0.25}
    />


                    </div>
                </div>
            </div>


    );
};

export default ItemsByClass;