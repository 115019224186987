import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
//import Chart from 'react-apexcharts';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend);

const RiskByAssetCategory = (props) => {

  const [onLoading, setOnLoading] = useState(false)

  const [screenWidth, setScreenWidth] = useState(1000);

  const data = {
    "riskByAssetCategory": {
      "chart": props.data.charts.riskByAssetCategory.chart,
      "cards": props.data.charts.riskByAssetCategory.cards
    },
  }


  useEffect(() => {
    setScreenWidth(window.innerWidth - ((window.innerWidth * 25) / 100))
  }, []);

  const total = 1134;
  const portion = 113;
  const percentage = (portion / total) * 100;
  const restPercentage = 100 - percentage;

  const chart = {
    chartData: {
      series: [portion, total - portion],
      options: {
        chart: {
          type: 'donut',
        },
        labels: ['Completed', 'Remaining'],
        colors: ['#ff5759', '#cfcfcf'],
        plotOptions: {
          pie: {
            donut: {
              size: '65%',
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
      },
    },
    centerText: `${portion}/${total}`
  };

  const chartData = props.data.charts.riskByAssetCategory.chart;


  const data1 = {
    labels: ['High', 'Medium', 'Low'],
    datasets: [
      {
        data: [chartData.high, chartData.medium, chartData.low],
        backgroundColor: ['#5dbc53', '#d76358', 'red'],
        hoverBackgroundColor: ['#5dbc53', '#d76358', 'red']
      }
    ],
    text: ""
  };

  const options1 = {
    plugins: {
      legend: {
        display: false,
        position: 'right',
        labels: {
          color: '#fff'
        }
      },

    },
    cutout: 80
  };

  const plugins1 = [{
    id: 'pluginId',
    beforeDraw: function (chart) {
      var width = chart.width,
        height = chart.height,
        ctx = chart.ctx;
      ctx.restore();
      var fontSize = 1;
      ctx.font = fontSize + "em sans-serif";
      ctx.textBaseline = "top";
      ctx.textColor = "white";
      var text = `Total ${chartData.high}`,
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 2;
      ctx.fillStyle = '#fff';
      ctx.fillText(text, textX, textY);
      ctx.save();
    }
  }]


  return (
    <div style={{
      width: '49%', marginLeft: 0, display: 'flex', flexDirection: 'column', height: 550,
      marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
    }}>


      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 10 }}>


        <div style={{ height: 60, }}>

          <h4 style={{ color: '#fff', textAlign: 'left' }}>Risk by Asset Category</h4>
          <hr />
        </div>


        <div style={{
          display: 'flex', flexDirection: 'row', height: 500,
          justifyContent: 'space-between', alignItems: 'center'
        }}>

          <div className="donut-chart"
            style={{ position: 'relative', width: '50%' }}>


            <Doughnut
              type="doughnut"
              data={data1}
              options={options1}
              plugins={plugins1}
              width={screenWidth * 0.3}
              height={screenWidth * 0.3}

            />


          </div>


          <div style={{ width: '50%', height: '300px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>


            <span className="whiteSpan" style={{ textAlign: 'center' }}>Total Assets</span>

            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', marginTop: 20, width: '100%' }}>

              <div style={{
                display: 'flex', flexDirection: 'column', width: '43%', margin: 5,
                border: '1px solid #fff', justifyContent: 'center', borderRadius: 5, padding: 5, alignItems: 'center', padding: 30
              }}>
                <span style={{ color: '#fb4437', fontSize: 15, textAlign: 'center' }}>{props.data.charts.riskByAssetCategory.cards[0].value}</span>
                <span className="whiteSpan" style={{ marginTop: 10, fontSize: 14, textAlign: 'center' }}>{props.data.charts.riskByAssetCategory.cards[0].name}</span>
              </div>

              <div style={{
                display: 'flex', flexDirection: 'column', width: '43%', margin: 5,
                border: '1px solid #fff', justifyContent: 'center', borderRadius: 5, padding: 5, alignItems: 'center', padding: 30
              }}>
                <span style={{ color: '#fb4437', fontSize: 14, textAlign: 'center' }}>{props.data.charts.riskByAssetCategory.cards[0].value}</span>
                <span className="whiteSpan" style={{ marginTop: 10, fontSize: 14, textAlign: 'center' }}>{props.data.charts.riskByAssetCategory.cards[0].name}</span>
              </div>


              <div style={{
                display: 'flex', flexDirection: 'column', width: '43%', margin: 5,
                border: '1px solid #fff', justifyContent: 'center', borderRadius: 5, padding: 5, alignItems: 'center', padding: 30
              }}>
                <span style={{ color: '#fb4437', fontSize: 14, textAlign: 'center' }}>{props.data.charts.riskByAssetCategory.cards[0].value}</span>
                <span className="whiteSpan" style={{ marginTop: 10, fontSize: 14, textAlign: 'center' }}>{props.data.charts.riskByAssetCategory.cards[0].name}</span>
              </div>



            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default RiskByAssetCategory;
