import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

import TopSendersForBlockedMessagesData from '../../../data/ops-view/SecurityStackEmailSecurity/TopSendersForBlockedMessages.json';


const TopSendersForBlockedMessages = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const data = props.data.charts;

    const [parentWidth, setParentWidth] = useState(0);

    useEffect(() => {
      const updateParentWidth = () => {
        const parentDiv = document.getElementById('parentDiv');
        if (parentDiv) {
          const width = parentDiv.offsetWidth;
          setParentWidth(width * 0.95); // Assigning 90% width
        }
      };
  
      updateParentWidth();
  
      window.addEventListener('resize', updateParentWidth);
  
      return () => {
        window.removeEventListener('resize', updateParentWidth);
      };
    }, []);
   

    return (
        <div style={{
            width: '49%', marginLeft: 0, display: 'flex', flexDirection: 'column', height: 550,
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>



            {onLoading &&

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

                    <ShimmerCircularImage size={500} />
                    <ShimmerCircularImage size={500} />
                </div>
            }


            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>


                <div style={{ width: '100%' }}>

                    <h6 style={{ color: '#fff', textAlign: 'left' }}>Top Senders for Blocked Messages</h6>
                    <hr />


                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} id="parentDiv">
                         

                    <table style={{ width: '100%' }}>

<tbody>
  {data.topSendersForBlockedMessages.map((item, index) => (
    <tr key={index} style={{borderWidth:1}}>
      <td style={{ width: '2%' }}>{item.messagesBlocked}</td>

      <td style={{backgroundColor:'#ff9999', color:'#000'}}>{item.sender}</td>
    </tr>
  ))}
</tbody>

</table>

                    
                    </div>
                </div>
            </div>


        </div>
    );
};

export default TopSendersForBlockedMessages;