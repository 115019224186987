import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

import DeviceUniverseData from '../../../data/ops-view/SecurityStackEndpointSecurity/DeviceUniverse.json';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const DeviceUniverse = (props) => {

  const [onLoading, setOnLoading] = useState(false)

  const data = props.data.charts;

  const data1 = {
    labels: [data.deviceUniverse[0].name, data.deviceUniverse[1].name, data.deviceUniverse[2].name, data.deviceUniverse[3].name],
    datasets: [
      {
        data: [data.deviceUniverse[0].value, data.deviceUniverse[1].value, data.deviceUniverse[2].value, data.deviceUniverse[3].value],
        backgroundColor: ['#5dbc53', '#d76358'],
        hoverBackgroundColor: ['#5dbc53', '#d76358']
      }
    ],
    text: ""
  };
  
  const options1 = {
    plugins: {
      legend: {
        display: false,
        
      }
    },
    cutout: 80
  };
  
  const plugins1 = [{
    id : 'pluginId',
    beforeDraw: function(chart) {
     var width = chart.width,
         height = chart.height,
         ctx = chart.ctx;
         ctx.restore();
         var fontSize = 1;
         ctx.font = fontSize + "em sans-serif";
         ctx.textBaseline = "top";
         ctx.textColor = "white";
         var text = `${(data.deviceUniverse[0].value + data.deviceUniverse[1].value + data.deviceUniverse[2].value + data.deviceUniverse[3].value)} Devices`,
         textX = Math.round((width - ctx.measureText(text).width) / 2),
         textY = height / 2;
         ctx.fillStyle = '#fff';
         ctx.fillText(text, textX, textY);
         ctx.save();
    } 
   }]

  const [parentWidth, setParentWidth] = useState(0);
  const [screenWidth, setScreenWidth] = useState(1000);

  useEffect(() => {
      setScreenWidth (window.innerWidth - ((window.innerWidth * 25)/100))
  }, []);


  useEffect(() => {
    const updateParentWidth = () => {
      const parentDiv = document.getElementById('parentDiv');
      if (parentDiv) {
        const width = parentDiv.offsetWidth;
        setParentWidth(width * 0.95); // Assigning 90% width
      }
    };

    updateParentWidth();

    window.addEventListener('resize', updateParentWidth);

    return () => {
      window.removeEventListener('resize', updateParentWidth);
    };
  }, []);


  return (
    <div style={{
      width: '49%', marginLeft: 0, display: 'flex', flexDirection: 'column', 
      height: 550,
      marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
    }}>


      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 10 }}>

      <div style={{ height: 60, }}>


          <h6 style={{ color: '#fff', textAlign: 'left' }}>Device Universe</h6>
          <hr />

          </div>


          <div style={{
            display: 'flex', flexDirection: 'row', 
            justifyContent: 'center', alignItems: 'center',
            alignItems: 'center', height:395
          }}>

          <div style={{width:'40%'}}>
                    
    <Doughnut 
          type="doughnut" 
          data={data1} 
          options={options1} 
          plugins={plugins1} 
          width={screenWidth * 0.25}
                height={screenWidth * 0.25} 
          
    />                
</div>


          </div>
        </div>
      


    </div>
  );
};

export default DeviceUniverse;