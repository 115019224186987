import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

//import data from '../../../datanew/Australia/data/opsView.json';

const IncidentsByCategory = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const [screenWidth, setScreenWidth] = useState(1000);

    const data = props.data;

  useEffect(() => {
      setScreenWidth (window.innerWidth - ((window.innerWidth * 25)/100))
  }, []);


  
  const options = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: true
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    xaxis: {
      categories: data.charts.incidentsByCategory.categories,
      labels: {
        formatter: function (val) {
          return val;
        }
      }
    },
    yaxis: {
      title: {
        text: undefined
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        }
      }
    },
    fill: {
      opacity: 1
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetX: 40
    },
    title: {
      text: '',
      align: 'center'
    }
  };

   

    return (
        <div style={{
            width: '49%', marginLeft: 0, display: 'flex', 
            flexDirection: 'column', height: 500,
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>





            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 10 }}>


            <div style={{ height: 60, }}>

                    <h6 style={{ color: '#fff', textAlign: 'left' }}>
                    Incidents By Category</h6>
                    <hr />

            </div>


                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', 
                    alignItems: 'center', height:390 }} >
                         
                    <ReactApexChart
        options={options}
        series={data.charts.incidentsByCategory.series}
        type="bar"
        height={400}
        width={screenWidth * 0.45}
      />

                    </div>
                </div>
            </div>


    );
};

export default IncidentsByCategory;