import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

import DetectionData from '../../../data/ops-view/SecurityStackEndpointSecurity/Detection.json';

const Detection = (props) => {

  const [onLoading, setOnLoading] = useState(false)

  const data = props.data.charts;

  const [parentWidth, setParentWidth] = useState(0);


  useEffect(() => {
    const updateParentWidth = () => {
      const parentDiv = document.getElementById('parentDiv');
      if (parentDiv) {
        const width = parentDiv.offsetWidth;
        setParentWidth(width * 0.95); 
      }
    };

    updateParentWidth();

    window.addEventListener('resize', updateParentWidth);

    return () => {
      window.removeEventListener('resize', updateParentWidth);
    };
  }, []);


 
  return (
    <div style={{
      width: '100%', marginLeft: 0, display: 'flex', flexDirection: 'column', height: 1000,
      marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
    }}>


      {onLoading &&

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

          <ShimmerCircularImage size={500} />
          <ShimmerCircularImage size={500} />
        </div>
      }


      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>


        <div style={{ width: '100%' }}>

          <h6 style={{ color: '#fff', textAlign: 'left' }}>Detection</h6>
          <hr />


          <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
            alignItems: 'center',
          }}>


<table style={{width:'100%'}}>
            <thead>
                <tr>
                    <th style={{width:'15%', padding:10,color:'#fff'}}>Category</th>
                    <th style={{width:'15%', padding:10,color:'#fff'}}>Risk</th>
                    <th style={{width:'30%', padding:10,color:'#fff'}}>Severity</th>
                    <th style={{width:'20%', padding:10,color:'#fff'}}>Trend</th>
                    <th style={{width:'20%', padding:10,color:'#fff'}}>Affected Devices</th>
                </tr>
            </thead>
            <tbody>
                {data.detection.map(entry => (
                    <tr key={entry.category}>
                        <td>{entry.category}</td>
                        <td>{entry.risks}</td>
                        <td>
                              <div style={{ width: '100%', height: '50px', display: 'flex', alignItems:'center'  }}>
                                <div style={{display:'flex', flexDirection:'row',
                                 justifyContent:'center', alignItems:'center', 
                                 width: `${(entry.severity.critical/ (entry.severity.low + entry.severity.medium + entry.severity.high + entry.severity.critical))*100 }%`, background: 'red' }}>{entry.severity.critical}</div>
                                <div style={{display:'flex', flexDirection:'row', 
                                justifyContent:'center',alignItems:'center', 
                                width: `${(entry.severity.high/ (entry.severity.low + entry.severity.medium + entry.severity.high + entry.severity.critical))*100 }%`, background: 'orange', color:'#000' }}>{entry.severity.high}</div>
                                <div style={{display:'flex', flexDirection:'row', 
                                justifyContent:'center',alignItems:'center', 
                                width: `${(entry.severity.medium / (entry.severity.low + entry.severity.medium + entry.severity.high + entry.severity.critical))*100 }%`, background: 'yellow', color:'#000' }}>{entry.severity.medium}</div>
                                <div style={{display:'flex', flexDirection:'row', 
                                justifyContent:'center',alignItems:'center', 
                                  width: `${(entry.severity.low/ (entry.severity.low + entry.severity.medium + entry.severity.high + entry.severity.critical))*100 }%`, background: '#5dbc53' }}>{entry.severity.low}</div>
                            </div>
                        </td>
                        <td>
                            <ReactApexChart
                                type="line"
                                width={300}
                                height={150}
                                series={[{ data: entry.trend }]}
                                options={{
                                    chart: { type: 'line' },
                                    stroke: { width: 2, curve: 'smooth' },
                                    colors: ['#546E7A']
                                }}
                            />
                        </td>
                        <td>
                           <div style={{width:150, height:150, 
                             display:'flex', flexDirection:'column', 
                             justifyContent:'center', alignItems:'center'}}>
                            <ReactApexChart
                                type="donut"                                
                                series={[entry.affectedDevices, entry.totalDevices - entry.affectedDevices]}
                                options={{
                                    chart: { type: 'donut' },
                                    labels: ['Affected', 'Total'],
                                    colors: ['#FF4560', '#546E7A'],
                                    legend:{
                                      show:false
                                    },
                                    dataLabels:{
                                      enabled:false
                                    }
                                    
                                }}
                                width={100}
                                height={100}
                            />
                            <span style={{width:'100%', textAlign:'center', color:'#fff'}}>
                              {entry.affectedDevices}/{entry.totalDevices}</span>
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>

          </div>
        </div>
      </div>


    </div>
  );
};

export default Detection;