import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

import RecentActivityData from '../../../data/ops-view/SecurityStackServerSecurity/RecentActivity.json';


const RecentActivity = (props) => {

  const [onLoading, setOnLoading] = useState(false)

  const data = props.data.charts;

  const [parentWidth, setParentWidth] = useState(0);


  useEffect(() => {
    const updateParentWidth = () => {
      const parentDiv = document.getElementById('parentDiv');
      if (parentDiv) {
        const width = parentDiv.offsetWidth;
        setParentWidth(width * 0.95); // Assigning 90% width
      }
    };

    updateParentWidth();

    window.addEventListener('resize', updateParentWidth);

    return () => {
      window.removeEventListener('resize', updateParentWidth);
    };
  }, []);


  return (
    <div style={{
      width: '49%', marginLeft: 0, display: 'flex', flexDirection: 'column', height: 450,
      marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
    }}>


      {onLoading &&

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

          <ShimmerCircularImage size={500} />
          <ShimmerCircularImage size={500} />
        </div>
      }


      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>


        <div style={{ width: '100%' }}>

          <h6 style={{ color: '#fff', textAlign: 'left' }}>Recent Activity</h6>
          <hr />


          <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
            alignItems: 'center',
          }}>

            
    <table style={{width:'100%'}}>
      <thead>
        <tr>
          <th style={{ padding: 10,color:'#fff' }}>When</th>
          <th style={{ padding: 10,color:'#fff' }}>Detail</th>
        </tr>
      </thead>
      <tbody>
        {data.recentActivity.map((activity, index) => (
          <tr key={index}>
            <td style={{ padding: 10 }}>{activity.when}</td>
            <td style={{ padding: 10 }}>{activity.detail}</td>
          </tr>
        ))}
      </tbody>
    </table>




          </div>
        </div>
      </div>


    </div>
  );
};

export default RecentActivity;