import React from 'react'

import StrategicView from './views/users/strategicView';
import TacticalView from './views/users/tacticalView';
import OpsView from './views/users/opsView';

import Organization from './views/users/organization';
import Tickets from './views/users/tickets';
import OpenTicket from './views/users/openTicket';
import EditTicket from './views/users/editTicket';
import Ticket from './views/users/ticket';
import Users from './views/users/users';
import AddUser from './views/users/addUser';
import EditUser from './views/users/editUser';
import AssetGroups from './views/users/assetGroups';
import AddAssetGroup from './views/users/addAssetGroup';
import EditAssetGroup from './views/users/editAssetGroup';
import BusinessUnits from './views/users/businessUnits';
import AddBusinessUnit from './views/users/addBusinessUnit';
import EditBusinessUnit from './views/users/editBusinessUnit';

import CIS from './views/users/cis';
import CISDetails from './views/users/cisDetails';

import RiskRegister from './views/users/riskRegister';

const routes = [
  { path: '/strategic-view', name: 'User Dashboard', element: StrategicView},
  { path: '/tactical-view', name: 'SAST Scans', element: TacticalView},  
  { path: '/ops-view', name: 'SAST Scan Detail', element: OpsView},   

  { path: '/csat', name: 'CSAT', element: CIS}, 
  { path: '/csat-details', name: 'CSAT', element: CISDetails},   

  { path: '/risk-register', name: 'Risk Register', element: RiskRegister},   

  { path: '/asset-groups', name: 'Asset Groups', element: AssetGroups},  
  { path: '/add-asset-group', name: 'Add Asset Group', element: AddAssetGroup},  
  { path: '/edit-asset-group', name: 'Edit Asset Group', element: EditAssetGroup},  

  { path: '/business-units', name: 'Business Units', element: BusinessUnits},  
  { path: '/add-business-unit', name: 'Add Business Unit', element: AddBusinessUnit},  
  { path: '/edit-business-unit', name: 'Edit Business Unit', element: EditBusinessUnit},  

  { path: '/users', name: 'Users', element: Users},  
  { path: '/add-user', name: 'Add User', element: AddUser},  
  { path: '/edit-user', name: 'Edit User', element: EditUser},  

  { path: '/organization', name: 'Organization', element: Organization},  
  { path: '/tickets', name: 'Tickets', element: Tickets}, 
  { path: '/open-ticket', name: 'Open Ticket', element: OpenTicket},  
  { path: '/edit-ticket', name: 'Edit Ticket', element: EditTicket},  
  { path: '/ticket', name: 'Ticket', element: Ticket}, 

]

export default routes 