import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';
import '../../style.css';
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';


const WhereIsTheDataStored = (props) => {

  const [onLoading, setOnLoading] = useState(false)

  const data = props.data;

  const dotData = [
    { name: 'Azure', coordinates: [[-95.7129, 37.0902], [-96.7969, 32.7767]] },
    { name: 'AWS', coordinates: [[-118.2437, 34.0522]] },
    { name: 'GCP', coordinates: [[-74.006, 40.7128], [-87.6298, 41.8781]] },
    { name: 'Snowflake', coordinates: [[-95.3698, 29.7604], [-97.7431, 30.2672], [-104.9903, 39.7392]] },
  ];

  const dotTypes = data.charts.whereIsTheDataStored.dotTypes;// ['Azure', 'AWS', 'GCP', 'Snowflake'];
  const dotColors = ['#FFBF00', '#f2103f', '#57ba7e', '#9fb3fe'];


  return (
    <div style={{
      width: '49%', marginLeft: 0, display: 'flex',
      flexDirection: 'column', height: 550,
      marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
    }}>

      <div style={{
        display: 'flex', flexDirection: 'column',
        justifyContent: 'space-between', marginTop: 10
      }}>


        <div style={{ height: 60, }}>

          <h4 style={{ color: '#fff', textAlign: 'left' }}>
            Where is the data stored?</h4>
          <hr />

        </div>


        <div style={{
          display: 'flex',
          flexDirection: 'row', flexWrap: 'wrap',
          justifyContent: 'space-between', alignItems: 'center', height:440, position:'relative'
        }}>



          <div style={{ width: '100%', height: '100%', background:'#abd3df', borderRadius:10 }}>
            <ComposableMap className="dataStoredMap" style={{height:'100%', }}>
              <Geographies geography="/features.json" fill="#f2efe8"
                stroke="#FFFFFF">
                {({ geographies }) =>
                  geographies.map((geo) => <Geography key={geo.rsmKey} geography={geo} />)
                }
              </Geographies>
              {data.charts.whereIsTheDataStored.dotData.map((dot, index) => (
                dot.coordinates.map((coordinate, idx) => (
                  <Marker key={`${index}-${idx}`} coordinates={coordinate}>
                    <circle
                      cx={0}
                      cy={0}
                      r={6}
                      fill={dotColors[dotTypes.indexOf(dot.name)]}
                      stroke="#fff"
                      strokeWidth={2}
                    />

                  </Marker>
                ))
              ))}
            </ComposableMap>
          </div>


          <div style={{
            display: 'flex', flexDirection: 'column',
            justifyContent: 'center', position:'absolute', bottom:10, left:10, zIndex:10000
          }}>
            <div class="maplegend" style={{ marginRight: 20 }}>
              <div class="maplegenddot azure"></div> Azure
            </div>
            <div class="maplegend" style={{ marginRight: 20 }}>
              <div class="maplegenddot aws"></div> AWS
            </div>
            <div class="maplegend" style={{ marginRight: 20 }}>
              <div class="maplegenddot gcp"></div> GCP
            </div>
            <div class="maplegend">
              <div class="maplegenddot snowflake"></div> Snowflake
            </div>
          </div>

        </div>


      </div>
    </div>


  );
};

export default WhereIsTheDataStored;
