import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

import SoftwareAssetsData from '../../../data/ops-view/SecurityStackEndpointSecurity/SoftwareAssets.json';

const SoftwareAssets = (props) => {

  const [onLoading, setOnLoading] = useState(false)

  const data = props.data.charts;

  const [parentWidth, setParentWidth] = useState(0);


  useEffect(() => {
    const updateParentWidth = () => {
      const parentDiv = document.getElementById('parentDiv');
      if (parentDiv) {
        const width = parentDiv.offsetWidth;
        setParentWidth(width * 0.95); // Assigning 90% width
      }
    };

    updateParentWidth();

    window.addEventListener('resize', updateParentWidth);

    return () => {
      window.removeEventListener('resize', updateParentWidth);
    };
  }, []);


  return (
    <div style={{
      width: '67%', marginLeft: 0, display: 'flex', flexDirection: 'column', 
      height: 470,
      marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
    }}>


      {onLoading &&

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

          <ShimmerCircularImage size={500} />
          <ShimmerCircularImage size={500} />
        </div>
      }


      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>


        <div style={{ width: '100%' }}>

          <h6 style={{ color: '#fff', textAlign: 'left' }}>Software Assets</h6>
          <hr />


          <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',
            alignItems: 'center',height:330
          }}>

          
<table style={{ width: '100%' }}>
      <thead>
        <tr>
          <th style={{ padding: 20,color:'#fff' }}>Asset</th>
          <th style={{ padding: 20, width:'60%',color:'#fff' }}>Statistics</th>
          <th style={{ padding: 20,color:'#fff' }}>Risk</th>
        </tr>
      </thead>

      <tbody>
        {data.softwareAssets.map((item, index) => (
          <tr key={index}>
            <td style={{ padding: 20 }}>{item.asset}</td>
            <td style={{ padding: 20 }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', width: `${(item.statisticsGreen / (item.statisticsRed + item.statisticsGreen))*100}%`, height: '20px', backgroundColor: '#5dbc53', marginRight: '0px' }}>
                <span style={{textAlign:'center', color:'#fff',alignItems:'center', width:'100%', fontSize:12}}>{item.statisticsGreen}</span>
                </div>
                <div style={{ display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', width: `${(item.statisticsRed / (item.statisticsRed + item.statisticsGreen))*100}%`, height: '20px', backgroundColor: 'red' }}>
                <span style={{textAlign:'center', width:'100%', fontSize:12, color:'#fff'}}>{item.statisticsRed}</span>
                </div>
              </div>
            </td>
            <td style={{ padding: 20 }}>
              <button style={{ padding: '5px 10px', borderRadius: '20px', backgroundColor: 'red', color: 'white', border: 'none' }}>
                {item.riskPercent}%
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>




          </div>
        </div>
      </div>


    </div>
  );
};

export default SoftwareAssets;