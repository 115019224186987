import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

import TrendsData from '../../../data/ops-view/SecurityStackCloudSecurity/Trends.json';

import { IoMdTrendingUp } from "react-icons/io";
import { FaArrowUp } from "react-icons/fa";
import { FaArrowDown } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { FaRegStar } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa";
import { TbBrandSentry } from "react-icons/tb";

const Trends = (props) => {

  const [onLoading, setOnLoading] = useState(false)

  const data = props.data.charts;

  const [parentWidth, setParentWidth] = useState(0);

  useEffect(() => {
    const updateParentWidth = () => {
      const parentDiv = document.getElementById('parentDiv');
      if (parentDiv) {
        const width = parentDiv.offsetWidth;
        setParentWidth(width * 0.95); // Assigning 90% width
      }
    };

    updateParentWidth();

    window.addEventListener('resize', updateParentWidth);

    return () => {
      window.removeEventListener('resize', updateParentWidth);
    };
  }, []);


  return (
    <div style={{
      width: '40%', marginLeft: 0, display: 'flex', flexDirection: 'column', height: 500,
      marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
    }}>

     


      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: 10 }}>

      <div style={{ height: 60, }}>

          <h6 style={{ color: '#fff', textAlign: 'left' }}>Trends</h6>
          <hr />

          </div>


          <div style={{ display: 'flex', flexDirection: 'row', 
              justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', height: 410}} >

            <div style={{ display: 'flex', flexDirection: 'column', width: '30%', backgroundColor: '#fff', padding: 15, borderRadius: 5, height: '40%', alignItems: 'center' }}>

              <IoMdTrendingUp size={50} style={{color:'#1e88e5', marginBottom:10}}/>
              <span style={{ color: '#000', fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>{data.trends[0].value}</span>
              <span style={{ color: '#000', fontSize: 15, fontWeight: 'bold', textAlign: 'center' }}>{data.trends[0].name}</span>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', width: '30%', backgroundColor: '#fff', padding: 15, borderRadius: 5, height: '40%', alignItems: 'center' }}>

              <FaCheck size={50} style={{color:'#388e3c', marginBottom:10}}/>
              <span style={{ color: '#000', fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>{data.trends[1].value}</span>
              <span style={{ color: '#000', fontSize: 15, fontWeight: 'bold', textAlign: 'center' }}>{data.trends[1].name}</span>
            </div>


            <div style={{ display: 'flex', flexDirection: 'column', width: '30%', backgroundColor: '#fff', padding: 15, borderRadius: 5, height: '40%', alignItems: 'center' }}>

              <FaRegStar size={50} style={{color:'#9c27b0', marginBottom:10}} />
              <span style={{ color: '#000', fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>{data.trends[2].value}</span>
              <span style={{ color: '#000', fontSize: 15, fontWeight: 'bold', textAlign: 'center' }}>{data.trends[2].name}</span>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', width: '30%', backgroundColor: '#fff', padding: 15, borderRadius: 5,
             height: '40%', alignItems: 'center' }}>

              <FaRegClock size={50} style={{color:'#607d8b', marginBottom:10}}/>
              <span style={{ color: '#000', fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>{data.trends[3].value}</span>
              <span style={{ color: '#000', fontSize: 15, fontWeight: 'bold', textAlign: 'center' }}>{data.trends[3].name}</span>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', 
            width: '30%', backgroundColor: '#fff', padding: 15, 
            borderRadius: 5, height: '40%', alignItems: 'center' }}>

              <TbBrandSentry size={50} style={{color:'#009688', marginBottom:10}} />
              <span style={{ color: '#000', fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>{data.trends[4].value}</span>
              <span style={{ color: '#000', fontSize: 15, fontWeight: 'bold', textAlign: 'center' }}>{data.trends[4].name}</span>
            </div>


            <div style={{ display: 'flex', flexDirection: 'column', width: '30%', 
              backgroundColor: '#fff', padding: 15, borderRadius: 5, height: '40%', alignItems: 'center', justifyContent:'space-between' }}>

              <span style={{ color: '#000', fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>{data.trends[6].value}</span>
              <span style={{ color: '#000', fontSize: 15, fontWeight: 'bold', textAlign: 'center' }}>Overall Posture</span>

             {data.overallPostureTrend == "Increase" ?
              <span style={{ color: '#000', fontSize: 13, fontWeight: 'bold', textAlign: 'center', marginTop: 20 }}>Increase +{data.trends[5].overallPostureTrendValue}
                <FaArrowUp size={12} style={{ color: 'green', marginLeft: 10 }} /></span>
                :
                <span style={{ color: '#000', fontSize: 13, fontWeight: 'bold', textAlign: 'center', marginTop: 20 }}>Decrease -{data.trends[5].overallPostureTrendValue}
                <FaArrowDown size={12} style={{ color: 'red', marginLeft: 10 }} /></span>
              }

            </div>


          </div>
        </div>
      </div>


    
  );
};

export default Trends;