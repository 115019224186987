import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

//import data from '../../../datanew/Australia/data/opsView.json';
import GaugeChart from 'react-gauge-chart'

const IncidentCards2 = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const [screenWidth, setScreenWidth] = useState(1000);

    const data = props.data;

    useEffect(() => {
        setScreenWidth(window.innerWidth - ((window.innerWidth * 25) / 100))
    }, []);



    return (
        <div style={{
            width: '49%', marginLeft: 0, display: 'flex', flexDirection: 'column', height: 300,
            marginTop: '2%',
            padding: 20, borderRadius: 15
        }}>


          


            <div style={{ display: 'flex', flexDirection: 'row', 
            justifyContent: 'space-around', marginTop: 10 }}>


                <div style={{ width: '100%' }}>

                    <div style={{
                        display: 'flex', flexDirection: 'row',
                        justifyContent: 'space-between', alignItems: 'center', width:'100%'
                    }}
                    >

                        <div style={{
                            display: 'flex', flexDirection: 'column',
                            justifyContent: 'space-between', alignItems: 'center',
                            width: '60%', height: '100%'
                        }} >

                            <div style={{
                                width: '100%',
                                backgroundColor: '#fff',
                                borderRadius: 10,
                                padding: 20,
                                width: '90%',
                                height: 150,
                                display: 'flex', flexDirection: 'column',
                            justifyContent: 'space-between', alignItems: 'center',
                            }}>

                                <span style={{ fontSize: 30, fontWeight: 'bold', color: '#000', textAlign:'center' }}>
                                    {data.charts.incidentCards2[0].value}</span>
                                <span style={{ fontSize: 20, fontWeight: 'bold', color: '#000', textAlign:'center' }}>
                                    {data.charts.incidentCards2[0].name}</span>

                            </div>

                            <div style={{
                                width: '100%',
                                borderRadius: 10,
                                padding: 20,
                                width: '90%',
                                marginTop: 20,
                                height: 200
                            }}>

                       

                    <div style={{marginBottom:50, 
                    display:'flex', flexDirection:'column', alignItems:'center'}}>
                    
                        <GaugeChart id="gauge-chart3"
                        nrOfLevels={1}
                        colors={['#f85356']}
                        arcWidth={0.2}
                        percent={data.charts.incidentCards2[1].value / 100}
                        hideText={false}                      

                    />

                        <span style={{
                            fontSize:17, color:'#fff', textAlign:'center',
                             
                        }}>{data.charts.incidentCards2[1].name}</span>

                    </div>
                   

                            </div>

                        </div>


                        <div style={{
                            display: 'flex', flexDirection: 'column',
                            justifyContent: 'space-between', alignItems: 'center',
                            width: '40%', height: '50%'
                        }} >


                            <div style={{
                                width: '100%',
                                backgroundColor: '#fff',
                                borderRadius: 10,
                                padding: 20,
                                width: '90%',
                                height: '20%',
                                marginBottom:15,
                                display: 'flex', flexDirection: 'column',
                            justifyContent: 'space-between', alignItems: 'center',
                            }}>

                                <span className="incidentCard2Text1" style={{  fontWeight: 'bold', color: '#000',textAlign:'center' }}>
                                    {data.charts.incidentCards2[2].value}</span>
                                <span className="incidentCard2Text2" style={{  fontWeight: 'normal', color: '#000',textAlign:'center' }}>
                                    {data.charts.incidentCards2[2].name}</span>

                            </div>


                            <div style={{
                                width: '100%',
                                backgroundColor: '#fff',
                                borderRadius: 10,
                                padding: 20,
                                width: '90%',
                                height: '20%',
                                marginBottom:15,
                                display: 'flex', flexDirection: 'column',
                            justifyContent: 'space-between', alignItems: 'center',
                            }}>

                                <span className="incidentCard2Text1" style={{  fontWeight: 'bold', color: '#000',textAlign:'center' }}>
                                {data.charts.incidentCards2[3].value}</span>
                                <span className="incidentCard2Text2" style={{  fontWeight: 'normal', color: '#000',textAlign:'center' }}>
                                {data.charts.incidentCards2[3].name}</span>

                            </div>


                            <div style={{
                                width: '100%',
                                backgroundColor: '#fff',
                                borderRadius: 10,
                                padding: 20,
                                width: '90%',
                                height: '20%',
                                marginBottom:15,
                                display: 'flex', flexDirection: 'column',
                            justifyContent: 'space-between', alignItems: 'center',
                            }}>

                                <span className="incidentCard2Text1" style={{  fontWeight: 'bold', color: '#000', textAlign:'center' }}>
                                {data.charts.incidentCards2[4].value}</span>
                                <span className="incidentCard2Text2" style={{ fontWeight: 'normal', color: '#000',textAlign:'center' }}>
                                {data.charts.incidentCards2[4].name}</span>

                            </div>



                            <div style={{
                                width: '100%',
                                backgroundColor: '#fff',
                                borderRadius: 10,
                                padding: 20,
                                width: '90%',
                                height: '20%',
                                display: 'flex', flexDirection: 'column',
                            justifyContent: 'space-between', alignItems: 'center',
                            }}>

                                <span className="incidentCard2Text1" style={{  fontWeight: 'bold', color: '#000',textAlign:'center' }}>
                                {data.charts.incidentCards2[5].value}</span>
                                <span className="incidentCard2Text2" style={{  fontWeight: 'normal', color: '#000',textAlign:'center' }}>
                                {data.charts.incidentCards2[5].name}</span>

                            </div>



                        </div>




                    </div>
                </div>
            </div>


        </div>
    );
};

export default IncidentCards2;