import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';
import '../../style.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const WhichRiskNeedToBeResolvedFirst = (props) => {

  const data = props.data;

  const [onLoading, setOnLoading] = useState(false)

  const [screenWidth, setScreenWidth] = useState(1000);

  useEffect(() => {
    setScreenWidth(window.innerWidth - ((window.innerWidth * 25) / 100))
  }, []);





  return (
    <div style={{
      width: '49%', marginLeft: 0, display: 'flex',
      flexDirection: 'column', height: 550,
      marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
    }}>

      <div style={{
        display: 'flex', flexDirection: 'column',
        justifyContent: 'space-between', marginTop: 10
      }}>


        <div style={{ height: 60, }}>

          <h4 style={{ color: '#fff', textAlign: 'left' }}>
            Which risks need to be resolved first?</h4>
          <hr />

        </div>


        <div style={{
          display: 'flex',
          flexDirection: 'column', flexWrap: 'wrap', alignItems: 'center',
          justifyContent: 'center', width: '100%',  height:430
        }}>



          <Tabs style={{ width: '100%', height:'100%', alignSelf:'center'  }}>
            <TabList>
              <Tab>Data Exposure</Tab>
              <Tab>Public Exposure</Tab>
            </TabList>

            <TabPanel >

              <table style={{ width: '100%' }}>


                {data.charts.whichRisksNeedToBeResolvedFirst.dataExposure.map(row => (
                  <tr>
                    <td style={{ width: '90%' }}>{row.item}</td>
                    <td>{row.count}</td>
                  </tr>
                ))}



              </table>

            </TabPanel>


            <TabPanel>


              <table style={{ width: '100%' }}>

                {data.charts.whichRisksNeedToBeResolvedFirst.publicExposure.map(row => (
                  <tr>
                    <td style={{ width: '90%' }}>{row.item}</td>
                    <td>{row.count}</td>
                  </tr>
                ))}

              </table>

            </TabPanel>
          </Tabs>




        </div>


      </div>
    </div>


  );
};

export default WhichRiskNeedToBeResolvedFirst;
