import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';


const TotalMonetaryImpact = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const data = props.data;

    const [screenWidth, setScreenWidth] = useState(1000);

    useEffect(() => {
        setScreenWidth(window.innerWidth - ((window.innerWidth * 25) / 100))
    }, []);

    const series = [76];

    const options = {
        chart: {
            type: 'radialBar',
            offsetY: -20,
            sparkline: {
                enabled: true
            }
        },
        plotOptions: {
            radialBar: {
                startAngle: -90,
                endAngle: 90,
                track: {
                    background: "#e7e7e7",
                    strokeWidth: '97%',
                    margin: 5, // margin is in pixels
                    dropShadow: {
                        enabled: true,
                        top: 2,
                        left: 0,
                        color: '#999',
                        opacity: 1,
                        blur: 2
                    }
                },
                dataLabels: {
                    name: {
                        show: false
                    },
                    value: {
                        offsetY: -2,
                        fontSize: '22px',
                        color: '#fff'
                    }

                }
            }
        },
        grid: {
            padding: {
                top: -10
            }
        },
        fill: {
            type: 'gradient',
            gradient: {
                shade: 'light',
                shadeIntensity: 0.4,
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 50, 53, 91]
            },
        },
        labels: ['Average Results'],
    }





    return (
        <div style={{
            width: '49%', marginLeft: 0, display: 'flex', flexDirection: 'column', height: 550,
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', marginTop: 10 }}>


                <div style={{ height: 60, }}>

                    <h4 style={{ color: '#fff', textAlign: 'left' }}>Total Monetary Impact</h4>
                    <hr />

                </div>

                <div style={{
                    width: '100%',
                    height: '100%', display: 'flex',
                    flexDirection: 'row', alignItems: 'center', height: 410
                }}>

                    <div style={{
                        width: '50%',
                        height: '100%', display: 'flex',
                        flexDirection: 'column', alignItems: 'center', justifyContent: 'center'
                    }}>

                        <Chart options={options}
                            series={series}
                            type="radialBar"
                            width={screenWidth * 0.25}
                        />

                    </div>


                    <div style={{ width: '50%', height: '300px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>


                        <span className="whiteSpan" style={{ textAlign: 'center' }}>Total Assets</span>

                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', marginTop: 20, width: '100%' }}>

                            <div style={{
                                display: 'flex', flexDirection: 'column', width: '43%', margin: 5,
                                border: '1px solid #fff', justifyContent: 'center', borderRadius: 5, padding: 5, alignItems: 'center', padding: 30
                            }}>
                                <span style={{ color: '#fb4437' }}>{data.charts.totalMonetaryImpact.cards[0].value}</span>
                                <span className="whiteSpan" style={{ marginTop: 10 }}>{data.charts.totalMonetaryImpact.cards[0].name}</span>
                            </div>

                            <div style={{
                                display: 'flex', flexDirection: 'column', width: '43%', margin: 5,
                                border: '1px solid #fff', justifyContent: 'center', borderRadius: 5, padding: 5, alignItems: 'center', padding: 30
                            }}>
                                <span style={{ color: '#fb4437' }}>{data.charts.totalMonetaryImpact.cards[1].value}</span>
                                <span className="whiteSpan" style={{ marginTop: 10 }}>{data.charts.totalMonetaryImpact.cards[1].name}</span>
                            </div>


                            <div style={{
                                display: 'flex', flexDirection: 'column', width: '43%', margin: 5,
                                border: '1px solid #fff', justifyContent: 'center', borderRadius: 5, padding: 5, alignItems: 'center', padding: 30
                            }}>
                                <span style={{ color: '#fb4437' }}>{data.charts.totalMonetaryImpact.cards[2].value}</span>
                                <span className="whiteSpan" style={{ marginTop: 10 }}>{data.charts.totalMonetaryImpact.cards[2].name}</span>
                            </div>


                            <div style={{
                                display: 'flex', flexDirection: 'column', width: '43%', margin: 5,
                                border: '1px solid #fff', justifyContent: 'center', borderRadius: 5, padding: 5, alignItems: 'center', padding: 30
                            }}>
                                <span style={{ color: '#fb4437' }}>{data.charts.totalMonetaryImpact.cards[3].value}</span>
                                <span className="whiteSpan" style={{ marginTop: 10 }}>{data.charts.totalMonetaryImpact.cards[3].name}</span>
                            </div>

                        </div>


                    </div>


                </div>



            </div>
        </div>





    );
};

export default TotalMonetaryImpact;
