import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';

import ComplianceIndicatorsData from '../../data/tactical-view/ComplianceSummaryIndicators.json'; 

const ComplianceSummaryIndicators = () => {

    const [onLoading, setOnLoading] = useState(false)   

    const data = ComplianceIndicatorsData;

    return (
        <div style={{
            width: '100%', marginLeft: 0, display: 'flex', flexDirection: 'column', 
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>



            {onLoading &&

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

                    <ShimmerCircularImage size={500} />
                    <ShimmerCircularImage size={500} />
                </div>
            }



            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>


                <div style={{ width: '100%' }}>

                    <h6 style={{ color: '#fff', textAlign: 'left' }}>Compliance Summary - Indicators</h6>
                    <hr />


                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems:'center', 
                flexWrap:'wrap' }}>

{data.map((item, index) => (
  <span
    key={index}
    style={{
      width: '33%',
      paddingTop: 5,
      paddingBottom: 5,
      backgroundColor: item.backgroundColor,
      textAlign: 'center',
      color: item.textColor,
      marginBottom: 10
    }}
  >
    {item.value}
  </span>
))}




                    </div>



                </div>


            </div>


        </div>
    );
};

export default ComplianceSummaryIndicators;
