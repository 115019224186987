import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import Chart from 'react-apexcharts';
import '../../style.css';
import GaugeChart from 'react-gauge-chart'

const OverallRiskScore = (props) => {

    const [onLoading, setOnLoading] = useState(false)

    const overallRiskScore = 0.5;

    const data = props.data;

    return (
        <div style={{
            width: '49%', marginLeft: 0,
            display: 'flex', flexDirection: 'column', height: 520,
            marginTop: '2%',
            background: 'linear-gradient(135deg, #161c2a, #1a2130)',
            padding: 20, borderRadius: 15
        }}>

            <div style={{
                display: 'flex', flexDirection: 'column',
                justifyContent: 'flex-start', marginTop: 10
            }}>


                <div style={{ height: 100 }}>

                    <h4 style={{ color: '#fff', textAlign: 'left' }}>Overall Risk Score</h4>

                    <div style={{
                        display: 'flex', flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}>
                        <h6 style={{ color: '#fff', textAlign: 'left' }}>Company Risk</h6>

                    </div>
                    <hr />

                </div>

                <div style={{
                    height: 420, display: 'flex',
                    flexDirection: 'row', alignItems: 'center', justifyContent: 'center'
                }}>

                    <div style={{ width: '80%' }}>

                        <GaugeChart id="gauge-chart3"
                            nrOfLevels={3}
                            colors={['#a4ba0d', '#fec81a', '#f85356']}
                            arcWidth={0.3}
                            percent={data.charts.overallRiskScore.riskScore / 100}
                            hideText={true}

                        />

                        <h3 style={{ textAlign: 'center' }}>{data.charts.overallRiskScore.riskScore}%</h3>

                    </div>
                </div>

            </div>

        </div>


    );
};

export default OverallRiskScore;
